import React, { useState, useEffect, useRef } from "react";
import TestLayout from "./TestLayout";
import { goodPerformance, needImprovement } from "../../../assets/images";

const DualTaskTest = () => {
  const [horizontalRed, setHorizontalRed] = useState(50);
  const [verticalRed, setVerticalRed] = useState(50);
  const [horizontalBlue, setHorizontalBlue] = useState(50);
  const [verticalBlue, setVerticalBlue] = useState(50);
  const [overlapTime, setOverlapTime] = useState(0);
  const [startTime, setStartTime] = useState(null);

  const testDuration = 120000;
  const intervalRef = useRef(null);
  const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    setStartTime(Date.now());

    let horizontalDirection = 1 
    let verticalDirection = 1;
    const speed = 0.02;

    const moveBlueBox = setInterval(() => {
      setHorizontalBlue((prev) => {
        if (prev >= 95) horizontalDirection = -1
        if (prev <= 5) horizontalDirection = 1;
        return prev + horizontalDirection * speed;
      });

      setVerticalBlue((prev) => {
        if (prev >= 95) verticalDirection = -1;
        if (prev <= 5) verticalDirection = 1;
        return prev + verticalDirection * speed;
      });
    }, 10); 

    const overlapInterval = setInterval(() => {
      if (isOverlapping(horizontalRed, horizontalBlue)) {
        setOverlapTime((prev) => prev + 100);
      }
      if (isOverlapping(verticalRed, verticalBlue)) {
        setOverlapTime((prev) => prev + 100);
      }
    }, 100);

    intervalRef.current = setTimeout(() => {
      clearInterval(moveBlueBox);
      clearInterval(overlapInterval);
      clearTimeout(intervalRef.current);
    }, testDuration);

    return () => {
      clearInterval(moveBlueBox);
      clearInterval(overlapInterval);
      clearTimeout(intervalRef.current);
    };
    // eslint-disable-next-line
  }, [horizontalRed, verticalRed]);

  const isOverlapping = (red, blue) => {
    return Math.abs(red - blue) < 5;
  };

  const handleKeyPress = (e) => {
    if (!isMobile) {
      switch (e.key) {
        case "ArrowUp":
          setVerticalRed((prev) => Math.max(prev - 1, 0));
          break;
        case "ArrowDown":
          setVerticalRed((prev) => Math.min(prev + 1, 100));
          break;
        case "w":
          setHorizontalRed((prev) => Math.max(prev - 1, 0));
          break;
        case "s":
          setHorizontalRed((prev) => Math.min(prev + 1, 100));
          break;
        default:
          break;
      }
    }
  };

  const handleTouchMove = (e) => {
    if (isMobile) {
      const touchX = (e.touches[0].clientX / window.innerWidth) * 100;
      const touchY = (e.touches[0].clientY / window.innerHeight) * 100;
      setHorizontalRed(Math.max(0, Math.min(touchX, 100)));
      setVerticalRed(Math.max(0, Math.min(touchY, 100)));
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("touchmove", handleTouchMove);
    } else {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener("touchmove", handleTouchMove);
      } else {
        window.removeEventListener("keydown", handleKeyPress);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const accuracy = ((overlapTime / testDuration) * 100).toFixed(2);
  return (
    <TestLayout>
      <div className="dual-task-test">
        <h2>Dual Task Test</h2>
        {Date.now() - startTime < testDuration ? (
         <div className="test_box">
           <div className="paths">
            <div className="horizontal-path">
              <div
                className="red-box"
                style={{ left: `${horizontalRed}%` }}
              ></div>
              <div
                className="blue-box"
                style={{ left: `${horizontalBlue}%` }}

              ></div>
            </div>
            <div className="vertical-path">
              <div className="red-box" style={{ top: `${verticalRed}%` }}></div>
              <div
                className="blue-box"
                style={{ top: `${verticalBlue}%` }}
              ></div>
            </div>
          </div>
         </div>
        ) : (
          <div className="feedback_box">
            {accuracy > 60 ? (
              <>
                <img src={goodPerformance} alt="good" />
                <p>
                  You have performed well in the test, demonstrating great
                  understanding and preparation. Keep up the good work and
                  continue striving for excellence!🎉
                </p>
              </>
            ) : (
              <>
                <img src={needImprovement} alt="needImprovement" />
                <p>
                  Your performance shows potential, but there is room for
                  improvement
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </TestLayout>
  );
};

export default DualTaskTest;
