import React, { useState } from "react";
import "../../styles/gptChatHeader.scss";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GiNotebook } from "react-icons/gi";
import { TbStretching } from "react-icons/tb";
import {
  defaultUser,
  logo_main,
  profile_mic,
  profile_mic_active,
} from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/userContext";
import { logout } from "../../redux/actions/userAction";
import CustomButton from "./CustomButton";
import exerciseAnimated from "../../images/exerciseAnimated.gif"
import notesAnimated from "../../images/notesAnimated.gif"

const GptChatHeader = ({
  handleSpeakFunction,
  captainImage,
  gptSpeaker,
  welcomeText,
  handleShowExercise,
  handleShowNotes,
}) => {
  const [openToggler, setOpenToggler] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth] = useAuth(); // GET USER DETAILS FROM USER CONTEXT

  // FOR LOGIN PAGE CHANGE IN ROUTING
  const handleSignInPage = () => {
    navigate("/login");
  };

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="mobileChatHeader txt_color">
      <div className="modalDetails">
        <div className="modalImg" onClick={handleSpeakFunction}>
          <img src={captainImage} alt="captain modal" />
          <button>
            {gptSpeaker ? (
              <img
                src={profile_mic_active}
                alt="speaker function"
                className="mic_btn active"
              />
            ) : (
              <img
                src={profile_mic}
                alt="speaker function"
                className="mic_btn"
              />
            )}
          </button>
        </div>
        <div className="abtModal">
          <h1>{welcomeText.name}</h1>
          <p>{welcomeText.tagLine}</p>
        </div>
      </div>
      <div className="actionButtons">
        <button title="Exercise" onClick={() => handleShowExercise()}>
          {/* <TbStretching /> */}
          <img src={exerciseAnimated} alt="exerciseAnimated" />
        </button>
        <button title="Notes" onClick={() => handleShowNotes()}>
          {/* <GiNotebook /> */}
          <img src={notesAnimated} alt="notesAnimated" />
        </button>
        <button title="Notes" onClick={() => setOpenToggler(!openToggler)}>
          <BsThreeDotsVertical />
        </button>
      </div>
      <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
        <div className="close_menu_btn">
          <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
        </div>

        {auth?.user?._id ? (
          <div className="user_details_nav_link_mobile">
            <div className="userDetails">
              <div className="profileImg">
                <img
                  src={
                    auth?.user?.profile?.url
                      ? auth?.user?.profile?.url
                      : defaultUser
                  }
                  alt={auth?.user?.first_name}
                />
              </div>
              <div className="userName">
                {auth?.user?.first_name?.slice(0, 10)}
              </div>
            </div>
            <div className="auth_user_links">
              <Link
                to={
                  auth?.user?.role === "13" || auth?.user?.role === "12"
                    ? "/teacher/profile"
                    : auth?.user?.role === "11"
                    ? "/student/profile"
                    : "/parent/profile"
                }
              >
                Profile
              </Link>
              <CustomButton title="Log Out" onClick={handleLogout} />
            </div>
          </div>
        ) : (
          <img
            className="sideNavdesktopImg"
            src={logo_main}
            alt="Alpha Regiment desktop_logo"
          />
        )}

        <div className="nav_link dropdown_nav">
          {auth?.user?._id ? (
            <div className="auth_profile_dropdown"></div>
          ) : (
            <div className="headerSign_btns">
              <button
                className="sign_up_btn signUp"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </button>
              <button className="sign_up_btn signIn" onClick={handleSignInPage}>
                Sign In
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GptChatHeader;
