import React, { useState, useEffect, useRef } from "react";
import TestLayout from "./TestLayout";
import moment from "moment";
import { goodPerformance, needImprovement } from "../../../assets/images";

const PursuitTrackingTest = () => {
  const [balls, setBalls] = useState([]);
  const [blinkingBallIndex, setBlinkingBallIndex] = useState(null);
  const [boxPosition, setBoxPosition] = useState({ x: 90, y: 90 });
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(120);
  const [joystickPosition, setJoystickPosition] = useState({ x: 0, y: 0 });
  const joystickRef = useRef(null);
  const dragging = useRef(false);
  const boxRef = useRef(null);
  const gameBoxRef = useRef(null);
  const ballRefs = useRef([]);

  const BALL_SPEED_MM = 30;
  const BALL_DIAMETER_PX = 20;
  const MM_TO_PX_CONVERSION = 3.779527559;

  const ballSpeedPx = (BALL_SPEED_MM * MM_TO_PX_CONVERSION) / 1000;

  useEffect(() => {
    const generatedBalls = Array.from({ length: 40 }, () => ({
      x: Math.random() * 90 + 5,
      y: Math.random() * 90 + 5,
    }));
    setBalls(generatedBalls);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (balls?.length > 0) {
      const randomIndex = Math.floor(Math.random() * balls?.length);
      setBlinkingBallIndex(randomIndex);
    }
  }, [balls]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    dragging.current = true;
  };

  const handleMouseMove = (e) => {
    if (!dragging.current || !gameBoxRef.current) return;
    e.preventDefault();

    const gameBox = gameBoxRef.current.getBoundingClientRect();
    const x = ((e.clientX - gameBox.left) / gameBox.width) * 100;
    const y = ((e.clientY - gameBox.top) / gameBox.height) * 100;

    const scaledX = boxPosition.x + (x - boxPosition.x) * 0.5;
    const scaledY = boxPosition.y + (y - boxPosition.y) * 0.5;

    setBoxPosition({
      x: Math.min(100, Math.max(0, scaledX)),
      y: Math.min(100, Math.max(0, scaledY)),
    });
    if (blinkingBallIndex !== null) {
      const ball = ballRefs.current[blinkingBallIndex];
      const ballSpeed = 1;
      ball.style.transition = `transform ${ballSpeed}s linear`;
    }
  };

  const handleMouseUp = () => {
    dragging.current = false;
  };

  const handleTouchStart = (e) => {
    dragging.current = true;
  };

  const handleTouchMove = (e) => {
    if (!dragging.current && !gameBoxRef.current) return;
    // e.preventDefault();
    const gameBox = gameBoxRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const x = ((touch.clientX - gameBox.left) / gameBox.width) * 100;
    const y = ((touch.clientY - gameBox.top) / gameBox.height) * 100;

    const scaledX = boxPosition.x + (x - boxPosition.x) * 0.5;
    const scaledY = boxPosition.y + (y - boxPosition.y) * 0.5;

    setBoxPosition({
      x: Math.min(100, Math.max(0, scaledX)),
      y: Math.min(100, Math.max(0, scaledY)),
    });
  };

  const handleTouchEnd = () => {
    dragging.current = false;
  };

  const moveBall = (ball, ballIndex) => {
    const gameBox = gameBoxRef.current.getBoundingClientRect();
    const ballElement = ballRefs.current[ballIndex];
    if (!gameBox || !ballElement) return;

    const maxWidth = gameBox.width - BALL_DIAMETER_PX;
    const maxHeight = gameBox.height - BALL_DIAMETER_PX;

    const newX = Math.random() * maxWidth;
    const newY = Math.random() * maxHeight;

    const currentX = ballElement.offsetLeft;
    const currentY = ballElement.offsetTop;

    const deltaX = newX - currentX;
    const deltaY = newY - currentY;

    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const duration = distance / ballSpeedPx;

    ballElement.style.transition = `transform ${duration}ms linear`;
    ballElement.style.transform = `translate(${deltaX}px, ${deltaY}px)`;

    setTimeout(() => moveBall(ball, ballIndex), duration);
  };

  useEffect(() => {
    if (blinkingBallIndex !== null) {
      moveBall(balls[blinkingBallIndex], blinkingBallIndex);
    }
  }, [blinkingBallIndex]);

  const handleSubmit = () => {
    if (isOverlap(balls[blinkingBallIndex])) {
      setScore((prev) => prev + 1);
    }
    setBoxPosition({
      x: 90,
      y: 90,
    });
  };

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  // Overlap detection
  const isOverlap = (ball) => {
    if (!ball && !gameBoxRef.current) return false;

    const { x, y } = ball;
    const box = boxRef.current.getBoundingClientRect();
    const ballPos = {
      left: x * (window.innerWidth / 100),
      top: y * (window.innerHeight / 100),
    };
    return (
      ballPos.left >= box.left &&
      ballPos.left <= box.right &&
      ballPos.top >= box.top &&
      ballPos.top <= box.bottom
    );
  };

  const formattedTime = moment
    .utc(moment.duration(timeLeft, "seconds").asMilliseconds())
    .format("mm:ss");
  const timerProgress = ((120 - timeLeft) / 120) * 100;

  useEffect(() => {
    document.body.style.overflow = timeLeft > 0 ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [timeLeft]);

  // Handle joystick movement

  const startJoystickMovement = () => {
    const moveBox = () => {
      if (!dragging.current) return;

      const movementSpeed = 0.5; // Speed of movement
      setBoxPosition((prev) => ({
        x: Math.min(
          100,
          Math.max(0, prev.x + (joystickPosition.x / 50) * movementSpeed)
        ),
        y: Math.min(
          100,
          Math.max(0, prev.y + (joystickPosition.y / 50) * movementSpeed)
        ),
      }));

      requestAnimationFrame(moveBox);
    };

    moveBox();
  };

  const handleJoystickMove = (e) => {
    if (!joystickRef?.current) return;
    const parentRect = joystickRef?.current?.getBoundingClientRect();
    const centerX = parentRect.left + parentRect.width / 2;
    const centerY = parentRect.top + parentRect.height / 2;

    const deltaX = e.clientX - centerX;
    const deltaY = e.clientY - centerY;

    const distance = Math.min(Math.sqrt(deltaX ** 2 + deltaY ** 2), 50);

    const angle = Math.atan2(deltaY, deltaX);

    const newX = distance * Math.cos(angle);
    const newY = distance * Math.sin(angle);

    setJoystickPosition({ x: newX, y: newY });

    if (!dragging.current) {
      dragging.current = true;
      startJoystickMovement();
    }
  };

  const handleJoystickEnd = () => {
    dragging.current = false;
    setJoystickPosition({ x: 0, y: 0 });
  };

  return (
    <TestLayout>
      <div
        className="pursuit-test"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={gameBoxRef}
      >
        <h2>Pursuit Test</h2>
        {timeLeft > 0 ? (
          <>
            <div
              className="timer"
              style={{
                background: `conic-gradient(red ${timerProgress}%, #ddd ${timerProgress}% 100%)`,
              }}
            >
              <p>{formattedTime}</p>
            </div>
            <div className="game-box">
              <div className="ball_group">
                {balls.map((ball, index) => (
                  <div
                    key={index}
                    ref={(el) => {
                      ballRefs.current[index] = el;
                    }}
                    className={`ball ${
                      blinkingBallIndex === index ? "blinking" : ""
                    }`}
                    style={{
                      left: `${ball.x}%`,
                      top: `${ball.y}%`,
                      width: `${BALL_DIAMETER_PX}px`,
                      height: `${BALL_DIAMETER_PX}px`,
                      backgroundColor:
                        blinkingBallIndex === index ? "green" : "red",
                    }}
                  ></div>
                ))}
              </div>
              <div
                ref={boxRef}
                className="box-group"
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                style={{
                  left: `${boxPosition.x}%`,
                  top: `${boxPosition.y}%`,
                }}
              >
                <div className="box small"></div>
                <div className="box medium"></div>
                <div className="box large"></div>
              </div>
            </div>
            <div
              ref={joystickRef}
              className="joystick"
              onMouseMove={handleJoystickMove}
              onMouseUp={handleJoystickEnd}
              onMouseLeave={handleJoystickEnd}
            >
              <div
                className="joystick_handle"
                style={{
                  left: `calc(50% + ${joystickPosition.x}px - 15px)`,
                  top: `calc(50% + ${joystickPosition.y}px - 15px)`,
                }}
              ></div>
            </div>
          </>
        ) : (
          <div className="feedback_box">
            {score > 22 ? (
              <>
                <img src={goodPerformance} alt="good" />
                <p>
                  You have performed well in the test, demonstrating great
                  understanding and preparation. Keep up the good work and
                  continue striving for excellence!🎉
                </p>
              </>
            ) : (
              <>
                <img src={needImprovement} alt="needImprovement" />
                <p>
                  Your performance shows potential, but there is room for
                  improvement
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </TestLayout>
  );
};

export default PursuitTrackingTest;
