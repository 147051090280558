import React, { Fragment, useEffect, useRef, useState } from "react";
import "../../styles/mockPPDT.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "./gptLayout";
import moment from "moment";
import CustomButton from "../reusable/CustomButton";
import { micActive, micInactive } from "../../assets/images";
import { FaMicrophone } from "react-icons/fa";

const MockPPDT = () => {
  const [imageTimer, setImageTimer] = useState(30);
  const [writingTimer, setWritingTimer] = useState(300);
  const [blinking, setBlinking] = useState(false);
  const [writingTimeblinking, setWritingTimeBlinking] = useState(false);

  // IMAGE SHOW TIME MANAGE BY INTERVAL
  useEffect(() => {
    if (imageTimer < 6) {
      setBlinking(true);
    }
    if (imageTimer > 0) {
      const interval = setInterval(() => {
        setImageTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [imageTimer]);

  // STORY WRITING TIME MANAGE BY INTERVAL
  useEffect(() => {
    if (writingTimer < 6) {
      setWritingTimeBlinking(true);
    }
    if (writingTimer > 0 && imageTimer === 0) {
      const interval = setInterval(() => {
        setWritingTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [writingTimer, imageTimer]);

  // IMAGE TIMER PROGRESS AND FORMATTED TIME
  const imageTimerProgress = ((30 - imageTimer) / 30) * 100;
  const imageFormattedTime = moment.utc(imageTimer * 1000).format("mm:ss");

  // STORY WRITING TIMER PROGRESS AND FORMATTED TIME
  const writingTimerProgress = ((300 - writingTimer) / 300) * 100;
  const writingFormattedTime = moment.utc(writingTimer * 1000).format("mm:ss");

  return (
    <Fragment>
      <CustomHelmet
        title="Mock PPDT: SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="mockPPDT_wrapper txt_color">
          {imageTimer > 0 ? (
            <div className="imageSection">
              <div className="container">
                <div
                  className="timer"
                  style={{
                    background: `conic-gradient(red ${imageTimerProgress}%, #ddd ${imageTimerProgress}% 100%)`,
                  }}
                >
                  <p
                    style={{
                      animation: blinking ? "blinkingText 1s infinite" : "none",
                    }}
                  >
                    {imageFormattedTime}
                  </p>
                </div>
                <h1 className="main_title">PPDT Image</h1>
                <img src="./thumbnail.png" alt="SSB GPT PPDT Test" />
              </div>
            </div>
          ) : imageTimer === 0 && writingTimer > 0 ? (
            <div className="writingSection">
              <div className="container">
                <div className="writingSecContent">
                  <h2>PPDT Story Writing</h2>
                  <div className="storyWritingSec">
                    <div
                      className="timer"
                      style={{
                        background: `conic-gradient(red ${writingTimerProgress}%, #ddd ${writingTimerProgress}% 100%)`,
                      }}
                    >
                      <p
                        style={{
                          animation: writingTimeblinking
                            ? "blinkingText 1s infinite"
                            : "none",
                        }}
                      >
                        {writingFormattedTime}
                      </p>
                    </div>
                    <p>Time Left</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="submissionSection">
              <div className="container">
                <div className="submissionSecContent">
                  <h2>PPDT Story Submission</h2>
                  <div className="storySubmissionArea">
                    <div className="imgBox">
                      <div className="writingDiv"></div>
                      <p className="descTxt">
                        *Identify and mark the location of characters, noting
                        their age, gender, and mood.
                      </p>
                    </div>
                    <div className="actionBox">
                      <label htmlFor="actionInput">Action:</label>
                      <textarea
                        name="actionText"
                        id="actionInput"
                        rows={5}
                      ></textarea>
                    </div>
                    <div className="textAreaBox">
                      <textarea
                        name="storyText"
                        placeholder="Write Story"
                        id="storyInput"
                        rows={10}
                      ></textarea>
                      <button title="Start speaking" className="micButton">
                        <FaMicrophone />
                      </button>
                    </div>
                  </div>
                  <CustomButton
                    className="submitStoryBtn"
                    title="Submit Story"
                    // onClick=""
                    disabled={true}
                    tooltip="Submit Story"
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default MockPPDT;
