import React, { Fragment } from "react";
import GptLayout from "../gptLayout";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "../../../styles/memoryRecall.scss";

const TestLayout = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <GptLayout>
        <section className="all_cpss_test">
          <div className="container">
            <button className="backBtn" onClick={() => navigate(-1)}>
              <FaArrowLeftLong />
              Go Back
            </button>
            {children}
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default TestLayout;
