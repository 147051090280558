import { LECTURETTE_CLEAR_ERROR, LECTURETTE_TOPICS_FAIL, LECTURETTE_TOPICS_REQUEST, LECTURETTE_TOPICS_SUCCESS } from "../constants/lecturetteConstant";

// GET LECTURETTE TOPICS REDUCER
export const lecturetteTopicsReducer = (state = {}, action) => {
  switch (action.type) {
    case LECTURETTE_TOPICS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case LECTURETTE_TOPICS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case LECTURETTE_TOPICS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case LECTURETTE_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
