import axios from "axios";
import {
  LECTURETTE_CLEAR_ERROR,
  LECTURETTE_CLEAR_STATE,
  LECTURETTE_TOPICS_FAIL,
  LECTURETTE_TOPICS_REQUEST,
  LECTURETTE_TOPICS_SUCCESS,
} from "../constants/lecturetteConstant";

// GET LECTURETTE TOPICS START
export const getLecturetteTopics = (conversationId) => async (dispatch) => {
  try {
    dispatch({ type: LECTURETTE_TOPICS_REQUEST });

    const { data } = await axios.post(
      `https://chat.ssbgpt.in/api/v1/lecturette-chat`,
      {
        inputText: "hello",
        conversationId: conversationId ? conversationId : "",
      }
    );

    dispatch({
      type: LECTURETTE_TOPICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LECTURETTE_TOPICS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearLecturetteState = () => async (dispatch) => {
  dispatch({ type: LECTURETTE_CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearLecturetteErrors = () => async (dispatch) => {
  dispatch({ type: LECTURETTE_CLEAR_ERROR });
};
