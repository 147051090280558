import React, { Fragment } from "react";
import CustomHelmet from "../../component/reusable/Helmet";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import olq_1 from "../../images/howuse/olq_1.png";
import olq_2 from "../../images/howuse/olq_2.png";
import olq_3 from "../../images/howuse/olq_3.png";
import olq_4 from "../../images/howuse/olq_4.png";
import olq_5 from "../../images/howuse/olq_5.png";
import olq_6 from "../../images/howuse/olq_6.png";

const OLQAnalyseHowUse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="how_UseClassRoom_main">
        <div className="container">
          <div className="bacbtnWthHeading">
            <button
              title="back"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeftLong />
            </button>
            <h1 className="main_heading">How to use OLQs Analysis</h1>
          </div>
        </div>
        <div className="howUse_content container">
          <p>
            <b>Welcome, Alpha Warriors! 👨‍✈️👩‍✈️</b>
            When you appear for the <b>SSB's five-day interview process</b>, the
            assessors evaluate you on 15 key{" "}
            <b>Officer-Like Qualities (OLQs)</b>. These qualities determine your
            suitability for a career in the armed forces. If you align with
            these 15 OLQs, it becomes easier to get selected.
          </p>
          <h2 className="subHeading">What is OLQ analysis?</h2>
          <p>
            The <b>OLQs Analysis Test</b> is a psychometric tool designed for
            self-assessment. Here’s everything you need to know:
          </p>
          <ul style={{ listStyle: "desimal" }}>
            <li>
              <b>Question Format:</b>
              <ul>
                <li>
                  The test consists of <b>MCQ-type questions</b>.
                </li>
                <li>
                  There is <b>no negative marking</b> and <b>no time limit</b>,
                  so you can take your time.
                </li>
              </ul>
            </li>
            <li>
              <b>Honesty is Key:</b>
              <ul>
                <li>
                  To get accurate results, it’s important to answer honestly.
                </li>
                <li>
                  Remember, this test is for <b>your self-assessment</b>, so be
                  truthful in your responses.
                </li>
              </ul>
            </li>
            <li>
              <b>Privacy Ensured:</b>
              <ul>
                <li>Your responses are completely private.</li>
                <li>
                  No human or machine will access your answers, and they will be
                  deleted immediately after the test is completed.
                </li>
              </ul>
            </li>
            <li>
              <b>Test Structure:</b>
              <ul>
                <li>
                  The test includes approximately <b>100 questions</b>.
                </li>
                <li>
                  You’ll see <b>one question at a time</b>. After answering, the
                  next question will appear.
                </li>
              </ul>
            </li>
            <li>
              <b>Result and Feedback:</b>
              <ul>
                <li>
                  At the end of the test, you’ll receive a detailed analysis of
                  your <b>Officer-Like Qualities (OLQs)</b>.
                </li>
                <li>
                  You’ll learn:
                  <ul>
                    <li>
                      Which OLQs do you already possess and to what extent?
                    </li>
                    <li>Which OLQs need improvement?</li>
                    <li>
                      Practical tips on{" "}
                      <b>how to develop the areas that need work</b>.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Take this test as a step towards self-improvement and better
            preparation for your SSB journey!
          </p>
          <h2 className="subHeading">
            Step-by-Step Guide to Use OLQs Analysis
          </h2>
          <h3 className="tab_heading">
            <b>Step 1.</b> Navigate to the Home Page {`>>`}{" "}
            <Link to="/chat">Ask Your SSB Mentor Now</Link>
          </h3>
          <img
            src={olq_1}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 2.</b> Click <b>“Choose Classroom”</b>
          </h3>
          <img
            src={olq_2}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step-3.</b> Click <b>“OLQs Analysis”</b>
          </h3>
          <img
            src={olq_3}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 4.</b> Click the <b>“Start”</b> button
          </h3>
          <img
            src={olq_4}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step-5. Read the Instructions & Start the Test</b>
          </h3>
          <img
            src={olq_5}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step-6. Attempt the test</b>
          </h3>
          <img
            src={olq_6}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <br />
          <h3 className="tab_heading">Next Steps</h3>
          <p>
            Use your score as a roadmap to understand your strengths and areas
            for improvement. By working on these qualities, you’ll not only
            increase your chances of selection but also grow into a confident
            and capable leader.
          </p>
          <br />
          <p>
            Remember, becoming an officer is a journey of self-discovery and
            development—so stay focused and keep working towards your goal! 💪
          </p>
          <br />
          <p>
            Jai Hind!!
            <br />
            Over & Out
          </p>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default OLQAnalyseHowUse;
