import React, { Fragment } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import GptSearch from "./component/ssbGpt/gptSearch";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChapterDetail from "./component/ssbGpt/ChapterDetail";
import CustomVideoPlayer from "./component/ssbGpt/video";
import Login from "./pages/Login";
import Interview from "./component/ssbGpt/Interview";
import ForgotPassword from "./pages/ForgetPassword";
import InterviewFeedback from "./component/ssbGpt/InterviewFeedback";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SrtAttempt from "./component/ssbGpt/SrtAttempt";
import AiSpokenEnglish from "./component/ssbGpt/AiSpokenEnglish";
import PsychologyTest from "./component/ssbGpt/PsychologyTest";
import Home from "./pages/Home";
import Binaural from "./pages/Binaural";
import RegisterPage from "./pages/RegisterPage";
import Register from "./pages/Register";
import TeacherRegister from "./pages/TeacherRegister";
import ParentRegister from "./pages/ParentRegister";
import AboutUs from "./component/ssbGpt/AboutUs";
import ContactUs from "./component/ssbGpt/ContactUs";
import RefundAndCancellationPolicy from "./component/ssbGpt/RefundAndCancellationPolicy.js";
import CaptainAlphaProfile from "./component/profiles/CaptainAlphaProfile.js";
import { useSelector } from "react-redux";
import Loader from "./component/reusable/Loader.js";
import FitnessCoachProfile from "./component/profiles/FitnessCoachProfile.js";
import CPSSExpertProfile from "./component/profiles/CPSSExpertProfile.js";
import EnglishTrainerProfile from "./component/profiles/EnglishTrainerProfile.js";
import MedicalExpertProfile from "./component/profiles/MedicalExpertProfile.js";
import ScrollToTop from "./component/reusable/ScrollToTop.js";
import { useAuth } from "./context/userContext.js";
import RedirectSpinner from "./component/reusable/RedirectSpinner.js";
import StudentProfile from "./pages/StudentProfile.js";
import TeacherProfile from "./pages/TeacherProfile.js";
import ParentProfile from "./pages/ParentProfile.js";
import HowToUse from "./pages/HowToUse.js";
import ProfileHelp from "./pages/ProfileHelp.js";
import SideSocialIcons from "./component/reusable/SideSocialIcons.js";
import StudentsOfTeacher from "./pages/StudentsOfTeacher.js";
import PaymentPage from "./pages/PaymentPage.js";
import PushupGuidance from "./component/ssbGpt/exercisePages/PushupGuidance.js";
import BodySquatsGuidance from "./component/ssbGpt/exercisePages/BodySquatsGuidance.js";
import ReverseLungeGuidance from "./component/ssbGpt/exercisePages/ReverseLungeGuidance.js";
import TricepsDipsGuidance from "./component/ssbGpt/exercisePages/TricepsDipsGuidance.js";
import BurpeeGuidance from "./component/ssbGpt/exercisePages/BurpeeGuidance.js";
import CalfRaisesGuidance from "./component/ssbGpt/exercisePages/CalfRaisesGuidance.js";
import CrunchGuidance from "./component/ssbGpt/exercisePages/CrunchesGuidance.js";
import KneePushUpGuidance from "./component/ssbGpt/exercisePages/KneePushUpGuidance.js";
import GluteBridgeGuidance from "./component/ssbGpt/exercisePages/GluteBridgeGuidance.js";
import JumpingJackGuidance from "./component/ssbGpt/exercisePages/JumpingJacksGuidance.js";
import LegRaisesGuidance from "./component/ssbGpt/exercisePages/LegRaisesGuidance.js";
import StepUpsGuidance from "./component/ssbGpt/exercisePages/StepUpsGuidance.js";
import BiCycleGuidance from "./component/ssbGpt/exercisePages/BiCycleGuidance.js";
import ArmCircleGuidance from "./component/ssbGpt/exercisePages/ArmCircleGuidance.js";
import WallSitsGuidance from "./component/ssbGpt/exercisePages/WallSitsGuidance.js";
import BearCrawlGuidance from "./component/ssbGpt/exercisePages/BearCrwalGuidance.js";
import PullUpsGuidance from "./component/ssbGpt/exercisePages/PullUpsGuidance.js";
import Lecturette from "./component/ssbGpt/Lecturette.js";
import IshiharaTest from "./component/ssbGpt/IshiharaTest.js";
import IshiharaInstruction from "./component/ssbGpt/IshiharaInstruction.js";
import MemoryRecallTest from "./component/ssbGpt/cpss/MemoryRecallTest.js";
import VelocityTest from "./component/ssbGpt/cpss/VelocityTest.js";
import EmojiTest from "./component/ssbGpt/cpss/EmojiTest.js";
import DualTaskTest from "./component/ssbGpt/cpss/DualTaskTest.js";
import SensorMotorTest from "./component/ssbGpt/cpss/SensorMotorTest.js";
import SpeedAnticipationTest from "./component/ssbGpt/cpss/SppedAntipicationTest.js";
import PursuitTrackingTest from "./component/ssbGpt/cpss/PursuitTrackingtest.js";
import DotEstimateTest from "./component/ssbGpt/cpss/DotEstimateTest.js";
import MockPPDT from "./component/ssbGpt/MockPPDT.js";
import CPSSHowUse from "./pages/howToUse/CPSSHowUse.js";
import SpokenEnglishHowUse from "./pages/howToUse/SpokenEnglishHowUse.js";
import OLQAnalyseHowUse from "./pages/howToUse/OLQAnalyseHowUse.js";
import SSBMentorHowUse from "./pages/howToUse/SSBMentorHowUse.js";
import EyeTest from "./component/ssbGpt/cpss/EyeTest.js";
import EyeInstruction from "./component/ssbGpt/cpss/EyeInstruction.js";
import MockPsychologyHowUse from "./pages/howToUse/MockPsychologyHowUse.js";
import OIRHowUse from "./pages/howToUse/OIRHowUse.js";
import PhysicalHowUse from "./pages/howToUse/PhysicalHowUse.js";
import MedicalHowUse from "./pages/howToUse/MedicalHowUse.js";
import GroupDiscussionHowUse from "./pages/howToUse/GroupDiscussionHowUse.js";
import PsychologyTestHowUse from "./pages/howToUse/PsychologyTestHowUse.js";
import PPDTHowUse from "./pages/howToUse/PPDTHowUse.js";
import GTOHowUse from "./pages/howToUse/GTOHowUse.js";
import CoursesHowUse from "./pages/howToUse/CoursesHowUse.js";
import MockInterviewHowUse from "./pages/howToUse/MockInterviewHowUse.js";
import CurrentAffairsHowUse from "./pages/howToUse/CurrentAffairsHowUse.js";
import NotesHowUse from "./pages/howToUse/NotesHowUse.js";
import SSBGPTHowUse from "./pages/howToUse/SSBGPTHowUse.js";
// import CustomVideoPlayer from "./video";

const App = () => {
  const { loading, isAuthenticated } = useSelector((state) => state.profile);
  const [auth] = useAuth();
  const user = auth?.user;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <ToastContainer />
          <ScrollToTop />
          <SideSocialIcons />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/binaural-music" element={<Binaural />} />
            <Route path="/ishihara-detail" element={<IshiharaInstruction />} />
            <Route path="/ishihara-test" element={<IshiharaTest />} />

            <Route path="/eye-test" element={<EyeTest />} />

            <Route path="/more-about-eye-test" element={<EyeInstruction />} />

            {/* CPSS TEST ROUTE STARTS  */}

            <Route path="/memory-recall-test" element={<MemoryRecallTest />} />
            <Route path="/vigilance-test" element={<EmojiTest />} />
            <Route path="/dual-task-test" element={<DualTaskTest />} />
            <Route path="/sensor-motor-test" element={<SensorMotorTest />} />
            <Route path="/dot-estimation-test" element={<DotEstimateTest />} />

            <Route path="/velocity-test" element={<VelocityTest />} />

            <Route
              path="/speed-anticipation-test"
              element={<SpeedAnticipationTest />}
            />
            <Route
              path="/pursuit-tracking-test"
              element={<PursuitTrackingTest />}
            />

            <Route path="/eye-test" element={<EyeTest />} />

            {/* EXERCISE GUIDANCE PAGES  */}

            <Route path="/about-push-ups" element={<PushupGuidance />} />
            <Route path="/about-body-squats" element={<BodySquatsGuidance />} />
            <Route
              path="/about-reverse-lunges"
              element={<ReverseLungeGuidance />}
            />
            <Route
              path="/about-mountain-climber"
              element={<ReverseLungeGuidance />}
            />
            <Route
              path="/about-knee-push-ups"
              element={<KneePushUpGuidance />}
            />
            <Route path="/about-burpee" element={<BurpeeGuidance />} />
            <Route path="/about-calf-raises" element={<CalfRaisesGuidance />} />
            <Route path="/about-crunch" element={<CrunchGuidance />} />

            <Route
              path="/about-glute-bridge"
              element={<GluteBridgeGuidance />}
            />
            <Route
              path="/about-jumping-jack"
              element={<JumpingJackGuidance />}
            />
            <Route path="/about-leg-raises" element={<LegRaisesGuidance />} />
            <Route path="/about-step-up" element={<StepUpsGuidance />} />
            <Route
              path="/about-tricep-dips"
              element={<TricepsDipsGuidance />}
            />

            <Route path="/about-lunge" element={<ReverseLungeGuidance />} />
            <Route
              path="/about-bicycle-crunches"
              element={<BiCycleGuidance />}
            />
            <Route path="/about-wall-sit" element={<WallSitsGuidance />} />
            <Route path="/about-arm-circles" element={<ArmCircleGuidance />} />

            <Route path="/about-bear-crawl" element={<BearCrawlGuidance />} />
            <Route path="/about-pull-ups" element={<PullUpsGuidance />} />

            {/* <Route path="/chat" element={<GptSearch />} /> */}
            <Route
              path="/"
              element={
                isAuthenticated && user?._id ? <Outlet /> : <RedirectSpinner />
              }
            >
              <Route path="chat" element={<GptSearch />} />
            </Route>
            {/* <Route path="/exercise" element={<ExerciseTracker />} /> */}

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/student-register" element={<Register />} />
            <Route path="/teacher-register" element={<TeacherRegister />} />
            <Route path="/parent-register" element={<ParentRegister />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/video" element={<CustomVideoPlayer />} />
            <Route path="/interview" element={<Interview />} />
            <Route path="/lecturette" element={<Lecturette />} />

            <Route path="/feedback" element={<InterviewFeedback />} />
            <Route
              path="/psychology-test-attempt"
              element={<PsychologyTest />}
            />
            <Route path="/srt-practice-test" element={<SrtAttempt />} />
            <Route path="/spoken-english" element={<AiSpokenEnglish />} />
            <Route path="/term-of-use" element={<TermsCondition />} />
            <Route path="/privacy-policies" element={<PrivacyPolicy />} />
            <Route path="/chapter-details/:id" element={<ChapterDetail />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/refund-cancellation-policy"
              element={<RefundAndCancellationPolicy />}
            />
            <Route path="/mock-ppdt" element={<MockPPDT />} />

            {/* Routes For Student Start */}
            <Route
              path="/student"
              element={
                isAuthenticated && user?.role === "11" ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<StudentProfile />} />
              <Route path="how-to-use" element={<HowToUse />} />
              <Route path="payment" element={<PaymentPage />} />
              <Route path="help" element={<ProfileHelp />} />
            </Route>
            {/* Routes For Student Ends */}

            {/* Routes For Teacher Start */}
            <Route
              path="/teacher"
              element={isAuthenticated ? <Outlet /> : <RedirectSpinner />}
            >
              <Route path="profile" element={<TeacherProfile />} />
              <Route path="students" element={<StudentsOfTeacher />} />
              <Route path="help" element={<ProfileHelp />} />
              <Route path="payment" element={<PaymentPage />} />
            </Route>
            {/* Routes For Teacher Ends */}

            {/* Routes For Parent Start */}
            <Route
              path="/parent"
              element={isAuthenticated ? <Outlet /> : <RedirectSpinner />}
            >
              <Route path="profile" element={<ParentProfile />} />
              <Route path="payment" element={<PaymentPage />} />
              <Route path="help" element={<ProfileHelp />} />
            </Route>
            {/* Routes For Parent Ends */}

            {/* Captains Profile Pages Start */}
            <Route
              path="/profile/captain-alpha"
              element={<CaptainAlphaProfile />}
            />
            <Route
              path="/profile/fitness-coach"
              element={<FitnessCoachProfile />}
            />
            <Route
              path="/profile/cpss-expert"
              element={<CPSSExpertProfile />}
            />
            <Route
              path="/profile/english-trainer"
              element={<EnglishTrainerProfile />}
            />
            <Route
              path="/profile/medical-expert"
              element={<MedicalExpertProfile />}
            />
            {/* Captains Profile Pages Ends */}

            {/* HOW TO USE CLASSROOM PAGES STRAT */}
            <Route path="/how-use-cpss-classroom" element={<CPSSHowUse />} />
            <Route
              path="/how-use-spoken-en-classroom"
              element={<SpokenEnglishHowUse />}
            />
            <Route
              path="/how-use-olq-analyse-classroom"
              element={<OLQAnalyseHowUse />}
            />
            <Route
              path="/how-use-ssb-mentor-classroom"
              element={<SSBMentorHowUse />}
            />
            <Route
              path="/how-use-spoken-en-classroom"
              element={<SpokenEnglishHowUse />}
            />
            <Route
              path="/how-use-olq-analyse-classroom"
              element={<OLQAnalyseHowUse />}
            />
            <Route
              path="/how-use-ssb-mentor-classroom"
              element={<SSBMentorHowUse />}
            />

            <Route
              path="/how-use-mock-psychology-classroom"
              element={<MockPsychologyHowUse />}
            />
            <Route path="/how-use-oir-classroom" element={<OIRHowUse />} />
            <Route
              path="/how-use-physical-classroom"
              element={<PhysicalHowUse />}
            />
            <Route
              path="/how-use-medical-classroom"
              element={<MedicalHowUse />}
            />
            <Route
              path="/how-use-group-discussion-classroom"
              element={<GroupDiscussionHowUse />}
            />
            <Route
              path="/how-use-psychology-test-classroom"
              element={<PsychologyTestHowUse />}
            />
            <Route path="/how-use-ppdt-classroom" element={<PPDTHowUse />} />
            <Route path="/how-use-gto-classroom" element={<GTOHowUse />} />
            <Route
              path="/how-use-courses-classroom"
              element={<CoursesHowUse />}
            />
            <Route
              path="/how-use-mock-interview-classroom"
              element={<MockInterviewHowUse />}
            />
            <Route
              path="/how-use-current-affairs-classroom"
              element={<CurrentAffairsHowUse />}
            />
            <Route path="/how-use-notes-classroom" element={<NotesHowUse />} />
            <Route path="/how-use-ssb-gpt" element={<SSBGPTHowUse />} />
            {/* HOW TO USE CLASSROOM PAGES ENDS */}
          </Routes>
        </Fragment>
      )}
    </>
  );
};

export default App;
