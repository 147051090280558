import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/lectrette.scss";
import { useAuth } from "../../context/userContext";
import moment from "moment";
import { captainAlphaModal } from "../../assets/images";
import { FaVolumeUp } from "react-icons/fa";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import lecturetteGroup from "../../images/lecturetteGroup.mp4";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLecturetteErrors,
  getLecturetteTopics,
} from "../../redux/actions/lecturetteAction";
import Loader from "../reusable/Loader";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";

const Lecturette = () => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const dispatch = useDispatch();

  const [auth] = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(180);
  const [blinking, setBlinking] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("");
  

  // GET LECTURETTE TOPICS START
  const { data, loading, error } = useSelector(
    (state) => state.lecturetteTopics
  );

  useEffect(() => {
    dispatch(getLecturetteTopics());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearLecturetteErrors());
    }
  }, [dispatch, error]);

  // GET LECTURETTE TOPICS ENDS

  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    if (step === 2) {
      startCamera();
      startListening();
    }
    return () => {
      stopCamera();
    };
  }, [step]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream; // Save the stream to a reference
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
      if (error.name === "NotAllowedError") {
        alert("Camera access is required to use this feature.");
      } else if (error.name === "NotFoundError") {
        alert("No camera found on this device.");
      }
    }
  };
  const stopCamera = () => {
    if (streamRef.current) {
      // Stop all video tracks
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Clear the reference
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Clear the reference
    }

    // Stop microphone
    SpeechRecognition.stopListening();
  };

  // const playBellSound = () => {
  //   const audio = new Audio("../../audio/siren.mp3");
  //   audio.loop = true;
  //   audio.play();
  // };

  useEffect(() => {
    if (timer > 0 && data?.success) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    if (timer === 0) {
      if (step === 2) {
        // playBellSound();
        setBlinking(true);
      } else {
        setStep(2);
        setTimer(180);
      }
    }
  }, [timer, step, data?.success]);

  const timerProgress = ((180 - timer) / 180) * 100;
  const formattedTime = moment.utc(timer * 1000).format("mm:ss");

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      return null;
    }
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      window.speechSynthesis.cancel();
    };
  }, [browserSupportsSpeechRecognition]);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  useEffect(() => {
    if (transcript) {
      setSearchValue(transcript);
    }
  }, [transcript]);

  // useEffect(()=>{

  // },[])

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      uploadInterviewAnswer();
    }
  };

  const uploadInterviewAnswer = () => {};

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  const selectTopic = (topic) => {
    setSelectedTopic(topic);
    if (selectedTopic) {
      toast.warning("Topic selected, You will redirect when time up.");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <GptLayout>
          <section className="lecture_interview_wrapper">
            <div className="container">
              {step === 1 && (
                <div className="interview_sec">
                  <h2>Your Topics are</h2>
                  <div
                    className="timer"
                    style={{
                      background: `conic-gradient(red ${timerProgress}%, #ddd ${timerProgress}% 100%)`,
                    }}
                  >
                    <p>{formattedTime}</p>
                  </div>
                  <div className="list">
                    <ReactMarkdown
                      components={{
                        ol: ({ node, ...props }) => <ol {...props} />,
                        li: ({ node, children, ...props }) => {
                          return (
                            <li
                              {...props}
                              onClick={() =>
                                selectTopic(children?.props?.children)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {children}
                            </li>
                          );
                        },
                      }}
                    >
                      {data?.answer}
                    </ReactMarkdown>
                  </div>
                  {selectedTopic && (
                    <div className="selected_Topic">
                      <span>Selected Topic:</span> {selectedTopic}
                    </div>
                  )}
                </div>
              )}
              {step === 2 && selectedTopic && (
                <div className="screen">
                  <div className="title">
                    <h2>LECTURETTE</h2>
                    <p className="selectedTopic">{selectedTopic}</p>
                  </div>
                  <div
                    className="timer"
                    style={{
                      background: `conic-gradient(red ${timerProgress}%, #ddd ${timerProgress}% 100%)`,
                    }}
                  >
                    <p
                      style={{
                        animation: blinking
                          ? "blinkingText 1s infinite"
                          : "none",
                      }}
                    >
                      {formattedTime}
                    </p>
                  </div>
                  <div className="photos">
                    <div className="left">
                      {/* <img src={lectureDiscuss} alt="lecture" /> */}
                      <video
                        src={lecturetteGroup}
                        playsInline
                        webkit-playsinline="true"
                        autoPlay
                        muted
                        loop
                        ref={(video) => {
                          if (video) video.playbackRate = 0.3; // Set playback speed (e.g., 0.5 for half speed)
                        }}
                      ></video>
                    </div>
                    <div className="right">
                      <div className="modal">
                        <div className="img">
                          <img
                            src={captainAlphaModal}
                            alt="captainAlphaModal"
                          />
                        </div>
                        <h4>Capt. Alpha</h4>
                        <p>AI SSB Mentor</p>
                      </div>
                      <div className="user_camera">
                        <video
                          ref={videoRef}
                          playsInline
                          webkit-playsinline="true"
                          autoPlay
                          muted
                          loop
                        ></video>
                        <FaVolumeUp />
                        <h4>{auth?.user?.first_name}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="input_box">
                    <div>
                      <textarea
                        type="text"
                        value={searchValue}
                        style={{ border: "1px solid #fff" }}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Begin your Lecturette now!"
                      />
                      <div className="btnGrp">
                        <button
                          className="submit"
                          onClick={uploadInterviewAnswer}
                        >
                          Submit
                        </button>
                        <button className="submit" onClick={startListening}>
                          Refresh Mic
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default Lecturette;
