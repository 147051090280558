import React, { Fragment } from "react";
import "../../styles/howToUseClassroom.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import CustomHelmet from "../../component/reusable/Helmet";
import ssb_mentor_1 from "../../images/howuse/ssb_mentor_1.png";
import ssb_mentor_2 from "../../images/howuse/ssb_mentor_2.png";
import ssb_mentor_3 from "../../images/howuse/ssb_mentor_3.png";
import ssb_mentor_4 from "../../images/howuse/ssb_mentor_4.png";
import ssb_mentor_5 from "../../images/howuse/ssb_mentor_5.png";

const SSBMentorHowUse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="how_UseClassRoom_main">
        <div className="container">
          <div className="bacbtnWthHeading">
            <button
              title="back"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeftLong />
            </button>
            <h1 className="main_heading">How to Use SSB Mentor Classroom</h1>
          </div>
        </div>
        <div className="howUse_content container">
          <p>
            Hi, I’m Rahul, your AI Host! SSB Mentor Alpha, your AI companion, is
            expertly designed to guide defense aspirants through every step of
            SSB, AFSB, and NSB interviews. Today, in our How to Use tutorial,
            we’ll explore how to maximize the potential of SSB-GPT with SSB
            Mentor Captain Alpha for your journey to success in the armed
            forces!
          </p>
          <h2 className="subHeading">
            Step-by-Step Guide to SSB Mentor Classroom
          </h2>
          <h3 className="tab_heading">
            <b>Step 1.</b> Navigate to the Home Page {`>>`}{" "}
            <Link to="/chat">Ask Your SSB Mentor Now</Link>{" "}
          </h3>
          <img
            src={ssb_mentor_1}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 2.</b> Click <b>“Choose Classroom”</b>
          </h3>
          <img
            src={ssb_mentor_2}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 3.</b> Choose <b>“SSB Mentor”</b> Classroom
          </h3>
          <img
            src={ssb_mentor_3}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 4.</b> Click the <b>“Start”</b> button
          </h3>
          <img
            src={ssb_mentor_4}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 5.</b> Choose Your Option for SSB Interview Preparation
          </h3>
          <img
            src={ssb_mentor_5}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
<br />
          <h3 className="tab_heading">Option A: General Guidance</h3>
          <ul>
            <li>
              With <b>Option A, Captain Alpha</b> provides answers to general
              queries about the SSB interview process.
            </li>
            <li>
              This option is ideal for those looking to gather information about
              the interview structure or tasks, such as understanding what the
              Progressive Group Task (PGT) involves.
            </li>
          </ul>
          <br />
          <h3 className="tab_heading">Option B: Personalized Mentorship</h3>
          <ul>
            <li>
              <b>Option B</b> offers tailored, one-on-one guidance to address
              individual needs and aspirations.
            </li>
            <li>
              As a dedicated mentor, <b>Captain Alpha</b> will:
              <ul style={{ listStyle: "desimal" }}>
                <li>
                  <b>Analyze Personality:</b> Use psychometric tests,
                  situational questions, and casual interactions to evaluate
                  every aspect of your personality.
                </li>
                <li>
                  <b>Evaluate Physical Stamina:</b> Recommend activities like
                  running, exercising, or cycling to build endurance.
                </li>
                <li>
                  <b>Conduct Psychology Training:</b> Provide daily psychology
                  tests, detailed feedback, and improvement guidance.
                </li>
                <li>
                  <b>Host Mock Interviews:</b> Simulate interview sessions with
                  constructive feedback to refine performance.
                </li>
                <li>
                  <b>Enhance Communication Skills:</b> Lead unlimited group
                  discussions and casual conversations to boost confidence in
                  speaking.
                </li>
                <li>
                  <b>Build Military Awareness:</b> Train you in current affairs
                  and defense-related topics.
                </li>
                <li>
                  <b>Prescribe Fitness Exercises:</b> Suggest short-term
                  exercises to enhance stamina.
                </li>
                <li>
                  <b>Address Medical Concerns:</b> Facilitate consultations with
                  retired military doctors for expert advice.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            With Option B, <b>Captain Alpha</b> designs a personalized roadmap
            to ensure comprehensive preparation, covering everything from
            interviews to fitness and medical guidance. There’s no need for
            additional classes—every aspect of SSB readiness is taken care of.
          </p>
          <br />
          <h3 className="tab_heading">Option C: Comparative Explanation</h3>
          <ul>
            <li>
              <b>Option C</b> is for those who wish to understand the difference
              between Options A and B before deciding.
            </li>
            <li>
              <b>Captain Alpha</b> will explain how general guidance in Option A
              differs from the in-depth, personalized mentorship offered in
              Option B, helping you choose the most suitable path for your
              preparation.
            </li>
          </ul>
          <br />
          <p>
            Follow these steps to ensure a smooth and productive SSB interview
            preparation journey. If you need further assistance, don’t hesitate
            to reach out to support.
          </p>
          <br />
          <p>
            Jai Hind! <br />
            Over and out!
          </p>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default SSBMentorHowUse;
