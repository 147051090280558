import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/gptSearch.scss";
import "../../styles/gptSearchMobileStyle.scss";
import { AiFillAudio, AiFillDislike, AiFillLike } from "react-icons/ai";
import axios from "axios";
import { LuBookmark, LuLoader } from "react-icons/lu";
import { RiVoiceprintFill } from "react-icons/ri";
import GptSidebar from "./gptSidebar";
import { TiArrowUp } from "react-icons/ti";
import { MdQuestionMark } from "react-icons/md";
import { useTour } from "@reactour/tour";
import {
  CPSS_logo,
  Gto,
  Lecturette,
  OIR,
  captainAlphaModal,
  courses,
  cpssModal,
  currentAffair,
  defaultFemale,
  defaultMale,
  defaultUser,
  doctor,
  group_discussion_icon,
  // hideVideo,
  interview,
  // lamp1,
  // lamp2,
  // lamp3,
  // linkSearch,
  loaderGif,
  logo_mobile,
  markModal,
  medicalModal,
  olq,
  physicalModal,
  ppdtIcon,
  profile_mic,
  profile_mic_active,
  psychology,
  // searchBook,
  spokenEnglish,
  trainer,
} from "../../assets/images";
import userService from "../../services/userService";
import bookService from "../../services/bookService";
import { toast } from "react-toastify";
import { BsPlayCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { userEndPoints } from "../../config";
// import Loader from "../reusable/Loader";
import { useAuth } from "../../context/userContext";
// import moment from "moment";
import CustomHelmet from "../reusable/Helmet";
import WhatCanDo from "./WhatCanDo";
import GptBooks from "./gptBooks";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import logo_mobile_small from "../../images/logo_mobile.svg";
import SSBMentorIcon from "../../images/SSBMentorIcon.png";
import CustomButton from "../reusable/CustomButton";
import FlightSimulation from "./FlightSimulation";
import choiceBlue from "../../images/choiceBlue.png";
import choiceOrange from "../../images/choiceOrange.png";
import GptChatHeader from "../reusable/GptChatHeader";
import ReactMarkdown from "react-markdown";
import ppdtsheet from "../../images/ppdtsheet.pdf";
import VideoPopup from "../reusable/VideoPopup";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const GptSearch = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const localToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : {};

    const data = {
      success: true,
      token: token,
    };
    if (!localToken) {
      localStorage.setItem("ssb-token", data);
    }
  }, [location.search]);

  const [searchValue, setSearchValue] = useState("");
  const [placeHolderInput, setPlaceholderInput] = useState(
    ""
    // "Search anything related to SSB"
  );
  const [activeTab, setActiveTab] = useState("");
  const [activeinput, setActiveInput] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  // const [mediaFile, setMediaFile] = useState([]);
  const [ppdtImage, setPpdtImage] = useState();
  const [imageQuery, setImageQuery] = useState("");
  const [ppdtTimer, setPpdtTimer] = useState(30);
  const [pdfFile, setPdfFile] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [gptMic, setGptMic] = useState("");
  const [gptSpeaker, setGptSpeaker] = useState("");
  const [captainImage, setCaptainImage] = useState("");

  const [srchToday, setSrchToday] = useState(false);

  const [welcomeText, setWelcomeText] = useState({});
  const [showExerciseBar, setshowExerciseBar] = useState(false);

  const [showClassCategory, setShowClassCategory] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [initialSearchType, setInitialSearchType] = useState("");
  const [initialQuestion, setInitialQuestion] = useState("");
  const [showFlightSimulation, setShowFlightSimulation] = useState(false);

  const noCategoryWlcmMsg = {
    logoImg: "",
    name: `Hello ${
      auth?.user?.first_name ? auth?.user?.first_name : "Warrior"
    }`,
    tagLine: "",
    description:
      "Let's gear up for SSB Interview prep – choose your classroom and let’s get to work!",
    tutorialLink:
      "https://www.youtube.com/embed/9b7jgLFnd5A?si=0UFWgAZZ52vj6pR2",
    startLink: "",
  };
  const captainAlphaWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "SSB Mentor",
    tagLine: "AI SSB Mentor Captain Alpha",
    description:
      "Ace your SSB interview with expert guidance from Captain Alpha. Get personalized tips and strategies to boost your chances of success!",
    redirectDescription:
      " Capt. Alpha is an AI-driven mentor modeled after the best practices and insights gathered from SSB assessors like Group Testing Officers (GTOs), Interviewing Officers (IOs), and Psychologists. Drawing upon years of experience in SSB evaluations and rigorous training, Capt. Alpha is equipped to guide aspiring candidates in all aspects of SSB preparation.",
    tutorialLink: "",
    startLink: "startQuestion",
  };
  const medicalWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Medical Officer",
    tagLine: "AI Medical Officer Ravinder Yadav",
    description:
      "Prepare for your medicals with guidance from AI  Medical Officer Ravinder Yadav. Get expert advice to ensure you're fully ready for your SSB medicals.",
    redirectDescription:
      "Dr. Ravinder Yadav is an advanced AI-powered medical expert developed to help candidates prepare thoroughly for the medical standards required to join the Indian Armed Forces. With insights from seasoned military doctors, Dr. Yadav provides guidance on everything from fitness standards to specific medical checks, ensuring you’re fully prepared for the medical assessment phase of the selection process.",
    tutorialLink: "",
    startLink: "startMedical",
  };
  const physicalWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Fitness Trainer",
    tagLine: "AI Fitness Trainer Vikram Kumar",
    description:
      "Get fit for your SSB interview with training from Vikram Kumar. Achieve your fitness goals and perform your best during the selection process!",
    redirectDescription:
      "Vikram Kumar is a cutting-edge AI fitness trainer developed to guide you through the physical challenges of the SSB selection process. With an in-depth understanding of the physical standards required in the Indian Armed Forces, Vikram delivers personalized workout programs, diet plans, and mental toughness exercises to help you build the stamina, strength, and endurance needed to succeed.",
    tutorialLink:
      "https://www.youtube.com/embed/DuxI7Yze6Wk?si=Wwi7q6wA_uGtavo2",
    startLink: "startPhysical",
  };
  const cpssWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "CPSS",
    tagLine: "Guided by AI CPSS Expert Sanjeev Kumar",
    description:
      "Master CPSS with Sanjeev Kumar—begin your practice today and take the first step toward soaring high!",
    redirectDescription:
      "Sanjeev Kumar is a dedicated AI expert in CPSS, crafted to support and train you in excelling at the Computerized Pilot Selection System, the gateway to becoming an Air Force pilot. With insights directly informed by experienced IAF CPSS experts, Sanjeev offers a tailored approach to mastering the specific skills, coordination, and mental agility required to clear the CPSS assessment with confidence.",
    tutorialLink:
      "https://www.youtube.com/embed/Rg6T_l0piKg?si=MZjOeTpLMgdCtSSj",
    startLink: "startCPSS",
  };
  const lecturetteWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Lecturette",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Master the Lecturette with Captain Alpha—begin your session now and excel with confidence!",
    tutorialLink: "",
    startLink: "startLecturette",
  };
  const spokenEnglishWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Spoken English",
    tagLine: "Guided by AI English trainer Mark",
    description:
      "Master Spoken English with Mark—start practicing today and take a confident step toward Recommendation.",
    redirectDescription:
      "Mark is a state-of-the-art AI language trainer designed to help SSB aspirants develop clear, confident, and effective English communication skills. Tailored to meet the needs of SSB interviews, Mark focuses on improving vocabulary, pronunciation, fluency, and confidence, helping you make a strong impression.",
    tutorialLink: "",
    startLink: "startSpokenEnglish",
  };
  const currentAffairsWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Current Affairs",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Stay updated with Captain Alpha—start practicing Current Affairs today and stay ahead in your SSB preparation!",
    tutorialLink: "",
    startLink: "startCurrentAffairs",
  };
  const mockInterviewWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Mock Interview",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Prepare for your Personal Interview with Captain Alpha—start practicing today and boost your confidence for SSB success!",
    tutorialLink: "",
    startLink: "startMockInterview",
  };
  const olqAnalysisWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "OLQs Analysis",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Discover your OLQs with Captain Alpha—analyze your strengths and create a personalized blueprint for SSB success!",
    tutorialLink:
      "https://www.youtube.com/embed/6G74Y2yVzNA?si=7Aahqm-PukfRcEU4",
    startLink: "startOlqAnalysis",
  };
  const coursesWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Courses",
    tagLine: "By DIPR Trained officers",
    description:
      "Enroll in our offline course at Alpha Regiment and receive personalized guidance from DIPR-trained officers—your pathway to SSB success!",
    tutorialLink: "",
    startLink: "startCourses",
  };
  const GTOWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "GTO",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Gear up for GTO tasks with Captain Alpha—practice today and master the GTO.",
    tutorialLink: "",
    startLink: "startGTO",
  };
  const PPDTWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "PPDT",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Practice PPDT under Captain Alpha's guidance—master story writing, narration, and group discussion to get screened in with SSB.",
    tutorialLink: "",
    startLink: "startPPDT",
  };
  const mockPsychologyWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Mock Psychology",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Take the psychology test and get expert feedback from Captain Alpha, your AI SSB mentor. Get insights tailored to help you excel in your SSB journey!",
    tutorialLink: "",
    startLink: "startMockPsychology",
  };
  const groupDiscussionWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "Group Discussion",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Prepare for your Group Discussion with expert guidance from Captain Alpha. Get personalized tips to improve your performance and stand out in your SSB interview!",
    tutorialLink: "",
    startLink: "startGroupDiscussion",
  };
  const OIRTestWlcmMsg = {
    logoImg: logo_mobile_small,
    name: "OIR Test",
    tagLine: "Guided by AI SSB Mentor Captain Alpha",
    description:
      "Boost your OIR test preparation with expert insights from Captain Alpha.",
    tutorialLink: "",
    startLink: "startOIRTest",
  };

  // ALL BOOK SAVE STATE STARTS
  // const todatDate = new Date();
  // const currentDate = moment(todatDate).format("YYYY-MM-DD");

  const handleVoice = () => {
    setIsListening(true);
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.maxResults = 10;
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchValue(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  const [disableVideo, setDisableVideo] = useState(
    () => JSON.parse(localStorage.getItem("videoSrch")) ?? true
  );

  const handleDisableVideo = () => {
    setDisableVideo((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("videoSrch", JSON.stringify(disableVideo));
  }, [disableVideo]);

  //  NO CATEGORY CHOSEN
  const handleNoCategory = () => {
    setSrchToday(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setPlaceholderInput("");
    setActiveInput(false);
    setMediaOpen(false);
    setActiveTab("");
    setOutputData([]);
    setWelcomeText(noCategoryWlcmMsg);
  };

  //  POST DATA TO QUESTION API START
  const handleAskQuestion = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to SSB");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("question");
    setOutputData([]);
    setWelcomeText(captainAlphaWlcmMsg);
  };

  //  POST DATA TO Medical API START
  const handleMedicalQuestion = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setPlaceholderInput("Ask anything related to medical");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("medical");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(medicalWlcmMsg);
  };

  //  POST DATA TO Physical API START
  const handlePhysicalQuestion = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to physical");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("physical");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(physicalWlcmMsg);
  };

  //  POST DATA TO CPSS API START
  const handleCPSSGuidance = () => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(false);
    setPlaceholderInput("Ask anything related to CPSS");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("CPSS");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(cpssWlcmMsg);
  };

  //  POST DATA TO CPSS API START
  const handleLecturetteGuidance = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("Lecturette");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(lecturetteWlcmMsg);
  };

  //  POST DATA TO SPOKEN ENGLISH API START
  const handleSpokenEnglish = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("spoken-english");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(spokenEnglishWlcmMsg);
  };

  //  POST DATA TO OIR TEST API START
  const handleOirTest = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("oir-test");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(OIRTestWlcmMsg);
  };

  //  POST DATA TO GROUP DISCUSSION API START
  const handleGroupDiscussion = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("group-discussion");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(groupDiscussionWlcmMsg);
  };

  //  POST DATA TO MOCK PSYCHOLOGY API START
  const handleMockPsychology = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("mock-psychology");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(mockPsychologyWlcmMsg);
  };

  //  POST DATA TO MOCK PPDT API START
  const handleMockPPDT = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("mock-PPDT");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(PPDTWlcmMsg);
  };

  //  POST DATA TO GTO API START
  const handleGTO = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("ssb-gto");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(GTOWlcmMsg);
  };

  //  POST DATA TO COURSES API START
  const handleCourses = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("courses");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(coursesWlcmMsg);
  };

  //  POST DATA TO OLQ ANALYSIS API START
  const handleOlqAnalysis = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("olq-analysis");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(olqAnalysisWlcmMsg);
  };

  //  POST DATA TO MOCK INTERVIEW API START
  const handleMockInterview = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("mock-interview");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(mockInterviewWlcmMsg);
  };

  //  POST DATA TO CURRENT AFFAIRS API START
  const handleCurrentAffair = () => {
    setShowClassCategory(false);
    setSrchToday(false);
    setMediaOpen(false);
    setActiveTab("current-affair");
    setConversationId("");
    setOutputData([]);
    setWelcomeText(currentAffairsWlcmMsg);
  };

  const handleSearchResult = async (query, multipleChoiceQu) => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = {
      question: searchValue || multipleChoiceQu,
      answer: "Loading...",
    };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      // const result = await userService.userChat(searchValue);
      let result;
      if (activeTab === "question") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/ssb-mentor-chat`,
          {
            inputText: searchValue,
            conversationId,
          }
        );
      }
      if (activeTab === "medical") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/medical-chat`,
          {
            inputText: searchValue,
            conversationId,
          }
        );
      }
      if (activeTab === "physical") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/physical-chat`,
          {
            inputText: searchValue || query,
            conversationId,
          }
        );
      }
      if (activeTab === "olq-analysis") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/olq-analysis-chat`,
          {
            inputText: searchValue || query,
            conversationId,
          }
        );
      }
      if (activeTab === "CPSS" && initialQuestion === "CPSS-A") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/cpss-query-chat`,
          {
            inputText: searchValue || query,
            conversationId,
          }
        );
      }
      if (activeTab === "CPSS" && initialQuestion === "CPSS-F") {
        result = await axios.post(
          `https://chat.ssbgpt.in/api/v1/cpss-reasoning-chat`,
          {
            inputText: searchValue || query || multipleChoiceQu,
            conversationId,
          }
        );
      }
      // if (!disableVideo) {
      //   var vdResult = await bookService.getVideoSearch(searchValue);
      // }

      if (result) {
        setConversationId(result?.data?.conversation_id);
        // const videoId = vdResult?.data?.video?.url;
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.answer.replace(/Alpha Bot:\s*/g, ""),
                  // url: videoId,
                  // videoTitle: vdResult?.data?.title,
                }
              : entry
          )
        );

        // IN FUNCTION SPEAK FUNCTIONALITY START
        if (gptSpeaker) {
          const speech = new SpeechSynthesisUtterance(result?.data?.answer);
          // speech.lang = "en-IN";
          speech.lang = "en-IN";
          window.speechSynthesis.speak(speech);
          speech.onend = () => {
            setSearchValue("");
            result = "";
          };
        }
        // IN FUNCTION SPEAK FUNCTIONALITY ENDS

        // let updatedList = [...localHistory];
        // const existingEntry = updatedList.find(
        //   (item) => item.dayData === currentDate
        // );

        // if (existingEntry) {
        //   existingEntry.searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //     url: videoId,
        //     videoTitle: vdResult?.data?.title,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //         url: videoId,
        //         videoTitle: vdResult?.data?.title,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  //  POST DATA TO API ENDS

  const handleMediaOpen = (e) => {
    e.stopPropagation();
    setMediaOpen(!mediaOpen);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (mediaOpen) {
        setMediaOpen(false);
        console.log("click outside, media closed");
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [mediaOpen]);

  // OPEN MEDIA SELECT BAR ENDS
  useEffect(() => {
    if (ppdtImage && ppdtTimer > 0) {
      setTimeout(() => {
        setPpdtTimer(ppdtTimer - 1);
      }, 1000);
    }
  });

  // IMAGE GENRATE STARTS
  const handleImageGenrate = async (query) => {
    setShowClassCategory(false);
    setInitialSearchType("");
    setInitialQuestion("");
    setIsLoading(true);
    setActiveInput(true);
    setActiveTab(query);
    setMediaOpen(false);
    setSrchToday(true);
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    setPlaceholderInput("Please wait for sometime we are generating the data");
    try {
      const apiResponseData = await userService.generateTatImage(query);
      if (apiResponseData) {
        setImageQuery(query);
        setPpdtImage(apiResponseData?.data?.data);
        setIsLoading(false);
        setMediaOpen(false);
        setPlaceholderInput("Ask anything related to SSB");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // IMAGE GENRATE ENDS

  // OIR MCQ'S TEST GENRATE START
  // const handleOIR = () => {
  //   setPlaceholderInput("Write the topic for generate MCQ");
  //   setActiveInput(true);
  //   setActiveTab("oir");
  //   setMediaOpen(false);
  //   setShowClassCategory(false);
  //   setInitialSearchType("");
  //   setInitialQuestion("");
  // };
  // OIR MCQ'S TEST GENRATE ENDS

  // Link TEST GENRATE START
  // const handleLinkSearch = () => {
  //   // setPlaceholderInput("Write the link to summerise the data");
  //   // setActiveInput(true);
  //   // setActiveTab("link");
  //   // setMediaOpen(false);
  //   toast.warning("Not integrated yet");
  // };

  const handleLinkGenrate = async () => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.linkSummarize, {
        input_text: searchValue,
      });

      if (result?.data?.response !== "No input provided") {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );

        // const currentDate = moment().format("YYYY-MM-DD");
        // let updatedList = [...questionList];

        // if (
        //   updatedList.length > 0 &&
        //   updatedList[updatedList.length - 1].dayData === currentDate
        // ) {
        //   updatedList[updatedList.length - 1].searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      } else {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: "Sorry i have no provided data for that",
                }
              : entry
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMediaOpen(false);
    }
  };
  //Link TEST GENRATE ENDS

  // HANDLE PDF UPLOAD START
  // const handlePdfSearch = () => {
  //   setActiveInput(true);
  //   setActiveTab("pdf");
  //   setMediaOpen(false);
  // };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
    }
  };

  const handlePdfUpload = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", pdfFile);

      const result = await axios.post(userEndPoints.pdfUpload, formData);

      if (result) {
        setOutputData([
          ...outputData,
          { answer: result?.data?.response, question: searchValue },
        ]);
        setIsLoading(false);
        setSearchValue("");
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  const handlePdfSearchQuestion = async () => {
    setIsLoading(true);
    setSearchValue("");
    setMediaOpen(false);
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.askQuestion, {
        input_text: searchValue,
      });

      if (result) {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );
        // const currentDate = moment().format("YYYY-MM-DD");
        // let updatedList = [...questionList];

        // if (
        //   updatedList.length > 0 &&
        //   updatedList[updatedList.length - 1].dayData === currentDate
        // ) {
        //   updatedList[updatedList.length - 1].searchData.push({
        //     question: searchValue,
        //     answer: result?.data?.response,
        //   });
        // } else {
        //   updatedList.push({
        //     dayData: currentDate,
        //     searchData: [
        //       {
        //         question: searchValue,
        //         answer: result?.data?.response,
        //       },
        //     ],
        //   });
        // }
        // setQuestionList(updatedList);
        // setlocalHistory(updatedList);
        // localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  // HANDLE PDF UPLOAD ENDS

  // HANDLE VIDEO SEARCH STARTS

  // const getYouTubeVideoId = (url) => {
  //   const regex = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // };

  // const fetchVideo = async () => {
  //   setIsLoading(true);
  //   setSearchValue("");
  //   setMediaOpen(false);
  //   const newEntry = {
  //     question: searchValue,
  //     url: null,
  //     videoTitle: "",
  //   };
  //   setOutputData((prevData) => [...prevData, newEntry]);
  //   try {
  //     const result = await bookService.getVideoSearch(searchValue);
  //     if (result) {
  //       // const videoId = getYouTubeVideoId(result?.data?.video?.url);
  //       const videoId = result?.data?.video?.url;

  //       setIsLoading(false);
  //       setPlaceholderInput("Search video");
  //       setActiveTab("video");
  //       setOutputData((prevData) =>
  //         prevData.map((entry, index) =>
  //           index === prevData.length - 1
  //             ? {
  //                 ...entry,
  //                 url: videoId,
  //                 answer: `Here is your video for your ${searchValue} search`,
  //                 videoTitle: result?.data?.title,
  //               }
  //             : entry
  //         )
  //       );

  //       const currentDate = moment().format("YYYY-MM-DD");
  //       let updatedList = [...questionList];
  //       if (
  //         updatedList.length > 0 &&
  //         updatedList[updatedList.length - 1].dayData === currentDate
  //       ) {
  //         updatedList[updatedList.length - 1].searchData.push({
  //           question: searchValue,
  //           answer: `Here is your video for your ${searchValue} search`,

  //           url: videoId,
  //         });
  //       } else {
  //         updatedList.push({
  //           dayData: currentDate,
  //           searchData: [
  //             {
  //               question: searchValue,
  //               answer: `Here is your video for your ${searchValue} search`,
  //               url: videoId,
  //             },
  //           ],
  //         });
  //       }
  //       setQuestionList(updatedList);
  //       setlocalHistory(updatedList);
  //       localStorage.setItem("userHistory", JSON.stringify(updatedList));
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setMediaOpen(false);
  //   }
  // };

  const handleVideoView = (url) => {
    // const videoId = getYouTubeVideoId(url);
    if (url) {
      navigate(`/video?streamId=${url}`);
    }
  };

  // HANDLE VIDEO SEARCH ENDS

  // STEPS FOR GIVING OVERVIEW
  const { currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 0 && isOpen) {
      setMediaOpen(true);
    }
  }, [currentStep, isOpen]);

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  // SCROLL HER PAGE AFTER EVERY SEARCH ENDS

  // CHECK USER TODAY SEARCH HISTORY STARTS
  const savedList = localStorage.getItem("userHistory");

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);
      if (data === null) {
        setSrchToday(true);
      } else {
        const today = new Date().toISOString().split("T")[0];
        const checkSearchToday = data.some((item) => item.dayData === today);
        const searchHistory = data.find(
          (item) => item.dayData === today
        )?.searchData;
        if (searchHistory) {
          setOutputData(searchHistory);
        }
        setSrchToday(checkSearchToday);
      }
      setQuestionList(data);
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // CHECK USER TODAY SEARCH HISTORY ENDS

  // HANDLE SPOKEN QUERY
  const handleSpokenQuery = (query) => {
    navigate(query);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      handleSearch();
    }
  };

  // HANDLE FUNCTION FOR ANALYSING USER INPUT
  const keywords = {
    srt: ["srt", "situation", "situations", "Give SRT", "Practice srt"],
    link: ["http", "https", "www"],
    ppdt: ["PP&DT", "PP & DT", "ppdt", "PPDT"],
    tat: ["tat", "TAT"],
    wat: ["word", "wat", "WAT"],
    interview: ["interview"],
    pdfSearch: ["pdf", "pdfSearch"],
    olq: ["olq", "OLQ", "olq analysis"],
    oir: ["Give OIR", "OIr test", "oir"],
    spoken: ["Spoken English", "Spoken", "english"],
    video: ["video", "learn by video"],
    courses: [
      "Coaching",
      "SSB Institute",
      "Psychology dossier checked by ex-psychologist ",
      "Mock Psychology",
      "Mock Interview",
      "Mock SSB",
    ],
  };

  const [message, setMessage] = useState("");
  const [suggestionTab, setSuggestionTab] = useState("ask");

  const handleInputChange = (e) => {
    const valueData = e.target.value;
    setSearchValue(valueData);
    // analyzeInput(valueData.trim());
  };

  //   const analyzeInput = (input) => {
  //     const lowerCaseInput = String(input).toLowerCase();
  //     let matchedKeyword = null;
  //     Object.keys(keywords).forEach((keyword) => {
  //       if (
  //         keywords[keyword].some((pattern) =>
  //           lowerCaseInput.includes(pattern.toLowerCase())
  //         )
  //       ) {
  //         matchedKeyword = keyword;
  //       }
  //     });
  //     if (matchedKeyword === "srt") {
  //       setMessage(`Would you like to get SRT?`);
  //       setSuggestionTab("srt");
  //     }
  //     if (matchedKeyword === "wat") {
  //       setMessage(`Would you like to get WAT?`);
  //     }
  //     if (matchedKeyword === "ppdt") {
  //       setMessage(`Would you like to get  PPDT / PP&DT?`);
  //       setSuggestionTab("PPDT");
  //     }
  //     if (matchedKeyword === "tat") {
  //       setMessage(`Would you like to get TAT ?`);
  //       setSuggestionTab("TAT");
  //     }
  //     if (matchedKeyword === "oir") {
  //       setMessage(`Would you like to get OIR ?`);
  //       setSuggestionTab("oir");
  //     }
  //     if (matchedKeyword === "pdfSearch") {
  //       setMessage(`Would you like a summary, MCQs, or specific information from the PDF?
  // `);
  //       setSuggestionTab("pdf");
  //     }
  //     if (matchedKeyword === "olq") {
  //       setMessage(
  //         `Do you mean you want to get your OLQ analysis through a simple psychometric test?`
  //       );
  //     }
  //     if (matchedKeyword === "link") {
  //       setMessage(
  //         `Would you like a summary, MCQs, or specific information from this link?`
  //       );
  //       setSuggestionTab("link");
  //     }

  //     if (matchedKeyword === "interview") {
  //       setMessage(`Want to give an interview`);
  //       setSuggestionTab("interview");
  //     }
  //     if (matchedKeyword === "courses") {
  //       setMessage(`Want to browse ssb courses`);
  //       setSuggestionTab("courses");
  //     }

  //     if (matchedKeyword === "video") {
  //       setMessage(`Would you like your questions answered in a video?
  // `);
  //       setSuggestionTab("video");
  //     }

  //     if (matchedKeyword === "spoken") {
  //       setMessage(`Do you mean you want to practice spoken English with AI?
  // `);
  //       setSuggestionTab("spokenEnglish");
  //     }

  //     return matchedKeyword;
  //   };

  // HANDLE SEARCH VIW POPUP MESSAGE

  // const handleSearchPop = () => {
  //   setMessage("");
  //   if (
  //     suggestionTab === "question" ||
  //     activeTab === "medical" ||
  //     activeTab === "physical" ||
  //     (activeTab === "CPSS" && initialQuestion === "CPSS-A") ||
  //     (activeTab === "CPSS" && initialQuestion === "CPSS-F") ||
  //     activeTab === "olq-analysis"
  //   ) {
  //     handleSearchResult();
  //   }
  //   if (suggestionTab === "oir") {
  //     toast.warning("We Launch OIR test feature soon");
  //   }
  //   if (suggestionTab === "link") {
  //     handleLinkGenrate();
  //   }
  //   if (suggestionTab === "pdf") {
  //     handlePdfUpload();
  //   }

  //   if (suggestionTab === "pdf_srch") {
  //     handlePdfSearchQuestion();
  //   }

  //   if (suggestionTab === "courses") {
  //     window.open("https://alpharegiment.in/ssb-courses");
  //   }
  // };

  const handleSearch = () => {
    setMessage("");
    if (
      activeTab === "question" ||
      activeTab === "medical" ||
      activeTab === "physical" ||
      (activeTab === "CPSS" && initialQuestion === "CPSS-A") ||
      (activeTab === "CPSS" && initialQuestion === "CPSS-F") ||
      activeTab === "olq-analysis"
    ) {
      handleSearchResult();
    }
    if (activeTab === "oir") {
      toast.warning("We Launch OIR test feature soon");
    }
    if (activeTab === "link") {
      handleLinkGenrate();
    }
    if (activeTab === "pdf") {
      handlePdfUpload();
    }

    if (activeTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }
  };

  // SPOKEN ENGLISH ENDS

  const [loadingNotes, setLoadingNotes] = useState(false);
  const [bookList, setBookList] = useState([]);
  const fetchBookList = async () => {
    try {
      setLoadingNotes(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setLoadingNotes(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setLoadingNotes(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);

  const [saveValue, setSaveValue] = useState();

  const handleSave = (idx) => {
    setSaveValue(idx);
  };

  const handleMicFunction = () => {
    // const micValue = localStorage.getItem("ssbgptmic");
    // if (micValue === "true") {
    //   localStorage.setItem("ssbgptmic", "false");
    // } else {
    //   localStorage.setItem("ssbgptmic", "true");
    // }
    if (gptMic) {
      setGptMic(false);
    } else {
      setGptMic(true);
      handleVoice();
    }
  };

  const handleSpeakFunction = () => {
    if (gptSpeaker) {
      setGptSpeaker(false);
    } else {
      setGptSpeaker(true);
    }
  };

  // GET type from URL
  const queryParams = new URLSearchParams(location.search);
  const modalType = queryParams.get("type");
  const urlStartType = queryParams.get("startType");
  const urlInitialQuestion = queryParams.get("initialQuestion");
  const isRedirected = queryParams.get("isRedirected");

  console.log("urlStartType", urlStartType);
  console.log("urlInitialQuestion", urlInitialQuestion);
  console.log("isRedirected", isRedirected);

  useEffect(() => {
    if (activeTab === "question") {
      setCaptainImage(captainAlphaModal);
      if (!isRedirected) {
        navigate("/chat?type=question");
      }
    } else if (activeTab === "Lecturette") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=Lecturette");
    } else if (activeTab === "medical") {
      setCaptainImage(medicalModal);
      if (!isRedirected) {
        navigate("/chat?type=medical");
      }
    } else if (activeTab === "physical") {
      setCaptainImage(physicalModal);
      if (!isRedirected) {
        navigate("/chat?type=physical");
      }
    } else if (activeTab === "CPSS") {
      setCaptainImage(cpssModal);
      if (!isRedirected) {
        navigate("/chat?type=CPSS");
      }
    } else if (activeTab === "spoken-english") {
      setCaptainImage(markModal);
      if (!isRedirected) {
        navigate("/chat?type=spoken-english");
      }
    } else if (activeTab === "oir-test") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=oir-test");
    } else if (activeTab === "group-discussion") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=group-discussion");
    } else if (activeTab === "mock-psychology") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=mock-psychology");
    } else if (activeTab === "mock-PPDT") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=mock-PPDT");
    } else if (activeTab === "ssb-gto") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=ssb-gto");
    } else if (activeTab === "courses") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=courses");
    } else if (activeTab === "olq-analysis") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=olq-analysis");
    } else if (activeTab === "mock-interview") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=mock-interview");
    } else if (activeTab === "current-affair") {
      setCaptainImage(captainAlphaModal);
      navigate("/chat?type=current-affair");
    } else {
      setCaptainImage("");
    }
  }, [activeTab, navigate, isRedirected]);

  // HANDLE MODAL TYPE BY PAGE LINK
  useEffect(() => {
    if (modalType === "question") {
      setCaptainImage(captainAlphaModal);
      handleAskQuestion();
    } else if (modalType === "Lecturette") {
      setCaptainImage(captainAlphaModal);
      handleLecturetteGuidance();
    } else if (modalType === "medical") {
      setCaptainImage(medicalModal);
      handleMedicalQuestion();
    } else if (modalType === "physical") {
      setCaptainImage(physicalModal);
      handlePhysicalQuestion();
    } else if (modalType === "CPSS") {
      setCaptainImage(cpssModal);
      handleCPSSGuidance();
    } else if (modalType === "spoken-english") {
      setCaptainImage(markModal);
      handleSpokenEnglish();
    } else if (modalType === "oir-test") {
      setCaptainImage(captainAlphaModal);
      handleOirTest();
    } else if (modalType === "group-discussion") {
      setCaptainImage(captainAlphaModal);
      handleGroupDiscussion();
    } else if (modalType === "mock-psychology") {
      setCaptainImage(captainAlphaModal);
      handleMockPsychology();
    } else if (modalType === "mock-PPDT") {
      setCaptainImage(captainAlphaModal);
      handleMockPPDT();
    } else if (modalType === "ssb-gto") {
      setCaptainImage(captainAlphaModal);
      handleGTO();
    } else if (modalType === "courses") {
      setCaptainImage(captainAlphaModal);
      handleCourses();
    } else if (modalType === "olq-analysis") {
      setCaptainImage(captainAlphaModal);
      handleOlqAnalysis();
    } else if (modalType === "mock-interview") {
      setCaptainImage(captainAlphaModal);
      handleMockInterview();
    } else if (modalType === "current-affair") {
      setCaptainImage(captainAlphaModal);
      handleCurrentAffair();
    } else {
      setCaptainImage("");
      handleNoCategory();
    }
  }, [modalType]);

  const handleShowClassCategory = () => {
    setShowClassCategory(true);
    setInitialSearchType("");
    setInitialQuestion("");
    setSrchToday(true);
  };

  const handleInitialStartAction = (startType) => {
    if (startType !== "startCourses") {
      setInitialSearchType(startType);
      setSrchToday(true);
      navigate(`/chat${location.search}&startType=${startType}`); // ADD START TYPE IN URL
    }
    if (startType === "startPhysical") {
      setShowSearchInput(true);
      handleSearchResult("Jai Hind Sir");
    }
    if (startType === "startSpokenEnglish") {
      window.open("/spoken-english", "_blank");
    }
    if (startType === "startMockPsychology") {
      window.open("/psychology-test-attempt", "_blank");
    }
    if (startType === "startCourses") {
      window.open("https://alpharegiment.in/ssb-courses", "_blank");
    }
    if (startType === "startMockInterview") {
      window.open("/interview", "_blank");
    }
  };

  // CAPTAIN ALPHA/ASK QUESTION INITIAL ACTIONS MESSAGES
  const handleQuestionInitialAction = (searchType) => {
    setConversationId("");
    setInitialQuestion(searchType);
    navigate(`/chat${location.search}&initialQuestion=${searchType}`); // ADD INITIAL QUESTION IN URL
    if (searchType === "option-A") {
      setShowSearchInput(true);
    } else if (searchType === "option-B") {
      setShowSearchInput(true);
    } else if (searchType === "option-C") {
      setShowSearchInput(false);
    } else {
      setShowSearchInput(false);
    }
  };

  // CPSS INITIAL ACTIONS MESSAGES
  const handleCPSSInitialAction = (searchType) => {
    setConversationId("");
    setInitialQuestion(searchType);
    navigate(
      `/chat?type=CPSS&startType=startCPSS&initialQuestion=${searchType}`
    );
  };

  // OLQ INITIAL ACTIONS MESSAGES
  const handleOLQInitialAction = (searchType) => {
    setConversationId("");
    setInitialQuestion(searchType);
    navigate(
      `/chat?type=olq-analysis&startType=startOlqAnalysis&initialQuestion=${searchType}`
    );
  };

  // PPDT INITIAL ACTIONS MESSAGES
  const handlePPDTInitialAction = (searchType) => {
    setConversationId("");
    setInitialQuestion(searchType);
    // navigate(
    //   `/chat?type=mock-PPDT&startType=startPPDT&initialQuestion=${searchType}`
    // );
    window.open("/mock-ppdt", "_blank");
  };

  const psychoMotorTestArr = [
    {
      name: "Sensory Motor Test",
      testLink: "/sensor-motor-test",
      knowMoreLink: "",
    },
    {
      name: "Control Velocity Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Dual Task Test",
      testLink: "/dual-task-test",
      knowMoreLink: "",
    },
    {
      name: "Dot Estimation Test",
      testLink: "/dot-estimation-test",
      knowMoreLink: "",
    },
    {
      name: "Speed Anticipation Test",
      testLink: "/speed-anticipation-test",
      knowMoreLink: "",
    },
    {
      name: "Size Anticipation Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Pursuit Tracking Test",
      testLink: "/pursuit-tracking-test",
      knowMoreLink: "",
    },
    {
      name: "Memory Recalling Test",
      testLink: "/memory-recall-test",
      knowMoreLink: "",
    },
    {
      name: "Color Naming Test",
      testLink: "",
      knowMoreLink: "",
    },
    {
      name: "Vigilance Test",
      testLink: "/vigilance-test",
      knowMoreLink: "",
    },
  ];

  useEffect(() => {
    const handleBackNavigation = () => {
      console.log("Back button clicked!");
      setOutputData([]);
      if (urlInitialQuestion) {
        setInitialQuestion(urlInitialQuestion);
      } else {
        setInitialQuestion("");
      }
      if (urlStartType) {
        setInitialSearchType(urlStartType);
      } else {
        setInitialSearchType("");
        setSrchToday(false);
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handleBackNavigation);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [urlInitialQuestion, urlStartType]); // Re-run the effect if the pathname changes

  useEffect(() => {
    // if url have iNITIALqUESTION//OPTIONS
    if (urlInitialQuestion) {
      setInitialQuestion(urlInitialQuestion);
    } else {
      setInitialQuestion("");
    }

    // IF URL HAVE STARTtYPE/initialSearchType
    if (urlStartType) {
      setInitialSearchType(urlStartType);
    } else {
      setInitialSearchType("");
      setSrchToday(false);
      setShowSearchInput(false);
    }

    if (urlStartType === "startQuestion") {
      if (urlInitialQuestion === "option-A") {
        setShowSearchInput(true);
        setSrchToday(true);
        setOutputData([]);
      } else if (urlInitialQuestion === "option-B") {
        setShowSearchInput(true);
        setSrchToday(true);
        setOutputData([]);
      } else if (urlInitialQuestion === "option-C") {
        setShowSearchInput(false);
        setSrchToday(true);
        setOutputData([]);
      } else {
        setShowSearchInput(false);
        setSrchToday(true);
        setInitialQuestion("");
        setOutputData([]);
      }
    }
  }, [urlInitialQuestion, urlStartType]);

  useEffect(() => {
    if (urlStartType === "startCPSS") {
      if (urlInitialQuestion === "CPSS-A") {
        setShowSearchInput(true);
        setSrchToday(true);
        setOutputData([]);
        handleSearchResult("Jai Hind Sir");
      } else if (urlInitialQuestion === "CPSS-F") {
        setShowSearchInput(true);
        setSrchToday(true);
        setOutputData([]);
        handleSearchResult("Start");
      } else {
        setShowSearchInput(false);
        setSrchToday(true);
        setOutputData([]);
      }
    }
  }, [urlInitialQuestion, urlStartType]);

  useEffect(() => {
    if (urlStartType === "startOlqAnalysis") {
      if (urlInitialQuestion === "start-action") {
        setShowSearchInput(true);
        setSrchToday(true);
        setOutputData([]);
        setPlaceholderInput("Ask Anything about OLQ Analysis");
        handleSearchResult("Hello");
      } else {
        setShowSearchInput(false);
        setSrchToday(true);
        setOutputData([]);
        setPlaceholderInput("");
      }
    }
  }, [urlInitialQuestion, urlStartType]);

  const [showChatBox, setShowChatBox] = useState(true);
  const [showBook, setShowBook] = useState(false);

  // SHOW CLASSROOM/CATEGORY PROMPT
  const handleShowClassroom = () => {
    setshowExerciseBar(false);
    setShowChatBox(true);
    setShowBook(false);
    navigate("/chat");
  };

  // SHOW EXERCISE BAR
  const handleShowExercise = () => {
    setshowExerciseBar(true);
    setShowChatBox(false);
    setShowBook(false);
  };

  // SHOW NOTES BAR
  const handleShowNotes = () => {
    setshowExerciseBar(false);
    setShowChatBox(false);
    setShowBook(true);
  };

  const mediaQuery = window.matchMedia("(max-width: 575px)");
  useEffect(() => {
    if (!showExerciseBar && !showChatBox && !showBook) {
      setShowChatBox(true);
    }

    if (mediaQuery.matches && (showChatBox || showBook)) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    } else {
      // Enable scrolling
      document.body.style.overflow = "";
      document.body.style.position = "";
    }

    // Cleanup when the component is unmounted or state changes
    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
    };
  }, [showExerciseBar, showChatBox, showBook, mediaQuery]);

  // FLIGHT SIMULATION INSTRUCTIONS SLIDER HANDLERS START
  const [currentFlightSimulationSlider, setCurrentFlightSimulationSlider] =
    useState(1);
  const handleFlightSimulationNext = () => {
    setCurrentFlightSimulationSlider(currentFlightSimulationSlider + 1);
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0); // Scroll to top with smooth animation
    }
  };

  const handleFlightSimulationPrev = () => {
    setCurrentFlightSimulationSlider(currentFlightSimulationSlider - 1);
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0); // Scroll to top with smooth animation
    }
  };
  // FLIGHT SIMULATION INSTRUCTIONS SLIDER HANDLERS ENDS

  // FOR DOWNLOAD PPDT SHEET
  const handlePPDTsheetDownload = () => {
    const link = document.createElement("a");
    link.href = ppdtsheet; // Path to your file
    link.download = "ppdtsheet.pdf"; // Desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [videoLink, setVideoLink] = useState("");
  const [showDetailVideo, setshowDetailVideo] = useState(false);
  // HANDLE HOW TO USE VIDEO POPUP
  const handleHowToUseVideo = (url) => {
    if (url) {
      setVideoLink(url);
      setshowDetailVideo(true);
    }
  };

  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <section className="gpt_layout_wrapper">
            <GptSidebar showExerciseBar={showExerciseBar} />
            <section
              className={`gpt_Search_box gpt_seachBox_mobile ${
                showChatBox ? "showChatBox" : ""
              }`}
            >
              <div className="input_box">
                {/* CHAT HEADER FOR MOBILE SCREEN STRAT */}
                {modalType && (
                  <GptChatHeader
                    handleSpeakFunction={handleSpeakFunction}
                    captainImage={captainImage}
                    gptSpeaker={gptSpeaker}
                    welcomeText={welcomeText}
                    handleShowExercise={handleShowExercise}
                    handleShowNotes={handleShowNotes}
                  />
                )}
                {/* CHAT HEADER FOR MOBILE SCREEN ENDS */}

                <div className={`srch_output custonScroll`} ref={scrollRef}>
                  {!srchToday && (
                    <div className="wlcm_msg">
                      <div className="modal_text">
                        <img
                          src={
                            welcomeText.logoImg
                              ? welcomeText.logoImg
                              : auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : auth?.user?.gender === "Male"
                              ? defaultMale
                              : auth?.user?.gender === "Female"
                              ? defaultFemale
                              : defaultUser
                          }
                          style={{
                            borderRadius: welcomeText.logoImg ? 0 : "50%",
                          }}
                          alt="ssb gpt mobile logo"
                          className="mobile_logo_small"
                        />
                        <h1 className="title">{welcomeText.name}</h1>
                        <p className="tagLine">{welcomeText.tagLine}</p>
                        {isRedirected ? (
                          <p className="description">
                            {welcomeText.redirectDescription}
                          </p>
                        ) : (
                          <p className="description">
                            {welcomeText.description}
                          </p>
                        )}
                        <div className="btn_grp">
                          <CustomButton
                            className="watch_tutorial"
                            title="HOW TO USE"
                            tooltip="HOW TO USE"
                            onClick={() =>
                              handleHowToUseVideo(welcomeText.tutorialLink)
                            }
                          />
                          {welcomeText.startLink ? (
                            <CustomButton
                              className="start_chat"
                              title="START"
                              tooltip="START CHAT"
                              onClick={() =>
                                handleInitialStartAction(welcomeText.startLink)
                              }
                            />
                          ) : (
                            <CustomButton
                              className="choose_classroom"
                              title="CHOOSE CLASSROOM"
                              tooltip="CHOOSE CLASSROOM"
                              onClick={handleShowClassCategory}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {showClassCategory && (
                    <div className="choose_category_wrapper">
                      <div className="title">Choose Classroom</div>
                      <ul>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "question" ? "active" : ""
                            }`}
                            onClick={() => handleAskQuestion()}
                          >
                            <img src={SSBMentorIcon} alt="SSBMentorIcon" />
                            <p>SSB Mentor</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "physical" ? "active" : ""
                            }`}
                            onClick={() => handlePhysicalQuestion()}
                          >
                            <img src={trainer} alt="trainer" />
                            <p>Fitness Trainer</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "medical" ? "active" : ""
                            }`}
                            onClick={() => handleMedicalQuestion()}
                          >
                            <img src={doctor} alt="doctor" />
                            <p>Medical Officer</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que1 ${
                              activeTab === "oir-test" ? "active" : ""
                            }`}
                            onClick={() => handleOirTest()}
                          >
                            <img src={OIR} alt="OIR" />
                            <p>OIR Test</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que2 ${
                              activeTab === "group-discussion" ? "active" : ""
                            }`}
                            onClick={() => handleGroupDiscussion()}
                          >
                            <img
                              src={group_discussion_icon}
                              alt="group_discussion_icon"
                            />
                            <p>Group Discussion</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que3 ${
                              activeTab === "mock-psychology" ? "active" : ""
                            }`}
                            onClick={() => handleMockPsychology()}
                          >
                            <img src={psychology} alt="psychology" />
                            <p>Mock Psychology</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que4 ${
                              activeTab === "mock-PPDT" ? "active" : ""
                            }`}
                            // onClick={() => handleImageGenrate("PPDT")}
                            onClick={() => handleMockPPDT()}
                          >
                            <img src={ppdtIcon} alt="ppdtIcon" />
                            <p>Mock PPDT</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que9 ${
                              activeTab === "ssb-gto" ? "active" : ""
                            }`}
                            onClick={handleGTO}
                          >
                            <img src={Gto} alt="wat1" />
                            <p>GTO</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que10 ${
                              activeTab === "courses" ? "active" : ""
                            }`}
                            onClick={() => handleCourses()}
                          >
                            <img src={courses} alt="wat1" />
                            <p>Courses</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que11 ${
                              activeTab === "courses" ? "active" : ""
                            }`}
                            onClick={() => handleOlqAnalysis()}
                          >
                            <img src={olq} alt="wat1" />
                            <p>OLQ Analysis</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que12"
                            onClick={() => handleMockInterview()}
                          >
                            <img src={interview} alt="wat1" />
                            <p>Mock Interview</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que13 ${
                              activeTab === "current-affair" ? "active" : ""
                            }`}
                            onClick={() => handleCurrentAffair()}
                          >
                            <img src={currentAffair} alt="currentAffair" />
                            <p>Current Affairs</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que14 ${
                              activeTab === "spoken-english" ? "active" : ""
                            }`}
                            onClick={() => handleSpokenEnglish()}
                          >
                            <img src={spokenEnglish} alt="spokenEnglish" />
                            <p>Spoken English</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que15 ${
                              activeTab === "CPSS" ? "active" : ""
                            }`}
                            onClick={() => handleCPSSGuidance()}
                          >
                            <img src={CPSS_logo} alt="CPSS_logo" />
                            <p>CPSS</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que15 ${
                              activeTab === "Lecturette" ? "active" : ""
                            }`}
                            onClick={() => handleLecturetteGuidance()}
                          >
                            <img src={Lecturette} alt="Lecturette" />
                            <p>Lecturette</p>
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* CAPTAIN ALPHA CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startQuestion" && (
                    <div className="answer_box">
                      <div className="profile_img">
                        <img
                          className="captainProfilePic"
                          src={captainImage}
                          alt="captain modal"
                        />
                      </div>{" "}
                      <div>
                        <div className="output_box">
                          <div className="arrowTri"></div>
                          {initialQuestion === "" && (
                            <p>
                              Jai Hind! I am Captain Alpha, your dedicated AI
                              expert for SSB interview preparation, trained by
                              experienced ex-SSB/AFSB/NSB officers. You can rely
                              on me to guide you through your journey. Please
                              choose the role you'd like me to play:
                            </p>
                          )}

                          {initialQuestion === "option-A" ? (
                            <p>
                              Alright, Alpha Warrior. I'm ready. How can I
                              assist you today?
                            </p>
                          ) : initialQuestion === "option-B" ? (
                            <p>
                              Excellent choice, Alpha Warrior! Let's dive into
                              analyzing your personality and Officer-Like
                              Qualities (OLQs). I'll use a mix of psychometric
                              multiple-choice questions and some casual
                              conversation to get a better understanding. Rest
                              assured, everything between us is completely
                              private—only you and I will see this. Shall we
                              start?
                            </p>
                          ) : (
                            initialQuestion === "option-C" && (
                              <>
                                <p>
                                  If you choose Option A, I'll provide answers
                                  to general queries about the SSB interview
                                  process. This is a great option if you're
                                  looking to gather information about the
                                  interview, for example, what is PGT
                                  (Progressive Group Task).
                                </p>
                                <p>
                                  However, if you're seeking personalized
                                  guidance—then Option B would be the better
                                  choice. With Option B, I'll take the time to
                                  understand your situation more closely and
                                  give tailored advice based on your needs.
                                </p>
                                <p>
                                  As your personal mentor, I will thoroughly
                                  analyze every aspect of your personality using
                                  psychometric tests, situational questions, and
                                  casual interactions. I will assess your
                                  physical stamina through activities like
                                  running, exercising, or cycling. Additionally,
                                  I will ask miscellaneous questions to better
                                  understand you as an individual. Based on
                                  this, I will create a personalized roadmap.
                                </p>
                                <p>
                                  Our daily interactions will include psychology
                                  tests, which I will analyze and provide
                                  feedback on to help you improve. We will
                                  conduct mock interviews, followed by detailed
                                  feedback and guidance for improvement. We will
                                  also have unlimited group discussions or
                                  casual conversations to enhance your
                                  communication skills. I'll ensure you're
                                  well-versed in military awareness, current
                                  affairs, and prescribe exercises to boost your
                                  stamina in a short period.
                                </p>
                                <p>
                                  If you have any medical concerns, I will refer
                                  you to retired military doctors for expert
                                  advice. In short, everything required to
                                  prepare you for the SSB interview will be
                                  taken care of by me. You won’t need to go
                                  elsewhere or attend additional classes—I will
                                  be your mentor, guiding you through all
                                  aspects of the SSB interview preparation. So
                                  tell me which option you want to choose.
                                </p>
                              </>
                            )
                          )}
                          <div className="action_btn_grp">
                            {initialQuestion === "" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-A")
                                  }
                                >
                                  (A) Answer my Questions
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-B")
                                  }
                                >
                                  (B) Become my Personal Mentor{" "}
                                  <b>[Recommended]</b>
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-C")
                                  }
                                >
                                  (C) Explain the difference between (A) & (B)
                                  to help you make an informed choice.
                                </button>
                              </>
                            )}
                            {initialQuestion === "option-C" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-A")
                                  }
                                >
                                  (A) Answer my Questions
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuestionInitialAction("option-B")
                                  }
                                >
                                  (B) Become my Personal Mentor{" "}
                                  <b>[Recommended]</b>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* CAPTAIN ALPHA CATEGORISED QUESTIONS ENDS */}

                  {/* CPSS CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startCPSS" &&
                    initialQuestion !== "CPSS-A" &&
                    initialQuestion !== "CPSS-F" && (
                      <div className="answer_box txt_color">
                        <div className="profile_img">
                          <img
                            className="captainProfilePic"
                            src={captainImage}
                            alt="captain modal"
                          />
                        </div>{" "}
                        <div>
                          <div className="output_box">
                            {initialQuestion === "" && (
                              <p>
                                Jai Hind, Alpha Warrior! I am Sanjeev Kumar,
                                your AI CPSS mentor. Please choose an option for
                                today:
                              </p>
                            )}
                            {initialQuestion === "CPSS-B" && (
                              <p>
                                CPSS includes two levels of testing. Please
                                select the one you'd like to practice:
                              </p>
                            )}
                            {initialQuestion === "CPSS-C" && (
                              <>
                                <ul>
                                  <li>
                                    <strong>
                                      Total Tests in Cognitive Ability Test:{" "}
                                    </strong>{" "}
                                    6
                                  </li>
                                  <li>
                                    <strong>
                                      Total Questions in Cognitive Ability Test:{" "}
                                    </strong>{" "}
                                    212 (MCQ Based)
                                  </li>
                                  <li>
                                    <strong>
                                      Total Duration in Cognitive Ability Test:{" "}
                                    </strong>{" "}
                                    2 hours 30 minutes
                                  </li>
                                </ul>
                                <p>Test Breakdown: </p>
                                <ol>
                                  <li>
                                    INSB 1
                                    <ul>
                                      <li>
                                        <b>Questions: </b>15
                                      </li>
                                      <li>
                                        <b>Time: </b>10 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Recognizing scales and
                                        meters (Magnetic Compass, Airspeed
                                        Indicator, Altimeter, Altitude
                                        Indicator, Vertical Speed Indicator,
                                        Turn Coordinator)
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    INSB 2
                                    <ul>
                                      <li>
                                        <b>Questions: </b>60
                                      </li>
                                      <li>
                                        <b>Time: </b>20 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Determining airplane
                                        position from instruments
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Spatial Ability Test
                                    <ul>
                                      <li>
                                        <b>Questions: </b>30
                                      </li>
                                      <li>
                                        <b>Time: </b>15 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Perceiving 2D and 3D
                                        figures in space
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Spatial Visualization Test
                                    <ul>
                                      <li>
                                        <b>Questions: </b>57
                                      </li>
                                      <li>
                                        <b>Time: </b>5 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Perceiving the position of
                                        objects in space
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Numerical Reasoning Test
                                    <ul>
                                      <li>
                                        <b>Questions: </b>20
                                      </li>
                                      <li>
                                        <b>Time: </b>20 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Numerical skills
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Reasoning Test
                                    <ul>
                                      <li>
                                        <b>Questions: </b>30
                                      </li>
                                      <li>
                                        <b>Time: </b>15 mins
                                      </li>
                                      <li>
                                        <b>Focus: </b>Verbal and non-verbal
                                        reasoning
                                      </li>
                                    </ul>
                                  </li>
                                </ol>
                                <p>
                                  As you may have noticed, reading and analyzing
                                  instruments such as the Magnetic Compass,
                                  Airspeed Indicator, Altimeter, Altitude
                                  Indicator, Vertical Speed Indicator, and Turn
                                  Coordinator forms a significant part of the
                                  Cognitive Ability Test. Since learning by
                                  doing is the most effective approach,
                                  practicing instrument reading and analysis is
                                  essentially like flying in a simulation with
                                  all the instrumentation, designed to be as
                                  accurate as in a real flight.
                                </p>
                              </>
                            )}
                            {initialQuestion === "CPSS-D" && (
                              <>
                                <ul>
                                  <li>
                                    <strong>
                                      Total Tests in Psychomotor Test:{" "}
                                    </strong>{" "}
                                    10
                                  </li>
                                  <li>
                                    <strong>Attempts per Test: </strong> 4 (Best
                                    attempt is considered)
                                  </li>
                                  <li>
                                    <strong>
                                      Total Time given for Psychomotor Test:{" "}
                                    </strong>{" "}
                                    1 hour 30 minutes
                                  </li>
                                </ul>
                                <p>
                                  Tests in <b>Psychomotor Test</b>:
                                </p>
                                <div className="psychomotor_test">
                                  <ol>
                                    {psychoMotorTestArr?.map(
                                      (item, itemIndex) => (
                                        <li key={itemIndex}>
                                          <p>
                                            <span>{item?.name}</span>
                                            <span>
                                              <button
                                                onClick={() =>
                                                  window.open(
                                                    item?.testLink,
                                                    "_blank"
                                                  )
                                                }
                                              >
                                                Practice
                                              </button>
                                              <div
                                                className="knowMore_tile"
                                                title="Know More"
                                              >
                                                <MdQuestionMark />
                                              </div>
                                            </span>
                                          </p>
                                        </li>
                                      )
                                    )}
                                  </ol>
                                </div>
                              </>
                            )}
                            {initialQuestion === "CPSS-E" && (
                              <div className="flightSimulation">
                                {!showFlightSimulation ? (
                                  <>
                                    <p>
                                      Before we begin, let’s go over a brief
                                      introduction of all the instruments shown
                                      in CPSS, as they are also widely used in
                                      all aircraft.
                                    </p>
                                    <div className="action_btn_grp">
                                      <button
                                        onClick={() =>
                                          setShowFlightSimulation(true)
                                        }
                                      >
                                        START
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <FlightSimulation
                                    handleNext={handleFlightSimulationNext}
                                    handlePrev={handleFlightSimulationPrev}
                                    currentSlider={
                                      currentFlightSimulationSlider
                                    }
                                  />
                                )}
                              </div>
                            )}

                            <div className="action_btn_grp">
                              {initialQuestion === "" && (
                                <>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-A")
                                    }
                                  >
                                    (A) Have a query related to CPSS
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-B")
                                    }
                                  >
                                    (B) Want to practice CPSS.
                                  </button>
                                </>
                              )}
                              {initialQuestion === "CPSS-B" && (
                                <>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-C")
                                    }
                                  >
                                    (A) Cognitive Ability Test
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-D")
                                    }
                                  >
                                    (B) Psychomotor Test
                                  </button>
                                </>
                              )}
                              {initialQuestion === "CPSS-C" && (
                                <>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-E")
                                    }
                                  >
                                    (A) Flight Simulation
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleCPSSInitialAction("CPSS-F")
                                    }
                                  >
                                    (B) Reasoning Test
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* CPSS CATEGORISED QUESTIONS ENDS */}

                  {/* LECTURETTE CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startLecturette" && (
                    <div className="answer_box txt_color">
                      <div className="profile_img">
                        <img
                          className="captainProfilePic"
                          src={captainImage}
                          alt="captain modal"
                        />
                      </div>{" "}
                      <div>
                        <div className="output_box">
                          <div className="arrowTri"></div>
                          {/* {initialQuestion === "" && ( */}
                          <div className="instructions_list">
                            <h2>Instructions for Lecturette</h2>
                            <ul>
                              <h4>1. Understand the Task:</h4>
                              <li>
                                <p>
                                  Lecturette is a GTO task conducted as part of
                                  the Group Testing Series.
                                </p>{" "}
                              </li>
                              <li>
                                <p>
                                  It is an individual task, where aspirants take
                                  turns as per their chest numbers.
                                </p>
                              </li>
                              <li>
                                <p>The process:</p>
                                <span>
                                  {"a)."} The aspirant with the lowest chest
                                  number is called first and asked to pick a
                                  slip from a box.
                                </span>
                                <span>
                                  {"b)."} Each slip contains 4 topics,
                                  categorized as:
                                  <strong>1. 1 High-Level Topic</strong>
                                  <strong>2. 2 Average-Level Topics</strong>
                                  <strong>3. 1 Below-Average Topic</strong>
                                </span>
                                <span>
                                  {"c)."} After picking the slip, the aspirant
                                  moves a few steps away and is given{" "}
                                  <b>3 minutes to prepare</b>. During this time:
                                  <strong>They choose a topic.</strong>
                                  <strong>
                                    They mentally prepare a blueprint or
                                    structure for their speech.
                                  </strong>
                                </span>
                                <span>
                                  {"d)."} Meanwhile, the GTO interacts casually
                                  with the remaining candidates, asking for
                                  brief introductions, hobbies, or general
                                  information.
                                </span>
                                <span>
                                  {"e)."} When the 3-minute preparation time
                                  ends, the GTO rings a bell, signaling:
                                  <strong>
                                    The current aspirant to begin their
                                    lecturette.
                                  </strong>
                                  <strong>
                                    The next aspirant to pick a slip, move to
                                    the preparation area, and start preparing.
                                  </strong>
                                </span>
                                <span>
                                  {"f)."} After 2 minutes 30 seconds, the GTO
                                  rings the bell again:
                                  <strong>
                                    This indicates the current speaker should
                                    start winding up their lecturette.
                                  </strong>
                                  <strong>
                                    The next aspirant should move closer to be
                                    ready to start.
                                  </strong>
                                </span>
                                <span>
                                  {"g)."} After 30 seconds, the bell rings
                                  again:
                                  <strong>
                                    The current aspirant stops speaking.
                                  </strong>
                                  <strong>
                                    The next aspirant begins their lecturette.
                                  </strong>
                                  <strong>
                                    The third aspirant picks a slip and starts
                                    preparing.
                                  </strong>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <h4>2. Topic Selection:</h4>
                              <li>
                                <p>
                                  Choose a topic you are comfortable with and
                                  can confidently discuss.
                                </p>
                              </li>
                              <li>
                                <p>
                                  Consider your knowledge level and the
                                  relevance of the topic.
                                </p>
                              </li>
                            </ul>
                            <ul>
                              <h4>3. Preparation Time (3 Minutes) :</h4>
                              <li>
                                <p>Use the preparation time wisely to:</p>
                                <span>
                                  Decide your structure:
                                  <strong>
                                    1. Introduction: Brief overview or
                                    definition of the topic.
                                  </strong>
                                  <strong>
                                    2. Main Points: Discuss 2-3 key aspects
                                    logically.
                                  </strong>
                                  <strong>
                                    3. Conclusion: Summarize your points and add
                                    a final thought.
                                  </strong>
                                  <strong>
                                    4. Stay calm and focused, visualizing how
                                    you will deliver the speech.
                                  </strong>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <h4>4. Speaking Time (3 Minutes):</h4>
                              <li>
                                <p>
                                  Deliver your speech confidently and clearly.
                                </p>
                              </li>
                              <li>
                                <p>
                                  Use the bell at 2 minutes 30 seconds as a cue
                                  to start winding up.
                                </p>
                              </li>
                              <li>
                                <p>
                                  End on a strong note within the time limit.
                                </p>
                              </li>
                            </ul>
                            <ul>
                              <h4>General Tips:</h4>
                              <li>
                                <p>
                                  <b>Body Language:</b> Stand straight, maintain
                                  eye contact, and avoid unnecessary gestures.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Voice Modulation:</b> Speak loudly and
                                  clearly, with appropriate tone variations.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Engagement:</b> Make your speech relatable
                                  and interesting to the group.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>Practice:</b> Regular practice with a timer
                                  will help you manage time effectively.
                                </p>
                              </li>
                            </ul>
                          </div>

                          <div className="skip_content">
                            <label htmlFor="lecturetteSkip">
                              <input
                                type="checkbox"
                                name="lecturetteSkip"
                                id="lecturetteSkip"
                              />{" "}
                              Skip &never show me again
                            </label>
                          </div>

                          <div className="action_btn_grp">
                            <button
                              className="strt"
                              onClick={() =>
                                window.open("/lecturette", "_blank")
                              }
                            >
                              Start
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* LECTURETTE CATEGORISED QUESTIONS ENDS */}

                  {/* OLQ ANALYSIS CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startOlqAnalysis" &&
                    initialQuestion === "" && (
                      <div className="answer_box txt_color">
                        <div className="profile_img">
                          <img
                            className="captainProfilePic"
                            src={captainImage}
                            alt="captain modal"
                          />
                        </div>{" "}
                        <div>
                          <div className="output_box">
                            <div className="arrowTri"></div>
                            <div className="instructions_list">
                              <h2>Instructions for OLQ Analysis</h2>
                              <ul>
                                <h4>1. Importance of OLQs:</h4>
                                <li>
                                  <p>
                                    As we know Officer Like Qualities (OLQs) are
                                    crucial for success in the SSB interview so
                                    OLQ Analysis can be very helpful for SSB
                                    Interview Aspirants.
                                  </p>{" "}
                                </li>
                              </ul>
                              <ul>
                                <h4>2. Test Overview:</h4>
                                <li>
                                  <p>
                                    This analysis uses psychometric and
                                    situational tests to evaluate your OLQs.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    The test provides insights into all your
                                    OLQs to support self-assessment.
                                  </p>
                                </li>
                              </ul>
                              <ul>
                                <h4>3. Test Format:</h4>
                                <li>
                                  <p>
                                    The test is entirely multiple-choice (MCQ).
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Questions will appear one at a time. You
                                    must answer the current question to proceed
                                    to the next.
                                  </p>
                                </li>
                              </ul>
                              <ul>
                                <h4>4. Time Limit:</h4>
                                <li>
                                  <p>
                                    There is <b>no time limit</b> for this test.
                                    Take your time to answer thoughtfully.
                                  </p>
                                </li>
                              </ul>
                              <ul>
                                <h4>5. Honesty Matters:</h4>
                                <li>
                                  <p>
                                    Be honest and answer to the best of your
                                    understanding for accurate self-assessment.
                                  </p>
                                </li>
                              </ul>
                              <ul>
                                <h4>6. Purpose:</h4>
                                <li>
                                  <p>
                                    The test is designed to{" "}
                                    <b>assist you in your SSB preparation</b>.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    It does not provide final judgments about
                                    your qualities but serves as a tool for
                                    self-improvement.
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className="skip_content">
                              <label htmlFor="olqStepSkip">
                                <input
                                  type="checkbox"
                                  name="olqStepSkip"
                                  id="olqStepSkip"
                                />{" "}
                                Skip &never show me again
                              </label>
                            </div>
                            <div className="action_btn_grp">
                              <button
                                className="strt"
                                onClick={() =>
                                  handleOLQInitialAction("start-action")
                                }
                              >
                                Start
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* OLQ ANALYSIS CATEGORISED QUESTIONS START */}

                  {/* PPDT CATEGORISED QUESTIONS START */}
                  {initialSearchType === "startPPDT" && (
                    <div className="answer_box txt_color">
                      <div className="profile_img">
                        <img
                          className="captainProfilePic"
                          src={captainImage}
                          alt="captain modal"
                        />
                      </div>{" "}
                      <div>
                        <div className="output_box">
                          <div className="arrowTri"></div>
                          <div className="instructions_list">
                            <h2>Instructions for PPDT Practice</h2>
                            <ul>
                              <h4>1. Understand the Importance:</h4>
                              <li>
                                <p>
                                  PPDT plays a vital role in getting screened in
                                  for the SSB interview. The more you practice,
                                  the better you will improve.
                                </p>{" "}
                              </li>
                            </ul>
                            <ul>
                              <h4>2. Download the Answer Sheet:</h4>
                              <li>
                                <p>
                                  Before you begin, download the PPDT answer
                                  sheet to familiarize yourself with the format.
                                </p>
                                <button onClick={handlePPDTsheetDownload}>
                                  Click Here To Download
                                </button>
                              </li>
                            </ul>
                            <ul>
                              <h4>3. Observe the Image:</h4>
                              <li>
                                <p>
                                  A blurry image will appear on your screen for{" "}
                                  <b>30 seconds</b>. Observe it carefully,
                                  noting key details like characters, actions,
                                  and context.
                                </p>
                              </li>
                            </ul>
                            <ul>
                              <h4>4. Note Down Key Details:</h4>
                              <p>You will get 5 minutes to:</p>
                              <li>
                                <p>Identify the number of characters.</p>
                              </li>
                              <li>
                                <p>Note their age, gender, and mood.</p>
                              </li>
                              <li>
                                <p>
                                  Determine the central action or theme of the
                                  image.
                                </p>
                              </li>
                              <li>
                                <p>
                                  Write a concise story based on the image in
                                  the answer sheet.
                                </p>
                              </li>
                            </ul>
                            <ul>
                              <h4>5. Submit Your Story:</h4>
                              <p>Once completed:</p>
                              <li>
                                <p>
                                  Submit your story along with all the details
                                  you noted in the answer sheet.
                                </p>
                              </li>
                            </ul>
                            <ul>
                              <h4>6. Wait for Feedback:</h4>
                              <li>
                                <p>
                                  Captain Alpha will analyze your submission and
                                  provide constructive feedback to help you
                                  improve.
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="skip_content">
                            <label htmlFor="olqStepSkip">
                              <input
                                type="checkbox"
                                name="olqStepSkip"
                                id="olqStepSkip"
                              />{" "}
                              Skip & never show me again
                            </label>
                          </div>
                          <div className="action_btn_grp">
                            <button
                              className="strt"
                              onClick={() => handlePPDTInitialAction()}
                            >
                              Start
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* PPDT CATEGORISED QUESTIONS START */}

                  {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                  {outputData?.length > 0 &&
                    outputData?.map((ele, idx) => (
                      <>
                        {ele?.question && (
                          <h5 className="gpt_questions" key={idx}>
                            <div className="question_text">
                              {ele?.question} <div className="arrowTri"></div>{" "}
                            </div>
                            <div className="profile_img">
                              <img
                                src={
                                  auth?.user?.gender === "Male"
                                    ? defaultMale
                                    : auth?.user?.gender === "Female"
                                    ? defaultFemale
                                    : defaultUser
                                }
                                alt="ssb gpt user"
                                className="captainProfilePic"
                              />
                            </div>{" "}
                          </h5>
                        )}
                        {ele?.srtList?.length > 0 && (
                          <h5>
                            {ele?.srtList?.map((itm, id) => (
                              <span key={id}>
                                {id + 1}. {itm}
                              </span>
                            ))}
                          </h5>
                        )}
                        {(ele?.answer || ele?.spokenType?.length > 0) && (
                          <div className="answer_box">
                            <div
                              className="profile_img"
                              onClick={handleSpeakFunction}
                            >
                              <img
                                className="captainProfilePic"
                                src={captainImage}
                                alt="captain modal"
                              />
                              <button>
                                {gptSpeaker ? (
                                  <img
                                    src={profile_mic_active}
                                    alt="speaker function"
                                    className="mic_btn active"
                                  />
                                ) : (
                                  <img
                                    src={profile_mic}
                                    alt="speaker function"
                                    className="mic_btn"
                                  />
                                )}
                              </button>
                            </div>{" "}
                            <div>
                              <div className="output_box">
                                <div className="arrowTri"></div>
                                {ele?.spokenType?.length > 0 &&
                                  ele?.spokenType?.map((itm, id) => (
                                    <button
                                      key={id}
                                      onClick={() =>
                                        handleSpokenQuery(itm?.query)
                                      }
                                    >
                                      {itm?.type}
                                    </button>
                                  ))}

                                <div className="video_data">
                                  <div className="video_content" key={idx}>
                                    {ele?.title !== "" && <p>{ele?.title}</p>}
                                    {ele?.url && ele?.url !== null && (
                                      <div className="thumbnail-container">
                                        <iframe
                                          className="thumbnail"
                                          src={ele?.url}
                                          title={ele?.url}
                                          frameBorder="0"
                                          allowFullScreen
                                        />
                                        <div
                                          className="overlay"
                                          onClick={() =>
                                            handleVideoView(ele?.url)
                                          }
                                        >
                                          <BsPlayCircle />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {ele?.answer !== "" ? (
                                  <>
                                    {ele?.answer?.Title && (
                                      <p>
                                        <strong>Title :</strong>
                                        <span>{ele?.answer?.Title}</span>
                                      </p>
                                    )}
                                    {ele?.answer?.Content && (
                                      <p>
                                        <strong>Content :</strong>
                                        <span>{ele?.answer?.Content}</span>
                                      </p>
                                    )}
                                    {ele?.answer?.Summary && (
                                      <p>
                                        <strong>Summary :</strong>
                                        <span>{ele?.answer?.Summary}</span>
                                      </p>
                                    )}
                                    {!ele?.answer?.Title &&
                                      !ele?.answer?.Content &&
                                      !ele?.answer?.Summary && (
                                        <p>
                                          {/* <span
                                            dangerouslySetInnerHTML={{
                                              __html: ele?.answer,
                                            }}
                                          ></span> */}
                                          <ReactMarkdown>
                                            {ele?.answer}
                                          </ReactMarkdown>
                                        </p>
                                      )}
                                  </>
                                ) : (
                                  <div className="video_data">
                                    <div className="video_content" key={idx}>
                                      {ele?.title !== "" && <p>{ele?.title}</p>}
                                      {ele?.url === "Loading..." ? (
                                        <p>{ele?.url}</p>
                                      ) : (
                                        <div
                                          className="thumbnail"
                                          onClick={() =>
                                            handleVideoView(ele?.url)
                                          }
                                        >
                                          <img
                                            src={logo_mobile}
                                            alt="thumbnail"
                                          />
                                          <span>
                                            <BsPlayCircle />
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="action_btn">
                                {/* <button
                                  className="stop_video"
                                  title="Disable Video suggestion"
                                  onClick={() => handleDisableVideo()}
                                >
                                  {disableVideo ? (
                                    <CiVideoOff />
                                  ) : (
                                    <CiVideoOn />
                                  )}
                                </button> */}
                                <span onClick={() => handleSave(idx)}>
                                  <LuBookmark />
                                  Save
                                </span>
                                <span>
                                  <AiFillDislike />
                                </span>
                                <span>
                                  <AiFillLike />
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                  {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                  <div className="tour_msg"></div>
                  {/* HANLDE TAT LOGICS STARTS  */}

                  {activeTab === "TAT" && (
                    <div className="tat_test_msg">
                      <p>
                        Your test is preparing. It will take upto 5-6 min.{" "}
                        <br />
                        Do not refresh the page in case you may loss data
                      </p>
                    </div>
                  )}

                  {activeTab === "PPDT" && (
                    <div className="tat_test_msg">
                      <p>
                        Your test is preparing. It will take upto 40 - 50 sec.{" "}
                        <br />
                        Do not refresh the page in case you may loss data
                      </p>
                    </div>
                  )}

                  <div className="mock_text_wrapper">
                    {imageQuery === "PPDT" && (
                      <div className="test_start">
                        <div className="test_start">
                          {ppdtTimer > 0 ? (
                            <div className="question_list">
                              <img src={ppdtImage} alt={ppdtImage} />
                            </div>
                          ) : (
                            <textarea rows="5" placeholder="Start writing..." />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {isLoading && activeTab === "video" && (
                    <div className="search_loader">
                      <img src={loaderGif} alt="loader" />
                    </div>
                  )}

                  {/* {activity === "pushUps" && (
                  <PushupTracker
                    setPushupCount={setPushupCount}
                    setActivity={setActivity}
                    setActivityStatus={setActivityStatus}
                  />
                )} */}
                </div>

                {showSearchInput && (
                  <div className="srch_input">
                    {/* {message !== "" && (
                      <div className="suggestion_box">
                        <p>{message}</p>
                        <div className="box">
                          <button onClick={() => setMessage("")}>No</button>
                          <button onClick={handleSearchPop}>Yes</button>
                        </div>
                      </div>
                    )} */}

                    {/* SEARCH INPUT AND CATEGORIES START */}
                    <div>
                      <button
                        className="search"
                        onClick={(e) => handleMediaOpen(e)}
                      >
                        {mediaOpen ? (
                          <img src={choiceOrange} alt="choiceOrange" />
                        ) : (
                          <img src={choiceBlue} alt="choiceOrange" />
                        )}
                      </button>
                      {activeTab === "pdf" && (
                        <div
                          className="upload_box"
                          style={{
                            border: activeinput ? "1px solid #fff" : "",
                          }}
                        >
                          <input
                            type="file"
                            accept="*/.pdf"
                            onFocus={() => {
                              setMediaOpen(false);
                            }}
                            onChange={(e) => handleMediaChange(e)}
                            placeholder={placeHolderInput}
                          />
                          <button
                            className="media_btn"
                            onClick={() => handleSearch()}
                          >
                            {isLoading ? <LuLoader /> : <TiArrowUp />}
                          </button>
                        </div>
                      )}

                      {activeTab !== "pdf" &&
                        initialQuestion !== "CPSS-F" &&
                        activeTab !== "olq-analysis" && (
                          <input
                            type="text"
                            value={searchValue}
                            style={{
                              border: activeinput ? "1px solid #fff" : "",
                            }}
                            onChange={(e) => handleInputChange(e)}
                            onFocus={() => {
                              setMediaOpen(false);
                            }}
                            onKeyDown={handleKeyDown}
                            placeholder={placeHolderInput}
                          />
                        )}

                      {/* OPTION INPUT BOX TYPE */}
                      {(initialQuestion === "CPSS-F" ||
                        activeTab === "olq-analysis") && (
                        <div className="option_bordered_sec">
                          <div className="optionSec">
                            <button
                              title="A"
                              onClick={() => handleSearchResult("", "A")}
                            >
                              A
                            </button>
                            <button
                              title="B"
                              onClick={() => handleSearchResult("", "B")}
                            >
                              B
                            </button>
                            <button
                              title="C"
                              onClick={() => handleSearchResult("", "C")}
                            >
                              C
                            </button>
                            <button
                              title="B"
                              onClick={() => handleSearchResult("", "D")}
                            >
                              D
                            </button>
                          </div>
                          <div className="nextSec">
                            <button
                              title="Next/Skip"
                              onClick={() => handleSearchResult("", "Skip")}
                            >
                              Skip
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="media_wrapper">
                        <div
                          className={`media_drop_up ${
                            mediaOpen ? "media_drop_up_on" : ""
                          }`}
                        >
                          <ul>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "question" ? "active" : ""
                                }`}
                                onClick={() => handleAskQuestion()}
                              >
                                <img src={SSBMentorIcon} alt="SSBMentorIcon" />
                                <p>SSB Mentor</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "physical" ? "active" : ""
                                }`}
                                onClick={() => handlePhysicalQuestion()}
                              >
                                <img src={trainer} alt="trainer" />
                                <p>Fitness Trainer</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que ${
                                  activeTab === "medical" ? "active" : ""
                                }`}
                                onClick={() => handleMedicalQuestion()}
                              >
                                <img src={doctor} alt="doctor" />
                                <p>Medical Officer</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que1 ${
                                  activeTab === "oir-test" ? "active" : ""
                                }`}
                                onClick={() => handleOirTest()}
                              >
                                <img src={OIR} alt="OIR" />
                                <p>OIR Test</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que2 ${
                                  activeTab === "group-discussion"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleGroupDiscussion()}
                              >
                                <img
                                  src={group_discussion_icon}
                                  alt="group_discussion_icon"
                                />
                                <p>Group Discussion</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que3 ${
                                  activeTab === "mock-psychology"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleMockPsychology()}
                              >
                                <img src={psychology} alt="psychology" />
                                <p>Mock Psychology</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que4 ${
                                  activeTab === "mock-PPDT" ? "active" : ""
                                }`}
                                // onClick={() => handleImageGenrate("PPDT")}
                                onClick={() => handleMockPPDT()}
                              >
                                <img src={ppdtIcon} alt="ppdtIcon" />
                                <p>Mock PPDT</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que9 ${
                                  activeTab === "ssb-gto" ? "active" : ""
                                }`}
                                onClick={handleGTO}
                              >
                                <img src={Gto} alt="wat1" />
                                <p>GTO</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que10 ${
                                  activeTab === "courses" ? "active" : ""
                                }`}
                                onClick={() => handleCourses()}
                              >
                                <img src={courses} alt="wat1" />
                                <p>Courses</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que11 ${
                                  activeTab === "courses" ? "active" : ""
                                }`}
                                onClick={() => handleOlqAnalysis()}
                              >
                                <img src={olq} alt="wat1" />
                                <p>OLQ Analysis</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className="media_select ask_que12"
                                onClick={() => handleMockInterview()}
                              >
                                <img src={interview} alt="wat1" />
                                <p>Mock Interview</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que13 ${
                                  activeTab === "current-affair" ? "active" : ""
                                }`}
                                onClick={() => handleCurrentAffair()}
                              >
                                <img src={currentAffair} alt="currentAffair" />
                                <p>Current Affairs</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que14 ${
                                  activeTab === "spoken-english" ? "active" : ""
                                }`}
                                onClick={() => handleSpokenEnglish()}
                              >
                                <img src={spokenEnglish} alt="spokenEnglish" />
                                <p>Spoken English</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que15 ${
                                  activeTab === "CPSS" ? "active" : ""
                                }`}
                                onClick={() => handleCPSSGuidance()}
                              >
                                <img src={CPSS_logo} alt="CPSS_logo" />
                                <p>CPSS</p>
                              </button>
                            </li>
                            <li>
                              <button
                                className={`media_select ask_que15 ${
                                  activeTab === "Lecturette" ? "active" : ""
                                }`}
                                onClick={() => handleLecturetteGuidance()}
                              >
                                <img src={Lecturette} alt="Lecturette" />
                                <p>Lecturette</p>
                              </button>
                            </li>
                          </ul>
                        </div>

                        {searchValue === "" ? (
                          <button
                            className="media_btn"
                            onClick={() => handleVoice()}
                          >
                            {isListening ? (
                              <RiVoiceprintFill />
                            ) : (
                              <AiFillAudio />
                            )}
                          </button>
                        ) : (
                          <button
                            className="media_btn"
                            onClick={() => handleSearch()}
                          >
                            {isLoading ? (
                              <LuLoader />
                            ) : (
                              <HiOutlinePaperAirplane />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                    {/* SEARCH INPUT AND CATEGORIES ENDS */}

                    {/* <p className="advice_txt">
                    Read our <Link to="/term-of-use">Terms</Link> and{" "}
                    <Link to="/privacy-policies">Privacy Policy</Link>.
                  </p> */}
                  </div>
                )}
              </div>
            </section>

            {/* VIDEO POPUP START */}
            <VideoPopup
              videoLink={videoLink}
              showDetailVideo={showDetailVideo}
              setshowDetailVideo={setshowDetailVideo}
            />
            {/* VIDEO POPUP ENDS */}

            <GptBooks
              bookList={bookList}
              fetchBookList={fetchBookList}
              handleSave={saveValue}
              outputData={outputData}
              // activeTab={activeTab}
              questionList={questionList}
              handleShowHistory={handleShowHistory}
              activeCaptain={activeTab}
              handleMicFunction={handleMicFunction}
              handleSpeakFunction={handleSpeakFunction}
              gptMic={gptMic}
              gptSpeaker={gptSpeaker}
              loadingNotes={loadingNotes}
              handleShowClassroom={handleShowClassroom}
              handleShowExercise={handleShowExercise}
              showBook={showBook}
              setShowBook={setShowBook}
              handleShowNotes={handleShowNotes}
              showExerciseBar={showExerciseBar}
              showChatBox={showChatBox}
            />
          </section>
          <WhatCanDo />
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default GptSearch;
