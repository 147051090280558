import React, { useState, useEffect } from "react";
import TestLayout from "./TestLayout";
import { goodPerformance, needImprovement } from "../../../assets/images";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const EmojiTest = () => {
  const allEmojis = [
    "😀",
    "😂",
    "😍",
    "🥰",
    "😎",
    "🤔",
    "😢",
    "😡",
    "😱",
    "🤗",
    "🙄",
    "🥵",
    "🥶",
    "😴",
    "😇",
    "🤩",
    "😭",
    "🤪",
    "😷",
    "😜",
    "😋",
    "🤑",
    "🤯",
    "🤓",
    "😏",
    "🙃",
    "😬",
    "😌",
    "🤤",
    "😵",
    "😃",
    "😆",
    "😁",
    "😅",
    "🤣",
    "😇",
    "😜",
    "🤠",
    "😎",
    "🥳",
    "😴",
    "😓",
    "😱",
    "😤",
    "🤕",
    "😖",
    "🤬",
    "😈",
    "🧐",
    "🤔",
    "🤥",
    "🤫",
    "🤭",
    "😵",
    "🤯",
    "🥶",
    "🥵",
    "😬",
    "😳",
  ];
  const [currentScreen, setCurrentScreen] = useState("single");
  const [currentEmoji, setCurrentEmoji] = useState(null);
  const [emojiGrid, setEmojiGrid] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!timer) {
      const interval = setInterval(() => {
        setElapsedTime((time) => time + 1);
      }, 1000);
      setTimer(interval);
    }
    if (elapsedTime >= 120) {
      clearInterval(timer);
      setCurrentScreen("result");
    }
    return () => clearInterval(timer);
  }, [elapsedTime, timer]);

  useEffect(() => {
    startRound();
  }, []);

  const startRound = () => {
    const selectedEmoji =
      allEmojis[Math.floor(Math.random() * allEmojis.length)];
    setCurrentEmoji(selectedEmoji);

    const unusedEmojis = allEmojis.filter((emoji) => emoji !== selectedEmoji);
    const gridSize = 32;
    const randomEmojis = Array.from(
      { length: gridSize - 1 },
      () => unusedEmojis[Math.floor(Math.random() * unusedEmojis.length)]
    );

    const grid = [...randomEmojis, selectedEmoji].sort(
      () => Math.random() - 0.1
    );
    setEmojiGrid(grid);
    setActiveIndex(0);
    setCurrentScreen("single");
    setTimeout(() => setCurrentScreen("grid"), 2000);
  };

  const handleMovement = (direction) => {
    const gridSize = emojiGrid?.length;
    const col = activeIndex % gridSize;
    let newIndex = activeIndex;

    switch (direction) {
      case "left":
        newIndex = col > 0 ? activeIndex - 1 : activeIndex;
        break;
      case "right":
        newIndex = col < gridSize - 1 ? activeIndex + 1 : activeIndex;
        break;
      default:
        break;
    }

    // console.log(newIndex);

    setActiveIndex(newIndex);
    setTimeout(() => {
      if (emojiGrid[newIndex] === currentEmoji) {
        setCorrectCount((prev) => prev + 1);
        startRound();
      }
    }, 2000);
  };
  

  return (
    <TestLayout>
      <section className="emoji_test">
        <div className="test_box">
          <h2>Vigilance Test</h2>
          {currentScreen === "single" && (
            <div style={{ fontSize: "50px", margin: "50px 0" }}>
              {currentEmoji}
            </div>
          )}

          {currentScreen === "grid" && (
            <div className="emoji_table" tabIndex="0">
              {emojiGrid.map((emoji, index) => (
                <div
                  key={index}
                  style={{
                    border:
                      activeIndex === index
                        ? "2px solid red"
                        : "1px solid #ccc",
                  }}
                >
                  {emoji}
                </div>
              ))}
            </div>
          )}

          {currentScreen === "grid" && (
            <div className="emoji_btn_grp" style={{ marginTop: "20px" }}>
              <button className="btn" onClick={() => handleMovement("left")}>
                <FaArrowLeft />
              </button>
              <button className="btn" onClick={() => handleMovement("right")}>
                <FaArrowRight />
              </button>
            </div>
          )}

          {currentScreen === "result" && (
            <div className="feedback_box">
              {correctCount > 6 ? (
                <>
                  <img src={goodPerformance} alt="good" />
                  <p>
                    You have performed well in the test, demonstrating great
                    understanding and preparation. Keep up the good work and
                    continue striving for excellence!🎉
                  </p>
                </>
              ) : (
                <>
                  <img src={needImprovement} alt="needImprovement" />
                  <p>
                    Your performance shows potential, but there is room for
                    improvement
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </section>
    </TestLayout>
  );
};

export default EmojiTest;
