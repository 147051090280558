import React, { useEffect, useState, useRef } from 'react';
import '../../../styles/velocity.scss';

const generateRandomPath = (boxWidth, boxHeight, points = 10) => {
  const path = [];
  let x = Math.random() * boxWidth; // Random starting X position
  let y = 0; // Start from the top
  for (let i = 0; i < points; i++) {
    x = Math.random() * boxWidth; // Randomize X for each point
    y += boxHeight / points; // Increment Y for the path
    path.push({ x, y });
  }
  return path;
};

const VelocityTest = () => {
  const boxWidth = 400; // Game box dimensions
  const boxHeight = 400;
  const pathPoints = useRef(generateRandomPath(boxWidth, boxHeight)); // Random path
  const [fallingBalls, setFallingBalls] = useState([]);
  const [userBall, setUserBall] = useState({ x: 200, y: 380 }); // Initial user-controlled ball position
  const [accuracy, setAccuracy] = useState(0);
  const [result, setResult] = useState('');
  const startTime = useRef(null);

  // Ball Drop Logic
  useEffect(() => {
    startTime.current = Date.now();
    const dropInterval = setInterval(() => {
      if ((Date.now() - startTime.current) / 1000 > 120) {
        clearInterval(dropInterval);
        calculateAccuracy(); // End the test after 2 minutes
      } else {
        setFallingBalls((prev) => [
          ...prev,
          { id: Date.now(), positionIndex: 0, progress: 0 },
        ]);
      }
    }, 1000); // New ball every second

    return () => clearInterval(dropInterval);
  }, []);

  // Move falling balls smoothly
  useEffect(() => {
    const moveInterval = setInterval(() => {
      setFallingBalls((prev) =>
        prev
          .map((ball) => {
            const { positionIndex, progress } = ball;

            // If the ball has reached the end of the path
            if (positionIndex >= pathPoints.current.length - 1) return null;

            const currentPoint = pathPoints.current[positionIndex];
            const nextPoint = pathPoints.current[positionIndex + 1];

            // Interpolate between the current and next point
            const dx = nextPoint.x - currentPoint.x;
            const dy = nextPoint.y - currentPoint.y;

            const newProgress = progress + 0.02; // Adjust speed here for smoothness
            if (newProgress >= 1) {
              // Move to the next segment of the path
              return {
                ...ball,
                positionIndex: positionIndex + 1,
                progress: 0,
              };
            }

            // Calculate new position along the line
            const newX = currentPoint.x + dx * newProgress;
            const newY = currentPoint.y + dy * newProgress;

            return { ...ball, x: newX, y: newY, progress: newProgress };
          })
          .filter(Boolean) // Remove null (balls that reached the end)
      );
    }, 20); // Update every 20ms for smooth movement

    return () => clearInterval(moveInterval);
  }, []);

  // Handle user ball movement
  const handleKeyDown = (e) => {
    const step = 10;
    const boxLimit = (val, max) => Math.max(0, Math.min(max, val));

    setUserBall((prev) => {
      let newX = prev.x;
      let newY = prev.y;

      if (e.key === 'ArrowLeft') newX -= step;
      if (e.key === 'ArrowRight') newX += step;
      if (e.key === 'ArrowUp') newY -= step;
      if (e.key === 'ArrowDown') newY += step;

      return { x: boxLimit(newX, boxWidth - 20), y: boxLimit(newY, boxHeight - 20) };
    });
  };

  // Calculate accuracy
  const calculateAccuracy = () => {
    let totalChecks = 0;
    let correctMatches = 0;

    fallingBalls.forEach((ball) => {
      const { x: bx, y: by } = ball;
      const { x: ux, y: uy } = userBall;

      const distance = Math.sqrt((bx - ux) ** 2 + (by - uy) ** 2);
      if (distance < 30) correctMatches += 1; // Count as match if within a 30px radius

      totalChecks += 1;
    });

    const acc = totalChecks ? (correctMatches / totalChecks) * 100 : 0;
    setAccuracy(acc);
    setResult(acc > 70 ? 'Good' : 'Try Again');
  };

  return (
    <div
      className="game-box"
      style={{ width: boxWidth, height: boxHeight }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {/* Render falling balls */}
      {fallingBalls.map((ball) => (
        <div
          key={ball.id}
          className="ball"
          style={{ left: ball.x || pathPoints.current[0].x, top: ball.y || pathPoints.current[0].y }}
        ></div>
      ))}

      {/* Render user-controlled ball */}
      <div
        className="user-ball"
        style={{ left: userBall.x, top: userBall.y }}
      ></div>

      {/* Display result */}
      {result && (
        <div className="result">
          {result} - Accuracy: {accuracy.toFixed(2)}%
        </div>
      )}
    </div>
  );
};

export default VelocityTest;
