import React, { Fragment } from "react";
import "../../styles/captainAlphaProfile.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "../ssbGpt/gptLayout";
import { FiMessageCircle } from "react-icons/fi";
import { captainAlphaModal, home2 } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import meeting_icon from "../../images/icons/meeting_icon.png";
import interview_icon from "../../images/icons/interview_icon.png";
import international_relations from "../../images/icons/international_relations.png";
import groupTaskStrategy from "../../images/groupTaskStrategy.png";
import cdsGPT from "../../images/cdsGPT.png";
import interviewSkills_img from "../../images/interviewSkills_img.png";
import interviewSkillsPersonality from "../../images/interviewSkillsPersonality.png";
import military_truck from "../../images/military-truck.png";
import military_awareness_img from "../../images/military_awareness_img.png";
import { GoPlus } from "react-icons/go";
import { useAuth } from "../../context/userContext";

const CaptainAlphaProfile = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  console.log("auth", auth)

  const handleRedirectToChat = () => {
    if (auth?.user?._id) {
      navigate(`/chat?type=question&isRedirected=true`);
    } else {
      navigate("/login", { state: `/chat?type=question&isRedirected=true` });
    }
  };

  return (
    <Fragment>
      <CustomHelmet
        title="Meet Captain Alpha - SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <div className="profile_page_main txt_color">
            <div className="hero_section">
              <div className="left_sec">
                <div className="heading_top">
                  Looking for your own personal SSB Mentor?
                </div>
                <h1 className="main_heading">
                  Meet Capt. Alpha: Your AI <span>SSB Mentor for Success</span>
                </h1>
                <p className="description">
                  Capt. Alpha is an AI-driven mentor modeled after the best
                  practices and insights gathered from SSB assessors like Group
                  Testing Officers (GTOs), Interviewing Officers (IOs), and
                  Psychologists. Drawing upon years of experience in SSB
                  evaluations and rigorous training, Capt. Alpha is equipped to
                  guide aspiring candidates in all aspects of SSB preparation.
                </p>
                <p className="description">
                  With deep knowledge in leadership, team dynamics, OLQs, and
                  personality assessment, Capt. Alpha provides a comprehensive
                  mentoring experience for individuals seeking success at the
                  Services Selection Board (SSB).
                </p>
                <button className="talk_captain" onClick={handleRedirectToChat}>
                  <FiMessageCircle /> Talk to Capt. Alpha
                </button>
              </div>
              <div className="right_sec">
                <div className="intro_modal_sec">
                  <div className="profile_pic">
                    <img src={captainAlphaModal} alt="captain Alpha" />
                  </div>
                  <div className="name_sec">
                    <div className="name">Capt. Alpha</div>
                    <div className="desc">AI SSB Mentor</div>
                  </div>
                </div>
                <div className="social_links">
                  <Link to="https://www.facebook.com/profile.php?id=61568560284895" title="Facebook" target="_blank">
                    <FaFacebookF />
                  </Link>
                  <Link to="https://x.com/AICaptAlpha" title="Twitter" target="_blank">
                    <FaXTwitter />
                  </Link>
                  <Link to="https://www.youtube.com/@AICaptAlpha" title="Youtube" target="_blank">
                    <FaYoutube />
                  </Link>
                  <Link to="https://www.instagram.com/aicaptalpha/" title="Instagram" target="_blank">
                    <FaInstagram />
                  </Link>
                </div>
              </div>
            </div>

            <div className="effective_member">
              <h2 className="heading">
                Capt. Alpha : YOUR AI SSB MENTOR,  
                <span>AS EFFECTIVE AS A REAL EX-SSB MEMBER!</span>
              </h2>
              <p className="desc">
                Using advanced AI algorithms, Capt. Alpha simulates real-time
                SSB conditions, helping candidates adjust their strategies
                dynamically.
              </p>
              <div className="ai_img">
                <img src={home2} alt="home2" />
              </div>
            </div>

            <div className="next_level_prep">
              <h2 className="heading">
                EXPERIENCE NEXT-LEVEL{" "}
                <span>SSB PREPARATION AND MENTORSHIP TODAY.</span>
              </h2>
              <div className="group_task_strategy">
                <div className="left_sec">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Group <img src={meeting_icon} alt="meeting_icon" /> Task
                    Strategy
                  </h2>
                  <p className="desc">
                    Capt. Alpha specializes in group dynamics and tasks. From
                    planning execution strategies to enhancing teamwork and
                    leadership, Capt. Alpha helps candidates understand the
                    GTO’s perspective, offering tips to navigate progressive and
                    half-group tasks with confidence.
                  </p>
                  <button className="talk_btn" onClick={handleRedirectToChat}>
                    <GoPlus />
                    Talk you to Capt. Alpha
                  </button>
                </div>
                <div className="right_sec">
                  <div className="img_sec">
                    <img src={groupTaskStrategy} alt="groupTaskStrategy" />
                  </div>
                </div>
              </div>

              <div className="core_expretise_sec">
                <div className="psychTestPrep">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Psychological Test <img src={cdsGPT} alt="cdsGPT" />{" "}
                    Preparation
                  </h2>
                  <p className="desc">
                    In-depth practice for TAT, WAT, and SRT, focusing on
                    officer-like qualities such as problem-solving and
                    adaptability.
                  </p>
                  <img src={interviewSkills_img} alt="interviewSkills" />
                </div>
                <div className="interviewSkillsPersonality">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Interview <img src={interview_icon} alt="interview_icon" />{" "}
                    Skills & Personality Insights.
                  </h2>
                  <p className="desc">
                    Tailored guidance on communication, body language, and
                    personality presentation for SSB interviews.
                  </p>
                  <img
                    src={interviewSkillsPersonality}
                    alt="interviewSkillsPersonality"
                  />
                </div>
              </div>

              <div className="militaryAwareness">
                <div className="right_sec">
                  <div className="img_sec">
                    <div className="circle_sec">
                      <div className="red"></div>
                      <div className="blue"></div>
                      <div className="yellow"></div>
                    </div>
                  </div>
                  <img src={military_awareness_img} alt="military_awareness" />
                </div>
                <div className="left_sec">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Military Awareness
                    <img src={military_truck} alt="military_truck" />
                  </h2>
                  <h2 className="sec_heading">
                    & International Relations
                    <img
                      src={international_relations}
                      alt="international_relations"
                    />
                    .
                  </h2>
                  <p className="desc">
                    Capt. Alpha is an expert in teaching military awareness and
                    understanding global geopolitical scenarios. By staying
                    updated on military strategies, defense policies, and
                    international relations, Capt. Alpha ensures that candidates
                    are well-prepared for questions related to current military
                    affairs and global dynamics during their interviews and
                    discussions.
                  </p>
                  <button className="talk_btn" onClick={handleRedirectToChat}>
                    <GoPlus />
                    Talk you to Capt. Alpha
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default CaptainAlphaProfile;
