import React, { Fragment } from "react";
import "../../styles/howToUseClassroom.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import CustomHelmet from "../../component/reusable/Helmet";
import spoken_en_1 from "../../images/howuse/spoken_en_1.png";
import spoken_en_2 from "../../images/howuse/spoken_en_2.png";
import spoken_en_3 from "../../images/howuse/spoken_en_3.png";
import spoken_en_4 from "../../images/howuse/spoken_en_4.png";
import spoken_en_5 from "../../images/howuse/spoken_en_5.png";
import spoken_en_6 from "../../images/howuse/spoken_en_6.png";

const SpokenEnglishHowUse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="how_UseClassRoom_main">
        <div className="container">
          <div className="bacbtnWthHeading">
            <button
              title="back"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeftLong />
            </button>
            <h1 className="main_heading">
              How to join Spoken English Classroom
            </h1>
          </div>
        </div>
        <div className="howUse_content container">
          <p>
            Welcome, Alpha Warriors! <br />
            Fluency in English is essential to excel in your SSB interview, as
            the entire process is conducted in English. To help you enhance your
            communication skills, we present <b>AI English Trainer Mark</b>,
            your dedicated guide on the journey to mastering spoken English.{" "}
            <br />
            Let’s get started and take your language skills to the next level!
            🚀
            <br />
            Here’s how you can join and get started:
          </p>
          <h2 className="subHeading">
            Step-by-Step Guide to Practice the Spoken English
          </h2>
          <h3 className="tab_heading">
            <b>Step 1.</b> Navigate to the Home Page {`>>`}{" "}
            <Link to="/chat">Ask Your SSB Mentor Now</Link>
          </h3>
          <img
            src={spoken_en_1}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 2.</b> Click <b>“Choose Classroom”</b>
          </h3>
          <img
            src={spoken_en_2}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 3.</b> Choose <b>“Spoken English”</b>
          </h3>
          <img
            src={spoken_en_3}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 4.</b> Click the <b>“Start”</b> button
          </h3>
          <img
            src={spoken_en_4}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step-5.</b> Click the <b>“Start”</b> button
          </h3>
          <p>
            Read the instructions carefully so that your spoken English class
            can run smoothly without any interruption. And click on the{" "}
            <b>“Start”</b> button.
          </p>
          <img
            src={spoken_en_5}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step- 6. Familiarize yourself with your classroom interface.</b>
          </h3>
          <img
            src={spoken_en_6}
            alt="ssb gpt cpss instructions"
            className="direct_banner"
          />
          <ul style={{ listStyle: "desimal" }}>
            <li>
              Whatever you speak will be automatically transcribed here. If you
              notice any errors due to background noise, misinterpretation, or
              other reasons, you can correct them before submitting.
            </li>
            <li>
              If the automatic transcription stops for any reason, click the{" "}
              <b>mic icon</b> to refresh it.
            </li>
            <li>
              Once you’ve ensured your answer is correctly typed, click{" "}
              <b>Submit</b>.
            </li>
          </ul>
          <br />
          <p>
            This way, your communication can continue smoothly for as long as
            you wish.
          </p>
          <br />
          <p>
            By following these steps, you can ensure a seamless and productive
            Spoken English learning experience. For further assistance, feel
            free to reach out to support!
          </p>
          <br />
          <p>Jai Hind!!</p>
          <p>Over and Out.</p>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default SpokenEnglishHowUse;
