class SpeechSynthesizer {
  constructor() {
    if (!window.speechSynthesis) {
      throw new Error("Speech Synthesis is not supported in this browser.");
    }
    this.synth = window.speechSynthesis;
    this.utterance = null;
    this.voice = null;

    // Try loading voices immediately
    this.voices = this.synth.getVoices();

    // If voices are not loaded, add a fallback for iOS
    if (this.voices.length === 0 && this.synth.onvoiceschanged !== undefined) {
      this.synth.onvoiceschanged = () => {
        this.voices = this.synth.getVoices();
        this.selectIndianVoice();
      };
    } else {
      this.selectIndianVoice();
    }
  }

  /**
   * Select an Indian English voice if available.
   */
  selectIndianVoice() {
    this.voice = this.voices.find(
      (voice) => voice.lang === "en-IN" || voice.name.includes("India")
    );

    if (!this.voice) {
      console.warn(
        "Indian English voice not found. Falling back to default voice."
      );
    }
  }

  /**
   * Set the voice based on gender preference.
   * @param {"male"|"female"} gender - Preferred gender for the voice.
   */
  setVoiceByGender(gender = "female") {
    if (!this.voices || this.voices.length === 0) {
      console.warn("No voices available to select from.");
      return;
    }

    // Filter voices based on gender-specific keywords
    const genderVoices = this.voices.filter((voice) =>
      gender === "male"
        ? voice.name.toLowerCase().includes("male") ||
          voice.name.toLowerCase().includes("man")
        : voice.name.toLowerCase().includes("female") ||
          voice.name.toLowerCase().includes("woman")
    );

    this.voice = genderVoices.length > 0 ? genderVoices[0] : this.voice;

    if (!this.voice) {
      console.warn(
        `No ${gender} voices found. Falling back to previously selected or default voice.`
      );
    }
  }

  /**
   * Set the text to be spoken.
   * @param {string} text - The text to be spoken.
   */
  setText(text) {
    if (!text || typeof text !== "string") {
      throw new Error("Invalid text. Please provide a non-empty string.");
    }
    this.utterance = new SpeechSynthesisUtterance(text);

    // Apply the selected Indian English voice
    if (this.voice) {
      this.utterance.voice = this.voice;
    }

    // Set language explicitly to Indian English
    this.utterance.lang = "en-IN";
  }

  /**
   * Set speech parameters like rate, pitch, and volume.
   * @param {object} options - Options for speech synthesis.
   * @param {number} options.rate - Speed of the speech (default: 1).
   * @param {number} options.pitch - Pitch of the speech (default: 1).
   * @param {number} options.volume - Volume of the speech (default: 1).
   */
  setOptions({ rate = 1, pitch = 1, volume = 1 } = {}) {
    if (this.utterance) {
      this.utterance.rate = rate;
      this.utterance.pitch = pitch;
      this.utterance.volume = volume;
    } else {
      console.warn("No utterance found. Set text before configuring options.");
    }
  }

  /**
   * Add an event listener for the `onend` event.
   * @param {function} callback - Function to call when the speech ends.
   */
  onEnd(callback) {
    if (this.utterance) {
      this.utterance.onend = callback;
    } else {
      console.warn(
        "No utterance found. Set text before adding event listeners."
      );
    }
  }

  /**
   * Play the current text.
   */
  play() {
    if (!this.utterance) {
      throw new Error("No text set. Use setText() before playing.");
    }

    // Ensure this is triggered by user interaction
    this.synth.cancel(); // Stop ongoing speech
    this.synth.speak(this.utterance);
  }

  /**
   * Stop any ongoing speech.
   */
  stop() {
    this.synth.cancel();
  }
}

export default SpeechSynthesizer;
