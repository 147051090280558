import React from "react";
import "../styles/howToUse.scss";
import GptLayout from "../component/ssbGpt/gptLayout";
import ProfileLayout from "../component/reusable/ProfileLayout";
import { studentSidebarLinks } from "../profileSidebarLinks";
import { useNavigate } from "react-router-dom";

const HowToUse = () => {
  const navigate = useNavigate()

  const redirectOnPage = (link) => {
    if(link){
      navigate(link)
    }
  }

  return (
    <GptLayout>
      <ProfileLayout sidebarLinks={studentSidebarLinks}>
        <section className="howTOUse_main txt_color">
          <h1 className="page_heading">How to Use SSB-GPT?</h1>
          <div className="subHeading">Welcome to SSB-GPT</div>
          <div className="content">
            <p>
              Welcome to <b>SSB-GPT</b>, your trusted platform for guidance on
              Service Selection Board (SSB) assessments. <b>SSB-GPT</b> is
              designed to provide insightful, tailored support for aspirants
              preparing for the SSB interview process, leveraging advanced AI
              models validated by a team of experienced officers and
              specialists. The platform is shaped by input from experts with
              firsthand experience as
              <b>
                Interviewing Officers (IO), Group Testing Officers (GTO),
                psychologists, military doctors, English trainers, and fitness
                experts
              </b>
              , ensuring that each tool and resource reflects the standards and
              demands of the SSB selection process. With this unique foundation,
              SSB-GPT offers accurate and relevant information to help
              candidates understand the nuances of each stage and perform at
              their best.
            </p>
            <p>
              In building SSB-GPT, our team sought to make preparation
              accessible, interactive, and practical, enabling users to practice
              various tests and receive feedback based on real SSB guidelines.
              Guided by the expertise of officers and validated by a review team
              led by <b>Lt Gen Ajai Kumar Sehgal</b>, a former SSB President,
              SSB-GPT combines traditional training principles with modern AI
              support, helping candidates gain a deeper understanding of
              expectations. Whether you're preparing for psychological tests,
              personal interviews, or physical challenges, SSB-GPT is here to
              support your journey to success.
            </p>
          </div>

          <div className="feature_btns">
            <div className="heading">
              <b>Features</b> ( How to use them)
            </div>
            <div className="btn_grp">
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-ssb-mentor-classroom")}>SSB Mentor</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-cpss-classroom")}>CPSS</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-mock-psychology-classroom")}>Mock Psychology</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-oir-classroom")}>OIR</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-physical-classroom")}>Physical</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-medical-classroom")}>Medical</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-group-discussion-classroom")}>Group Discussion</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-psychology-test-classroom")}>Psychology Test</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-ppdt-classroom")}>PPDT</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-gto-classroom")}>GTO</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-courses-classroom")}>Courses</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-olq-analyse-classroom")}>OLQ Analysis</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-mock-interview-classroom")}>Mock Interview</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-current-affairs-classroom")}>Current Affairs</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-spoken-en-classroom")}>Spoken English</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-ssb-gpt")}>How to use SSB GPT</button>
              </div>
              <div className="btn">
                <button onClick={()=> redirectOnPage("/how-use-notes-classroom")}>How to use Notes</button>
              </div>
            </div>
          </div>
        </section>
      </ProfileLayout>
    </GptLayout>
  );
};

export default HowToUse;
