import React, { Fragment } from "react";
import "../../styles/CPSSExpertProfile.scss";
import CustomHelmet from "../reusable/Helmet";
import GptLayout from "../ssbGpt/gptLayout";
import { FiMessageCircle } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import airForce from "../../images/icons/air-force.png";
import CPSS_task_scren from "../../images/CPSS_task_scren.png";
import brain from "../../images/icons/brain.png";
import dailySkillBuilding from "../../images/dailySkillBuilding.jpg";
import cognitiveMotorCoordination from "../../images/cognitiveMotorCoordination.jpg";
import skillBuildingExercises from "../../images/skillBuildingExercises.png";
import { GoPlus } from "react-icons/go";
import { cpssModal } from "../../assets/images";
import { useAuth } from "../../context/userContext";

const CPSSExpertProfile = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  const handleRedirectToChat = () => {
    if (auth?.user?._id) {
      navigate(`/chat?type=CPSS&isRedirected=true`);
    } else {
      navigate("/login", { state: `/chat?type=CPSS&isRedirected=true` });
    }
  };
  return (
    <Fragment>
      <CustomHelmet
        title="Meet Sanjeev Kumar - CPSS Expert - SSB-GPT"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <div className="container">
          <div className="CPSSExpertProfile_main txt_color">
            <div className="hero_section">
              <div className="left_sec">
                <div className="heading_top">Your AI CPSS Trainer</div>
                <h1 className="main_heading">
                  Meet Sanjeev Kumar <span>Your AI CPSS Expert</span>
                </h1>
                <p className="description">
                  Sanjeev Kumar is a dedicated AI expert in CPSS, crafted to
                  support and train you in excelling at the Computerized Pilot
                  Selection System, the gateway to becoming an Air Force pilot.
                  With insights directly informed by experienced IAF CPSS
                  experts, Sanjeev offers a tailored approach to mastering the
                  specific skills, coordination, and mental agility required to
                  clear the CPSS assessment with confidence.
                </p>
                <button className="talk_captain" onClick={handleRedirectToChat}>
                  <FiMessageCircle /> Talk to Sanjeev
                </button>
              </div>
              <div className="right_sec">
                <div className="intro_modal_sec">
                  <div className="profile_pic">
                    <img src={cpssModal} alt="cpssModal" />
                  </div>
                  <div className="name_sec">
                    <div className="name">Sanjeev Kumar</div>
                    <div className="desc">AI CPSS Expert</div>
                  </div>
                </div>
                <div className="social_links">
                  <Link to="https://www.facebook.com/profile.php?id=61568147290405	" title="Facebook" target="_blank">
                    <FaFacebookF />
                  </Link>
                  <Link to="https://x.com/expertCPSS" title="Twitter" target="_blank">
                    <FaXTwitter />
                  </Link>
                  <Link to="https://www.youtube.com/@AISanjeevKumar" title="Youtube" target="_blank">
                    <FaYoutube />
                  </Link>
                  <Link to="#!" title="Instagram" target="_blank">
                    <FaInstagram />
                  </Link>
                </div>
              </div>
            </div>

            <div className="effective_member">
              <h2 className="heading">
                <span>FLIGHT SIMULATION:</span> NOW UNDERSTAND WORKING OF THE
                INSTRUMENTS
              </h2>
              <p className="desc">
                Advanced AI delivers realistic flight simulations, helping
                candidates optimize their CPSS skills dynamically.
              </p>
              <div className="ai_img">
                {/* <img src={fitness_coach_home} alt="fitness_coach_home" /> */}
                <iframe
                  width="853"
                  height="480"
                  src="https://www.youtube.com/embed/KOK3IZut4D4?autoplay=1&controls=0&rel=0&mute=1&loop=1&playlist=KOK3IZut4D4"
                  title="cpss simulation"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className="next_level_prep">
              <h2 className="heading">
                EXPERIENCE ELITE-LEVEL{" "}
                <span>CPSS TRAINING AND GUIDANCE TODAY.</span>
              </h2>
              <div className="group_task_strategy">
                <div className="left_sec">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Realistic CPSS <img src={airForce} alt="air-force" />{" "}
                    Simulations
                  </h2>
                  <p className="desc">
                    Sanjeev replicates the exact challenges of the CPSS,
                    including tests of hand-eye coordination, cognitive speed,
                    multitasking, and reflexes. Experience the CPSS environment
                    before you even step into the testing room.
                  </p>
                  <button className="talk_btn" onClick={handleRedirectToChat}>
                    <GoPlus />
                    Talk You To Sanjeev Kumar
                  </button>
                </div>
                <div className="right_sec">
                  <div className="img_sec">
                    <img src={CPSS_task_scren} alt="CPSS_task_scren" />
                  </div>
                </div>
              </div>

              <div className="core_expretise_sec">
                <div className="psychTestPrep">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Daily Skill-Building <img src={brain} alt="brain" />{" "}
                    Exercises
                  </h2>
                  <p className="desc">
                    Build muscle memory and improve coordination through
                    structured daily exercises focusing on multitasking,
                    hand-foot coordination, and situational awareness – all
                    critical for CPSS.
                  </p>
                  <img src={dailySkillBuilding} alt="dailySkillBuilding" />
                </div>
                <div className="interviewSkillsPersonality">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Cognitive and Motor Coordination Enhancement
                  </h2>
                  <p className="desc">
                    Training modules designed to refine your quick
                    decision-making and coordination skills, ensuring you are
                    mentally and physically prepared.
                  </p>
                  <img
                    src={cognitiveMotorCoordination}
                    alt="cognitiveMotorCoordination"
                  />
                </div>
              </div>

              <div className="militaryAwareness">
                <div className="right_sec">
                  <div className="img_sec">
                    <div className="circle_sec">
                      <div className="red"></div>
                      <div className="blue"></div>
                      <div className="yellow"></div>
                    </div>
                  </div>
                  <img
                    src={skillBuildingExercises}
                    alt="skillBuildingExercises"
                  />
                </div>
                <div className="left_sec">
                  <div className="top_heading">Core Expertise</div>
                  <h2 className="sec_heading">
                    Daily Skill-Building Exercises
                  </h2>
                  <p className="desc">
                    Build muscle memory and improve coordination through
                    structured daily exercises focusing on multitasking,
                    hand-foot coordination, and situational awareness – all
                    critical for CPSS.
                  </p>
                  <button className="talk_btn" onClick={handleRedirectToChat}>
                    <GoPlus />
                    Talk you to Sanjeev Kumar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default CPSSExpertProfile;
