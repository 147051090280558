import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/interview.scss";
import { useAuth } from "../../context/userContext";
import {  spokenEnglishTrainer } from "../../assets/images";
import { toast } from "react-toastify";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import speakerWave from "../../images/icons/speakerWave.gif";
import { LuInspect } from "react-icons/lu";
import { CiMicrophoneOn } from "react-icons/ci";
import SpeechSynthesizer from "../reusable/SpeechSynthesizer";
import CustomButton from "../reusable/CustomButton";

const AiSpokenEnglish = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [auth] = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [startTest, setStartTest] = useState(false);
  const streamRef = useRef(null);
  const [askQuestion, setAskQuestion] = useState("");
  const [answerQuestion, setAnswerQuestion] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [preparingQuestion, setPreparingQuestion] = useState(false);
  const [answerBox, setAnswerBox] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState();
  const [allVoices, setAllVoices] = useState([]);
  const [answer, setAnswer] = useState("");
  const [isSpeakerStarted, setIsSpeakerStarted] = useState(false);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const synthesizer = new SpeechSynthesizer();

  // CHECK DEVICE
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const handleStartTest = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      if (stream) {
        // setPreparingQuestion(true);
        setStartTest(true);
        if(!isIOS){
          setAskQuestion(true);
        }
        SpeechRecognition.stopListening();
        resetTranscript();
        try {
          let response = await axios.post(
            "https://chat.ssbgpt.in/api/v1/spoken-english",
            { inputText: searchValue || "Hello", conversationId },
            { headers: { "Content-Type": "application/json" } }
          );

          if (response?.data?.conversation_id && response?.data?.answer) {
            setConversationId(response.data.conversation_id);
            setAnswer(response?.data?.answer);
            setPreparingQuestion(false);

            synthesizer.setText(response.data.answer); // Set the text from API response
            synthesizer.setOptions({ rate: 0.9, pitch: 0.4, volume: 1 }); // Configure speech settings
            synthesizer.play();
            synthesizer.onEnd(() => {
              setSearchValue("");
              setAskQuestion(false);
              setAnswerQuestion(true);
              startListening();
              handleTestAnswer();
              response = "";
            });
          } else {
            // In case the response format is incorrect or missing data
            throw new Error("Invalid response data");
          }
        } catch (apiError) {
         
          // setPreparingQuestion(false);
          setAskQuestion(false);
          toast.error("Failed to fetch interview question.");
        }
      }
    } catch (error) {
      setAskQuestion(false);
      toast.error("Camera access denied. Redirecting to home page.");
      navigate("/");
    }
  };

  const handleStartWithSynthesizer = async () => {
    await handleStartTest();
    if (answer) {
      synthesizer.play(); // Play the speech
    }
  };

  const synthesizerPlay = () => {
    if (answer) {
      synthesizer.setText(answer);
      synthesizer.play();
      setAskQuestion(true);
      setIsSpeakerStarted(true);
      synthesizer.onEnd(() => {
        setSearchValue("");
        setAskQuestion(false);
        setPreparingQuestion(false);
        setAnswerQuestion(true);
        startListening();
        handleTestAnswer();
      });
    } else {
      alert("Text not found");
    }
  };

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      return null;
    }
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      window.speechSynthesis.cancel();
    };
  }, [browserSupportsSpeechRecognition]);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const handleTestAnswer = () => {
    setAnswerBox(true);
  };

  const uploadAnswer = async () => {
    if (searchValue !== "") {
      try {
        setAnswerBox(false);
        SpeechRecognition.stopListening();
        resetTranscript();
        setAnswerQuestion(false);
        setAskQuestion(true);
        // setPreparingQuestion(true);
        let response = await axios.post(
          "https://chat.ssbgpt.in/api/v1/spoken-english",
          {
            inputText: searchValue,
            conversationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // setPreparingQuestion(false);
        setConversationId(response?.data?.conversation_id);
        if (response?.data?.answer) {
          setAnswer(response?.data?.answer);
          synthesizer.setText(response.data.answer); // Set the text from API response
          synthesizer.setOptions({ rate: 0.9, pitch: 0.4, volume: 1 }); // Configure speech settings
          synthesizer.play();
          synthesizer.onEnd(() => {
            setSearchValue("");
            setAskQuestion(false);
            // setPreparingQuestion(false);
            setAnswerQuestion(true);
            startListening();
            handleTestAnswer();
            response = "";
          });

          setSearchValue("");
          setAnswerBox(false);
        }
      } catch (error) {
        toast.error(error);
        setAnswerBox(false);
      }
    } else {
      toast.error("Please write your answer");
    }
  };

  const uploadAnswerWithSynthesizer = async () => {
    await uploadAnswer();
    synthesizer.play(); // Play the speech
  };

  useEffect(() => {
    if (transcript) {
      setSearchValue(transcript);
    }
  }, [transcript]);

  const handleKeyDown = (event) => {

    if (event.key === "Enter" && searchValue !== "") {
      uploadAnswerWithSynthesizer();
    }
  };

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing the camera:", err);
      }
    };

    startCamera();

    return () => {
      stopCamera();
    };
  }, []);


  const stopCamera = () => {
    if (streamRef.current) {
      // Stop all video tracks
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Clear the reference
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Clear the reference
    }

    // Stop microphone
    SpeechRecognition.stopListening();
  };

  const redirectToYourProfile = () => {
    if (auth?.user?.role === "13" || auth?.user?.role === "12") {
      navigate("/teacher/profile");
    } else if (auth?.user?.role === "11") {
      navigate("/student/profile");
    } else {
      navigate("/parent/profile");
    }
  };

  return (
    <Fragment>
      <GptLayout>
        <div className="container">
          <section className="interview_wrapper">
            {/* <div className="container"> */}
            <div className="interview_sec">
              <div>
                <div className="title">
                {!startTest ?
                  <h2>Instruction</h2> :
                  <h2>Spoken English</h2> 
                }
                  {/* <p>Powered By Alpha Regiment</p> */}
                </div>

                {!startTest ? (
                  <div className="test_instruction">
                    <h2>Points to be noted :</h2>
                    <ul>
                      <li>
                        <p>
                          Mark is an AI English Trainer. He will help you
                          improve your communication skills through practice, as
                          you know that power of expression is one of the
                          Officer Like Qualities (OLQs). So, please try to
                          improve as much as you can.
                        </p>
                      </li>
                      <li>
                        <p>
                          Double-check your response before submitting to avoid
                          misunderstandings caused by mispronunciation or
                          background noise. You will be able to review and edit
                          your response before final submission, ensuring better
                          accuracy and analysis.
                        </p>
                      </li>
                      <li>
                        <p>
                          Feel free to share any personal information with our
                          bot, and rest assured that your data is secure at all
                          times.
                        </p>
                      </li>
                      <li>
                        <p>
                          Please give microphone and camera permission to use
                          all the features effectively.
                        </p>
                      </li>
                    </ul>
                    <div className="start_btn">
                      <button onClick={() => handleStartWithSynthesizer()}>
                        Start
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="attend_interview">
                      <div className="ai_img">
                        <div className="box">
                          <img src={spokenEnglishTrainer} alt="ssbBot" />
                          {askQuestion && (
                            <div className="speakerWave">
                              <img src={speakerWave} alt="speaker wave" />
                            </div>
                          )}
                        </div>
                        {/* {preparingQuestion && <h4>Analysing...</h4>} */}
                        <div className="aiModal">
                          <div className="name">
                            <span>MARK</span>
                            <span
                              title="See Mark's Profile"
                              onClick={() =>
                                navigate("/profile/english-trainer")
                              }
                            >
                              <LuInspect />
                            </span>
                          </div>
                          <div className="description">
                            AI Spoken English Trainer
                          </div>
                        </div>
                      </div>
                      <div className="student_face_cam">
                        <div className="box">
                          <video
                            playsInline
                            webkit-playsinline="true"
                            ref={videoRef}
                            autoPlay
                            muted
                          />
                          {answerQuestion && (
                            <div className="speakerWave">
                              <img src={speakerWave} alt="speaker wave" />
                            </div>
                          )}
                          <button
                            onClick={startListening}
                            className="micButton"
                            title="Refresh Mic"
                          >
                            <CiMicrophoneOn />
                          </button>
                        </div>
                        <div className="userDetails">
                          <div className="name">
                            <span>{auth?.user?.first_name}</span>
                            <span
                              title="See Your Profile"
                              onClick={redirectToYourProfile}
                            >
                              <LuInspect />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isIOS && !isSpeakerStarted && (
                      <div className="startSpeechIOS">
                        <CustomButton
                          title="Start"
                          tooltip="Start"
                          onClick={synthesizerPlay}
                        />
                      </div>
                    )}

                    {/* <div className="question_list">
                        <p>
                          <span>Alpha Bot :</span>
                          <span>Tell me something about yourself</span>
                        </p>
                        <p>
                          <span>You :</span>
                          <span>
                            My self Rahul Yadav and i am a student for computer
                            science engineering.
                          </span>
                        </p>
                      </div> */}

                    <div className="input_box">
                      {answerBox && (
                        <div>
                          <textarea
                            type="text"
                            value={searchValue}
                            style={{ border: "1px solid #fff" }}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Write your answer"
                          />
                          <button
                            className="submit"
                            onClick={uploadAnswerWithSynthesizer}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* </div> */}
          </section>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default AiSpokenEnglish;
