import React, { useState, useEffect, useRef, Fragment } from "react";
import TestLayout from "./TestLayout";
import { goodPerformance, needImprovement } from "../../../assets/images";
import moment from "moment";

const DotEstimateTest = () => {
  const gameBoxRef = useRef(null);
  const [balls, setBalls] = useState([]);
  const smallBallSize = 8;
  const largeBallSize = 15;
  const [timeLeft, setTimeLeft] = useState(120);
  const [userAnswers, setUserAnswers] = useState({
    small: "",
    large: "",
  });

  useEffect(() => {
    const gameBox = gameBoxRef.current;
    if (!gameBox) return;

    const gameBoxWidth = gameBox.offsetWidth;
    const gameBoxHeight = gameBox.offsetHeight;

    const generateBalls = () => {
      const newBalls = Array.from({ length: 40 }, () => {
        const size = Math.random() < 0.5 ? smallBallSize : largeBallSize;
        const x = Math.random() * (gameBoxWidth - size);
        const y = Math.random() * (gameBoxHeight - size);
        return { size, x, y };
      });
      setBalls(newBalls);
    };

    generateBalls();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleAnswerChange = (type, value) => {
    setUserAnswers((prev) => ({ ...prev, [type]: value }));
  };

  const [answerCheck, setAnswerCheck] = useState(false);
  const [feedback, setFeedback] = useState(0);

  const checkAnswers = () => {
    const correctAnswers = {
      large: balls.filter((ball) => ball.size === largeBallSize).length,
      small: balls.filter((ball) => ball.size === smallBallSize).length,
    };

    const isAllCorrect =
      parseInt(userAnswers.large) === correctAnswers.large &&
      parseInt(userAnswers.small) === correctAnswers.small;
    setAnswerCheck(true);

    if (isAllCorrect) {
      setFeedback(1);
    } else {
      setFeedback(0);
    }
  };

  console.log("feedback", feedback);

  const timerProgress = ((120 - timeLeft) / 120) * 100;

  const formattedTime = moment
    .utc(moment.duration(timeLeft, "seconds").asMilliseconds())
    .format("mm:ss");

  return (
    <Fragment>
      <TestLayout>
        <div className="dot_estimate_test">
          <h2>Dot Estimation Test 123</h2>

          {!answerCheck && timeLeft > 0 ? (
            <>
              <div
                className="timer"
                style={{
                  background: `conic-gradient(red ${timerProgress}%, #ddd ${timerProgress}% 100%)`,
                }}
              >
                <p>{formattedTime}</p>
              </div>
              <div ref={gameBoxRef} className="game-box">
                {balls.map((ball, index) => (
                  <div
                    key={index}
                    className="ball"
                    style={{
                      width: `${ball.size}px`,
                      height: `${ball.size}px`,
                      left: `${ball.x}px`,
                      top: `${ball.y}px`,
                      fontSize: `${ball.size / 3}px`,
                    }}
                  ></div>
                ))}
              </div>

              <div className="answer-boxes">
                <div className="answer-box">
                  <p>Small Balls </p>
                  <input
                    type="number"
                    value={userAnswers.small}
                    onChange={(e) =>
                      handleAnswerChange("small", e.target.value)
                    }
                  />
                </div>
                <div className="answer-box">
                  <p>Large Balls</p>
                  <input
                    type="number"
                    value={userAnswers.large}
                    onChange={(e) =>
                      handleAnswerChange("large", e.target.value)
                    }
                  />
                </div>
              </div>

              <button className="submit-button" onClick={() => checkAnswers()}>
                Submit
              </button>
            </>
          ) : (
            <div className="feedback_box">
              {/* <h6>
                Correct Answers :
              </h6>
              <ul>
                <li>Large Balls: {feedback.correctAnswers.large}</li>
                <li>Small Balls: {feedback.correctAnswers.small}</li>
              </ul> */}
              {feedback === 1 ? (
                <>
                  <img src={goodPerformance} alt="good" />
                  <p>
                    You have performed well in the test, demonstrating great
                    understanding and preparation. Keep up the good work and
                    continue striving for excellence!🎉
                  </p>
                </>
              ) : (
                <>
                  <img src={needImprovement} alt="needImprovement" />
                  <p>
                    Your performance shows potential, but there is room for
                    improvement
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </TestLayout>
    </Fragment>
  );
};

export default DotEstimateTest;
