import React, { useState, useEffect } from "react";
import "../../../styles/memoryRecall.scss";
import TestLayout from "./TestLayout";
import { goodPerformance, needImprovement } from "../../../assets/images";
const generateRandomAlphabet = () => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return letters[Math.floor(Math.random() * letters.length)];
};

// Component for Memory Recall Test
const MemoryRecallTest = () => {
  const [firstScreenAlphabets, setFirstScreenAlphabets] = useState([]);
  const [secondScreenAlphabets, setSecondScreenAlphabets] = useState([]);
  const [userSelections, setUserSelections] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (currentRound < 30) {
      const firstScreen = Array.from({ length: 5 }, generateRandomAlphabet);
      setFirstScreenAlphabets(firstScreen);

      const secondScreen = [
        ...firstScreen.slice(0, 1),
        generateRandomAlphabet(),
        generateRandomAlphabet(),
        generateRandomAlphabet(),
      ].sort(() => Math.random() - 0.5);
      setSecondScreenAlphabets(secondScreen);

      const timer1 = setTimeout(() => {
        setIsFirstScreen(false);
      }, 2500);

      const timer2 = setTimeout(() => {
        handleSubmit();
      }, 5000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    } else {
      setShowResult(true);
    }
  }, [currentRound]);

  const handleSelection = (index) => {
    if (!userSelections.includes(index)) {
      setUserSelections((prev) => [...prev, index]);
    }
  };

  const handleSubmit = () => {
    const correctAnswers = firstScreenAlphabets
      .map((_, index) => index)
      .filter((index) => userSelections.includes(index));
    setCorrectCount(correctAnswers.length);
    setIsFirstScreen(true);
    setCurrentRound((prev) => prev + 1);
    setUserSelections([]);
  };

  

  return (
    <TestLayout>
        <div className="container">
          <div className="mmry_recall_test">
            <h1>Memory Recall Test</h1>
            <div className="steps">
              <div>
                {showResult ? (
                   <div className="feedback_box">
                   {correctCount > 25 ? (
                     <>
                       <img src={goodPerformance} alt="good" />
                       <p>
                         You have performed well in the test, demonstrating great
                         understanding and preparation. Keep up the good work and
                         continue striving for excellence!🎉
                       </p>
                     </>
                   ) : (
                     <>
                       <img src={needImprovement} alt="needImprovement" />
                       <p>
                         Your performance shows potential, but there is room for
                         improvement
                       </p>
                     </>
                   )}
                 </div>
                ) : (
                  <>
                    {isFirstScreen ? (
                      <div className="test">
                        <h2>{firstScreenAlphabets.join(", ")}</h2>
                      </div>
                    ) : (
                      <div className="test2">
                        <h2>Choose the Alphabets from Screen 1</h2>
                        <div>
                          {secondScreenAlphabets.map((alpha, index) => (
                            <button
                              key={index}
                              onClick={() => handleSelection(index)}
                            >
                              <p>{index + 1}</p>
                              <p
                                className="box"
                                style={{
                                  backgroundColor: userSelections.includes(
                                    index
                                  )
                                    ? "#00b67b"
                                    : "#f2f2f2",
                                }}
                              >
                                {alpha}
                              </p>
                            </button>
                          ))}
                        </div>
                      
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        </TestLayout>
  );
};

export default MemoryRecallTest;
