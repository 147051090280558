import React, { useState } from "react";
import "../../styles/footer.scss";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaEnvelope,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { BsAndroid2, BsApple } from "react-icons/bs";
import { toast } from "react-toastify";

const Footer = () => {
  // const todayDate = new Date();
  // let currentYear = todayDate.getFullYear();

  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackLoad, setFeedbackLoad] = useState(false);

  // SUBMIT FEEDBACK DATA
  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    await setFeedbackLoad(true);
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through Common Feedback form",
      Body:
        "<h3>This Email Email sent through Common Feedback form</h3> <br/><p><b>Feedback:</b> " +
        feedbackText +
        "</p>",
    };
    if (feedbackText) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Feedback Sent Successfully.");
          setFeedbackText("");
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
    setFeedbackLoad(false);
  };

  return (
    <div className="footer_main txt_color">
      <div className="container">
        <div className="footer_row">
          <div className="footer_links">
            <p className="linkHeadin">Legal & Policies</p>
            <Link to="/privacy-policies">Privacy Policy</Link>
            <Link to="/term-of-use">Terms & Conditions</Link>
            <Link to="/refund-cancellation-policy">Refund Policy</Link>
            <br />
            <p className="linkHeadin">Company</p>
            <Link to="/about-us">About us</Link>
            <Link to="/contact-us">Contact Us</Link>
            <Link target="_blank" rel="noopener noreferrer" to="/sitemap.xml">Sitemap</Link>
            <Link
              to="https://alpharegiment.com/we-are-hiring/"
              target="_blank"
              rel="noopener noreferrer"
            >
              We are hiring!
            </Link>
          </div>
          <div className="feedback_form">
            <div>
              <h2>We value your feedback</h2>
              <input
                type="text"
                name="feedback"
                placeholder="Write your feedback..."
                onChange={(e) => setFeedbackText(e.target.value)}
                value={feedbackText}
              />
              <button
                className={`feedback_btn ${
                  feedbackLoad || feedbackText.length < 1 ? "disabled" : ""
                }`}
                disabled={feedbackLoad ? true : false}
                onClick={handleFeedbackSubmit}
              >
                feedback
              </button>
            </div>
          </div>
          <div className="footer_links">
            <p className="linkHeadin">Our Other GPTs</p>
            <Link to="#!">
              NDA-GPT <div className="badge">Coming Soon</div>{" "}
            </Link>
            <Link to="#!">
              CDS-GPT <div className="badge">Coming Soon</div>{" "}
            </Link>
            <Link to="#!">
              INET-GPT <div className="badge">Coming Soon</div>{" "}
            </Link>
            <Link to="#!">
              AFCAT-GPT <div className="badge">Coming Soon</div>{" "}
            </Link>

            <br />
            <p className="linkHeadin">Partner</p>
            <Link
              rel="nofollow noopener"
              to="https://alpharegiment.in/alpha-school"
            >
              School Partner
            </Link>
            <Link
              rel="nofollow noopener"
              to="https://alpharegiment.in/franchise/home"
            >
              Franchisee
            </Link>
            <Link
              rel="nofollow noopener"
              to="https://alpharegiment.in/cyber-partner"
            >
              Cyber Partner
            </Link>
          </div>
        </div>
        <div className="social_links">
          <div className="app_link">
            <p>Connect with us</p>
            <div className="links">
              <Link to="https://www.instagram.com/ssbgpt/" target="_blank" title="Instagram" rel="nofollow noopener">
                <FaInstagram />
              </Link>
              <Link to="https://www.youtube.com/@SSBGPT"  target="_blank" title="Youtube" rel="nofollow noopener">
                <FaYoutube />
              </Link>
              <Link to="https://www.linkedin.com/company/ssb-gpt/"  target="_blank" title="Linkedin" rel="nofollow noopener">
                <FaLinkedin />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=61568516845347"  target="_blank" title="Facebook" rel="nofollow noopener">
                <FaFacebookF />
              </Link>
              <Link to="https://x.com/ssbgpt"  target="_blank" title="Twitter" rel="nofollow noopener">
                <FaTwitter />
              </Link>
              <Link
                // to="mailto:hello@alpharegiment.com"
                title="Email"
                rel="nofollow noopener"
              >
                <FaEnvelope />
              </Link>
            </div>
          </div>
          <div className="app_link">
            <p>Download our official app</p>
            <div className="links">
              <Link
                to="#!"
                title="Download Android App"
                rel="nofollow noopener"
              >
                <BsAndroid2 />
              </Link>
              <Link to="#!" title="Download IOS App" rel="nofollow noopener">
                <BsApple />
              </Link>
            </div>
          </div>
        </div>
        <p className="copyright_txt">
          Copyright © ARCAPTOR TECHNOLOGIES PRIVATE LIMITED. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
