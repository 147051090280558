import React from "react";
import styled from "styled-components";
import { GrRotateRight } from "react-icons/gr";

const LoaderSec = styled.div`
color: ${props => props.color};
  > svg {
    animation: rotateLoader 1s infinite linear;
  }
`;

const ButtonLoader = (color) => {
  return (
    <LoaderSec color={color ? color : "#081336"}>
      <GrRotateRight />
    </LoaderSec>
  );
};

export default ButtonLoader;
