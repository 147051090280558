import React, { Fragment } from "react";
import GptLayout from "../gptLayout";
import "../../../styles/eyeTest.scss";

const EyeTest = () => {
  return (
    <Fragment>
      <GptLayout>
        <section className="container">
          <div className="eye_test_page"></div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default EyeTest;
