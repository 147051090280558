import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import CustomHelmet from "../../component/reusable/Helmet";
import { FaArrowLeftLong } from "react-icons/fa6";

const CoursesHowUse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="how_UseClassRoom_main">
        <div className="container">
          <div className="bacbtnWthHeading">
            <button
              title="back"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeftLong />
            </button>
            <h1 className="main_heading">Courses</h1>
          </div>
        </div>
        <div className="howUse_content container"></div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default CoursesHowUse;
