import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT_REQUEST,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CLEAR_UPDATE_STATE,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_HELP_REQUEST,
  PROFILE_HELP_SUCCESS,
  PROFILE_HELP_FAIL,
} from "../constants/userConstant";
import axios from "axios";

//   FOR USER REGISTRATION
export const userRegisterAction =
  (
    first_name,
    last_name,
    email,
    phone,
    password,
    profile,
    role,
    referralCode
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/register`,
        {
          first_name,
          last_name,
          email,
          phone,
          password,
          role,
          referralCode,
          profile,
        },
        config
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/login`,
      {
        email,
        password,
      },
      config
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "ssb-token",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// FORGOT PASSWORD
export const requestForgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/forgot-password`,
      { email }
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    localStorage.removeItem("ssb-token");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/profile`,
      config
    );
    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserProfile =
  (first_name, last_name, email, phone, dob, address, profile, gender) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPDATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("ssb-token")
        ? JSON.parse(localStorage.getItem("ssb-token"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/update-profile`,
        { first_name, last_name, email, phone, dob, address, profile, gender },
        config
      );

      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFILE_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// SEND HELP MESSAGE
export const sendHelpMessage = (name, email, message) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_HELP_REQUEST,
    });

    const localStorageToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/ssb-gpt/send-help-message`,
      { name, email, message },
      config
    );

    dispatch({
      type: PROFILE_HELP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_HELP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
