import React from "react";
import "../../styles/videoPopup.scss";
import { AiOutlineClose } from "react-icons/ai";

// Popup for Video
const VideoPopup = ({ showDetailVideo, setshowDetailVideo, videoLink }) => {
  return (
    <section
      className={`video_popup_main ${
        showDetailVideo ? "show_video_Modal" : ""
      } bg_color txt_color`}
    >
      <div className="container">
        {showDetailVideo && (
          <div className="video_content">
            <button
              className="close_modal"
              onClick={() => setshowDetailVideo(false)}
            >
              <AiOutlineClose />
            </button>
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    title="SSB GPT - HOW TO USE VIDEO"
                    src={videoLink}
                    scrolling="no"
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                </figure>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default VideoPopup;
