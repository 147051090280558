import React, { Fragment } from 'react'
import GptLayout from './gptLayout'
import CustomHelmet from '../reusable/Helmet'
import "../../styles/ishihara.scss";
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const IshiharaInstruction = () => {
  const navigate =useNavigate();
  return (
    <Fragment>
    <CustomHelmet
      title="SSB-GPT: Your SSB Interview Self Preparation Tool"
      description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
    />

    <GptLayout>
      <div className="container">
        <div className='go_back'  onClick={()=>navigate(-1)}>
          <button>
            <FaArrowLeft />
          </button>
          Go Back
        </div>
        <div className="ishara_test">
          <div className="title">Ishihara Test</div>
          <p>
            The <b>Ishihara Color Blindness Test</b> is one of the most widely
            used methods to detect color vision deficiencies, particularly
            red-green color blindness. Developed by{" "}
            <b>Dr. Shinobu Ishihara</b> in 1917, this test remains a gold
            standard for assessing color vision.
          </p>
          <ul>
            <b>How the Ishihara Test Works:</b>
            <li>
              The test consists of a series of plates known as Ishihara
              Plates.
            </li>
            <li>
              Each plate contains a circle filled with many colored dots of
              various sizes.
            </li>
            <li>
              Within the circle, there’s a pattern of dots forming a number or
              shape that contrasts in color with the background dots.
            </li>
            <li>
              Individuals with normal color vision can easily identify the
              numbers or patterns, while those with color blindness may have
              difficulty or see different numbers
            </li>
          </ul>
          <b>Key Features:</b>
          <ol>
            <li>
              <b>Types of Plates:</b>
              <ul>
                <li>
                  <strong> Transformation Plates:</strong> A number is visible
                  to those with normal color vision but appears as a different
                  number to individuals with color blindness.
                </li>
                <li>
                  <strong>Vanishing Plates:</strong>
                  The number is visible only to those with normal color
                  vision.
                </li>
                <li>
                  <strong>Hidden Digit Plates:</strong>
                  The number is visible only to those with a color vision
                  deficiency.
                </li>
                <li>
                  <strong>Diagnostic Plates:</strong> Used to determine the
                  type and severity of the color vision defect.
                </li>
              </ul>
            </li>
            <li>
              <b>Common Usage:</b>
              <ul>
                <li>
                  Frequently used in{" "}
                  <strong>
                    aviation, military, and transportation industries
                  </strong>
                  , where accurate color perception is critical.
                </li>
                <li>
                  The test is non-invasive and takes only a few minutes to
                  complete.
                </li>
              </ul>
            </li>
          </ol>
          <ul>
            <b>Limitations:</b>
            <li>
              It is primarily designed to detect{" "}
              <strong>red-green deficiencies</strong> and is less effective
              for blue-yellow deficiencies or total color blindness.
            </li>
            <li>
              Not suitable for assessing color vision in very young children
              or those who cannot understand numbers.
            </li>
          </ul>
          <b>Why Is It Important?</b>
          <p>
            Color blindness can impact various professions, hobbies, and daily
            activities. Early detection through the Ishihara Test helps
            individuals adapt to their condition and understand its
            implications, especially in professions requiring accurate color
            perception.
          </p>
        </div>
      </div>
    </GptLayout>
  </Fragment>
  )
}

export default IshiharaInstruction