import React, { Fragment } from "react";
import "../../styles/howToUseClassroom.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import CustomHelmet from "../../component/reusable/Helmet";
import CPSS_1 from "../../images/howuse/CPSS-1.png";
import cpss_2 from "../../images/howuse/cpss_2.png";
import cpss_3 from "../../images/howuse/cpss_3.png";
import cpss_4 from "../../images/howuse/cpss_4.png";
import cpss_5 from "../../images/howuse/cpss_5.png";
import cpss_6 from "../../images/howuse/cpss_6.png";
import cpss_7 from "../../images/howuse/cpss_7.png";
import cpss_8 from "../../images/howuse/cpss_8.png";

const CPSSHowUse = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      {/* <GptHeader /> */}

      <section className="how_UseClassRoom_main">
        <div className="container">
          <div className="bacbtnWthHeading">
            <button
              title="back"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeftLong />
            </button>
            <h1 className="main_heading">How to use CPSS Classroom</h1>
          </div>
        </div>
        <div className="howUse_content container">
          <p className="descriptionText">
            <b>Welcome Warriors! 👨‍✈️👩‍✈️</b> <br />
            If you aspire to join the flying branch, passing the{" "}
            <b>CPSS Test</b>
            (Computerized Pilot Selection System) is your ultimate milestone.
            Remember, <b>this is a once-in-a-lifetime opportunity</b>, so
            preparation is the key to success. With proper practice and focus,
            you can ace it in your first and only attempt.
            <br />
            To help you prepare, we’ve created a dedicated <b>
              CPSS Classroom
            </b>{" "}
            on our platform. Here’s how you can practice step-by-step:
          </p>

          <h2 className="subHeading">
            Step-by-Step Guide to Practice the CPSS
          </h2>
          <h3 className="tab_heading">
            <b>Step 1.</b> Navigate to the Home Page {`>>`}{" "}
            <Link to="/chat">Ask Your SSB Mentor Now</Link>{" "}
          </h3>
          <img
            src={CPSS_1}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />
          <h3 className="tab_heading">
            <b>Step 2.</b> Click <b>“Choose Classroom”</b>
          </h3>
          <img
            src={cpss_2}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 3.</b> Choose <b>“CPSS”</b> Classroom.
          </h3>
          <img
            src={cpss_3}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 4.</b> Click the <b>“Start”</b> button
          </h3>
          <img
            src={cpss_4}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />

          <h3 className="tab_heading">
            <b>Step 5.</b> Select the button that fits your needs:{" "}
            <b>"Have a query related to CPSS"</b> or{" "}
            <b>"Want to Practice CPSS"</b>. Descriptions of both options are
            provided below.
          </h3>
          <img
            src={cpss_5}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />

          <ul style={{ listStyle: "upper-alpha" }}>
            <li>
              <b>Have a query related to CPSS:</b> Clicking this button allows
              you to ask any query related to CPSS. AI CPSS expert Sanjeev Kumar
              will provide answers to all your CPSS-related questions.
            </li>
            <li>
              <b>Want to Practice CPSS:</b> When you select this option, you
              will see two further choices: Cognitive Ability Test and
              Psychomotor Test.
            </li>
          </ul>

          <h3 className="tab_heading">
            <b>Explanation of the Tests:</b>
          </h3>
          <ul style={{ listStyle: "desimal" }}>
            <li>
              <b>Cognitive Ability Test:</b> This test evaluates your mental
              agility, problem-solving skills, decision-making ability, and
              situational awareness. It is designed to measure how quickly and
              accurately you can process information, which is crucial for a
              CPSS candidate.
            </li>
            <li>
              <b>Psychomotor Test:</b> This test assesses your hand-eye
              coordination, reaction time, and motor skills. It focuses on your
              ability to perform physical tasks accurately under time
              constraints, reflecting your aptitude for handling aviation
              equipment and systems effectively.
            </li>
          </ul>

          <h3 className="tab_heading">
            <b>Step 6.</b> Choose <b>“Cognitive Ability Test”</b> or{" "}
            <b>“Psychomotor Test”</b>
          </h3>
          <h3 className="tab_heading">
            <b>Case I:</b> When Selected <b>“Cognitive Ability Test”</b>
          </h3>
          <p>
            When you click on <b>"Cognitive Ability Test"</b>, you'll be
            introduced to the six tests conducted as part of this category.
            These tests are divided into two groups:
          </p>
          <ul style={{ listStyle: "desimal" }}>
            <li>
              <b>Dial/Instrument Reading Tests (INSB Tests):</b> <br />
              These evaluate your ability to read and interpret aircraft
              instruments and gauges. There are two tests under this category:
              <ul>
                <li>
                  <b>INSB 1:</b> You are shown one instrument or dial with
                  different readings and must calculate or choose the correct
                  final reading.
                </li>
                <li>
                  <b>INSB 2:</b> You are shown three or more instruments
                  simultaneously. Based on their readings, you need to visualize
                  the aircraft's position and select the correct option,
                  typically from illustrations showing various aircraft
                  orientations.
                </li>
              </ul>
            </li>
            <li>
              <b>Reasoning Tests:</b>
              <br />
              These tests assess your logical thinking, pattern recognition, and
              problem-solving abilities. There are four reasoning tests in
              total.
            </li>
          </ul>
          <h3 className="tab_heading">Aircraft Instruments Overview</h3>
          <p>
            Before starting the INSB tests, you'll be given a brief introduction
            to the six key instruments used in aircraft:
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              <b>Airspeed Indicator:</b> Displays the aircraft’s speed relative
              to the surrounding air.
            </li>
            <li>
              <b>Artificial Horizon:</b> Indicates the aircraft's orientation
              relative to the earth’s horizon.
            </li>
            <li>
              <b>Altimeter:</b> Shows the altitude of the aircraft above sea
              level.
            </li>
            <li>
              <b>Heading Indicator:</b> Displays the aircraft’s directional
              heading.
            </li>
            <li>
              <b>Turn Coordinator:</b> Indicates the rate of turn and roll of
              the aircraft.
            </li>
            <li>
              <b>Vertical Speed Indicator:</b> Measures the rate of climb or
              descent.
            </li>
          </ul>
          <p>
            Here in <b>SSB-GPT</b>, you’ll receive the same detailed briefing
            about these instruments before proceeding. At the bottom of the
            briefing page, you'll find a checkbox labeled{" "}
            <b>“Skip and Never Show Again”</b>. If you're already familiar with
            the workings of these instruments, you can check this box and click{" "}
            <b>Next</b> to proceed.
          </p>
          <img
            src={cpss_6}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />
          <h3 className="tab_heading">Flight Simulation</h3>
          <p>
            After the briefing, you'll land on a slide showcasing the control
            buttons required for flight simulation. This simulation replicates
            the INSB tests by allowing you to fly a virtual plane and observe
            how the readings on these six instruments change based on the
            plane's orientation and movements. Practicing in this simulation
            will give you a clear understanding of how these instruments
            function and prepare you effectively for the CPSS INSB tests.
          </p>
          <img
            src={cpss_7}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />
          <h3 className="tab_heading">Reasoning Tests</h3>

          <p>
            If you wish to practice reasoning tests instead of the INSB tests,
            you can choose the <b>Reasoning Test</b> option. These tests help
            sharpen your cognitive reasoning abilities, which are critical for
            success in the CPSS.
          </p>
          <p>
            By selecting the appropriate option—<b>Flight Simulation</b> for
            INSB practice or <b>Reasoning Test</b> for logic-based
            preparation—you can tailor your practice to your specific needs and
            excel in the CPSS tests.
          </p>
          <h3 className="tab_heading">
            <b>Case II:</b> When Selected <b>“Psychomotor Test”</b>
          </h3>
          <p>
            When you select the <b>"Psychomotor Test"</b> option, you'll be
            introduced to the 10 tests included in this category. These tests
            are designed to assess your hand-eye coordination, reaction time,
            multitasking ability, and overall psychomotor skills critical for
            aviation roles. The tests include:
          </p>
          <ul style={{ listStyle: "desimal" }}>
            <li>
              <b>Control Velocity Test</b>
            </li>
            <li>
              <b>Dual Task Test</b>
            </li>
            <li>
              <b>Dot Estimation Test</b>
            </li>
            <li>
              <b>Speed Anticipation Test</b>
            </li>
            <li>
              <b>Size Anticipation Test</b>
            </li>
            <li>
              <b>Pursuit Tracking Test</b>
            </li>
            <li>
              <b>Memory Recalling Test</b>
            </li>
            <li>
              <b>Color Naming Test</b>
            </li>
            <li>
              <b>Vigilance Test</b>
            </li>
          </ul>
          <h3 className="tab_heading">How to Practice Psychomotor Tests</h3>
          <ul>
            <li>
              To practice any test, simply click the <b>"Practice"</b> button
              next to the respective test.
            </li>
            <li>
              If you’re unsure how to perform a particular test, click the{" "}
              <b>"?"</b> icon located next to the <b>Practice</b> button. This
              will provide you with detailed instructions on how to complete the
              test successfully.
            </li>
          </ul>
          <img
            src={cpss_8}
            alt="ssb gpt CPSS test instrunction"
            className="direct_banner"
          />
          <p>
            By navigating through these options, you can practice and improve
            your psychomotor skills systematically, ensuring you're
            well-prepared for the CPSS evaluation.
          </p>
          <br />
          <p>Get ready to take flight with confidence and precision! 🛫</p>
          <p>Let’s make your dreams of wearing those wings a reality! 🚀</p>
          <p>
            <b>Jai Hind!</b>
          </p>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default CPSSHowUse;
