import React, { Fragment, useState } from "react";
import GptLayout from "./gptLayout";
import CustomHelmet from "../reusable/Helmet";
import "../../styles/ishihara.scss";
import {
  info,
  plate1,
  plate10,
  plate11,
  plate12,
  plate13,
  plate14,
  plate15,
  plate16,
  plate17,
  plate18,
  plate19,
  plate2,
  plate20,
  plate21,
  plate22,
  plate23,
  plate24,
  plate25,
  plate26,
  plate3,
  plate4,
  plate5,
  plate6,
  plate7,
  plate8,
  plate9,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";

const platesData = [
  {
    id: 1,
    image: plate1,
    options: ["12", "2", "Nothing"],
    feedback: {
      12: "Congratulations! You have normal color vision.",
      2: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 2,
    image: plate2,
    options: ["8", "3", "Nothing"],
    feedback: {
      8: "Congratulations! You have normal color vision.",
      3: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 3,
    image: plate3,
    options: ["6", "5", "Nothing"],
    feedback: {
      6: "Congratulations! You have normal color vision.",
      5: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 4,
    image: plate4,

    options: ["29", "70", "Nothing"],
    feedback: {
      29: "Congratulations! You have normal color vision.",
      70: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 5,
    image: plate5,

    options: ["57", "35", "Nothing"],
    feedback: {
      57: "Congratulations! You have normal color vision.",
      35: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 6,
    image: plate6,

    options: ["5", "2", "Nothing"],
    feedback: {
      5: "Congratulations! You have normal color vision.",
      2: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 7,
    image: plate7,
    options: ["3", "5", "Nothing"],
    feedback: {
      3: "Congratulations! You have normal color vision.",
      5: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 8,
    image: plate8,
    options: ["15", "17", "Nothing"],
    feedback: {
      15: "Congratulations! You have normal color vision.",
      17: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 9,
    image: plate9,
    options: ["74", "21", "Nothing"],
    feedback: {
      74: "Congratulations! You have normal color vision.",
      21: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 10,
    image: plate10,
    options: ["2", "1", "Nothing"],
    feedback: {
      2: "Congratulations! You have normal color vision.",
      1: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 11,
    image: plate11,
    options: ["6", "8", "Nothing"],
    feedback: {
      6: "Congratulations! You have normal color vision.",
      8: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 12,
    image: plate12,
    options: ["97", "87", "Nothing"],
    feedback: {
      97: "Congratulations! You have normal color vision.",
      87: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 13,
    image: plate13,
    options: ["45", "46", "Nothing"],
    feedback: {
      45: "Congratulations! You have normal color vision.",
      46: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 14,
    image: plate14,
    options: ["5", "6", "Nothing"],
    feedback: {
      5: "Congratulations! You have normal color vision.",
      6: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 15,
    image: plate15,
    options: ["7", "9", "Nothing"],
    feedback: {
      7: "Congratulations! You have normal color vision.",
      9: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 16,
    image: plate16,
    options: ["16", "18", "Nothing"],
    feedback: {
      16: "Congratulations! You have normal color vision.",
      18: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 17,
    image: plate17,
    options: ["73", "78", "Nothing"],
    feedback: {
      73: "Congratulations! You have normal color vision.",
      78: "Red-green deficiency: Most people don’t see anything or see something wrong.",
      Nothing:
        "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 18,
    image: plate18,
    options: ["7", "5", "Nothing"],
    feedback: {
      Nothing: "Congratulations! You have normal color vision.",
      5: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      7: "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 19,
    image: plate19,
    options: ["2", "9", "Nothing"],
    feedback: {
      Nothing: "Congratulations! You have normal color vision.",
      2: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      9: "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 20,
    image: plate20,
    options: ["45", "9", "Nothing"],
    feedback: {
      Nothing: "Congratulations! You have normal color vision.",
      45: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      9: "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 21,
    image: plate21,
    options: ["73", "70", "Nothing"],
    feedback: {
      Nothing: "Congratulations! You have normal color vision.",
      73: "You may have a red-green color vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      70: "You may have vision deficiency. Please visit https://alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 22,
    image: plate22,
    options: ["26", "6", "2"],
    feedback: {
      26: "Congratulations! You have normal color vision.",
      6: "You might have Protanopia or Protanomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      2: "You may have Deuteranopia or Deuteranomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 23,
    image: plate23,
    options: ["42", "2", "4"],
    feedback: {
      42: "Congratulations! You have normal color vision.",
      2: "You might have Protanopia or Protanomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      4: "You may have Deuteranopia or Deuteranomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 24,
    image: plate24,
    options: ["35", "5", "3"],
    feedback: {
      35: "Congratulations! You have normal color vision.",
      5: "You might have Protanopia or Protanomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      3: "You may have Deuteranopia or Deuteranomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 25,
    image: plate25,
    options: ["96", "9", "6"],
    feedback: {
      96: "Congratulations! You have normal color vision.",
      9: "You might have Protanopia or Protanomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      6: "You may have Deuteranopia or Deuteranomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
  {
    id: 26,
    image: plate26,
    options: ["purple and red spots", "purple line", "red line"],
    feedback: {
      "purple and red spots": "Congratulations! You have normal color vision.",
      "purple line":
        "You might have Protanopia or Protanomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
      "red line":
        "You may have Deuteranopia or Deuteranomaly. Please visit alpharegiment.in/medical-checkups to book an appointment with a retired military doctor.",
    },
  },
];

const IshiharaTest = () => {
  const navigate = useNavigate();
  const [currentPlateIndex, setCurrentPlateIndex] = useState(0);
  const [feedbackLog, setFeedbackLog] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isTestCompleted, setIsTestCompleted] = useState(false);

  // const handleOptionClick = (option) => {
  //   const plate = platesData[currentPlateIndex];
  //   const feedback = plate.feedback[option];
  //   if (!feedbackLog.has(feedback)) {
  //     setFeedbackLog((prevLog) => new Set([...prevLog, feedback]));
  //   }
  //   setSelectedOption(option);
  // };

  const handleOptionClick = (option) => {
    // const normalFeedback = "Congratulations! You have normal color vision."
    const plate = platesData[currentPlateIndex];
    setSelectedOption(option);
    const feedback = plate.feedback[option];
    if (!feedbackLog.includes(feedback)) {
      setFeedbackLog((prevLog) => [...prevLog, feedback]);
    }
    if (currentPlateIndex < 25) {
      setTimeout(() => {
        setCurrentPlateIndex((prevIndex) => prevIndex + 1);
        setSelectedOption(null);
      }, 1000);
    } else {
      setIsTestCompleted(true);
    }
  };

  const plate = platesData[currentPlateIndex];


  return (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />

      <GptLayout>
        <div className="container">
          <div className="eye_test">
            <h1>
              ISHIHARA TEST
              <img
                onClick={() => navigate("/ishihara-detail")}
                src={info}
                alt="info"
                title="Know more about ishihara test"
              />
            </h1>
            {!isTestCompleted ? (
              <>
                <img src={plate?.image} alt="plate" />
                <div className="test">
                  {plate.options.map((option) => (
                    <button
                      key={option}
                      onClick={() => handleOptionClick(option)}
                      disabled={selectedOption !== null}
                      style={{
                        backgroundColor:
                          selectedOption === option ? "#ccc" : "#f0f0f0",
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
                {/* {selectedOption && (
                  <div className="feedback">
                    <p>{plate.feedback[selectedOption]}</p>
                    <button onClick={nextPlate}>Next</button>
                  </div>
                )} */}
              </>
            ) : (
              <div className="final_feedback">
                <h2>Test Completed!</h2>
                <p>Thank you for participating. Here are your results:</p>
                <ul>
                  {feedbackLog?.every(
                    (feedback) =>
                      feedback ===
                      "Congratulations! You have normal color vision."
                  ) &&
                    feedbackLog.map((feedback, index) => (
                      <li key={index}>{feedback}</li>
                    ))}

                  {!feedbackLog?.every(
                    (feedback) =>
                      feedback ===
                      "Congratulations! You have normal color vision."
                  ) &&
                    feedbackLog.map((feedback, index) => (
                      <li key={index}>{feedback}</li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </GptLayout>
    </Fragment>
  );
};

export default IshiharaTest;
