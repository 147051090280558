import React, { useState, useEffect, useRef } from "react";
import TestLayout from "./TestLayout";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { goodPerformance, needImprovement } from "../../../assets/images";

const SpeedAnticipationTest = () => {
  const gameBoxRef = useRef(null);
  const [fallingBall, setFallingBall] = useState({ x: 0, y: 0 });
  const [userBallX, setUserBallX] = useState(50);
  const [isBallHidden, setIsBallHidden] = useState(false);
  const [collapsingPoint, setCollapsingPoint] = useState(null);
  const [result, setResult] = useState(0);
  const gameInterval = useRef(null);

  useEffect(() => {
    const gameBox = gameBoxRef.current;
    if (!gameBox) return;

    const gameWidth = gameBox.offsetWidth;
    const gameHeight = gameBox.offsetHeight;
    setUserBallX((gameWidth - 30) / 2);

    const startGame = () => {
      let x = Math.random() * (gameWidth - 30);
      let y = 0;
      let dx = 5;
      let dy = 5;
      const hideY = gameHeight / 2;

      gameInterval.current = setInterval(() => {
        x += dx;
        y += dy;

        if (x <= 0 || x >= gameWidth - 30) dx *= -1;

        if (y >= gameHeight - 30) {
          clearInterval(gameInterval.current);
          setFallingBall({ x, y: 0 });
          setCollapsingPoint(x);
          return;
        }

        setFallingBall({ x, y });
        if (y >= hideY) setIsBallHidden(true);
      }, 50);
    };

    startGame();

    return () => clearInterval(gameInterval.current);
  }, []);

  // const handleDrag = (e) => {
  //   if (!isDragging) return;

  //   const gameBox = gameBoxRef.current;
  //   const gameWidth = gameBox.offsetWidth;
  //   let newX = e.clientX - gameBox.offsetLeft - 15;
  //   if (newX < 0) newX = 0;
  //   if (newX > gameWidth - 30) newX = gameWidth - 30;

  //   setUserBallX(newX);
  // };

  const moveUserBall = (direction) => {
    const gameBox = gameBoxRef.current;
    const gameWidth = gameBox.offsetWidth;

    let newX = userBallX + (direction === "left" ? -20 : 20);
    if (newX < 0) newX = 0;
    if (newX > gameWidth - 30) newX = gameWidth - 30;

    setUserBallX(newX);
  };

  const [check, setCheck] = useState(false);

  const handleSubmit = () => {
    if (collapsingPoint !== null) {
      const marginOfError = 15;
      if (
        userBallX >= collapsingPoint - marginOfError &&
        userBallX <= collapsingPoint + marginOfError
      ) {
        setResult(1);
      } else {
        setResult(2);
      }
    }
  };

  useEffect(() => {
    if (result !== 0) {
      setTimeout(() => {
        setCheck(true);
      }, 1000);
    }
  },[result]);

 
  
  

  return (
    <TestLayout>
      <div className="speed_anti_test">
        <h2>Speed Anticipation Test</h2>
        {!check ? (
          <>
            <div className="test_box" ref={gameBoxRef}>
              {!isBallHidden && (
                <div
                  className="falling-ball"
                  style={{
                    left: `${fallingBall.x}px`,
                    top: `${fallingBall.y}px`,
                  }}
                ></div>
              )}
              {collapsingPoint !== null && result !== 0 && (
                <div
                  className="collapsing-point"
                  style={{ left: `${collapsingPoint}px`, bottom: "0px" }}
                ></div>
              )}
              <div className="user_slide">
                <div
                  className="user-ball"
                  style={{ left: `${userBallX}px` }}
                ></div>
              </div>
            </div>
            <div className="path">
              <button title="Move Left" onClick={() => moveUserBall("left")}>
                <FaAngleLeft />
              </button>

              <button title="Move Right" onClick={() => moveUserBall("right")}>
                <FaAngleRight />
              </button>
            </div>
            <div className="btn_group">
              <button className="submit_btn" onClick={handleSubmit}>
                Target Hit
              </button>
            </div>
          </>
        ) : (
          <div className="feedback_box">
            {result === 1 ? (
              <>
                <img src={goodPerformance} alt="good" />
                <p>
                  You have performed well in the test, demonstrating great
                  understanding and preparation. Keep up the good work and
                  continue striving for excellence!🎉
                </p>
              </>
            ) : (
              <>
                <img src={needImprovement} alt="needImprovement" />
                <p>
                  Your performance shows potential, but there is room for
                  improvement
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </TestLayout>
  );
};

export default SpeedAnticipationTest;
