import React, { useState, useEffect, useRef } from "react";
import TestLayout from "./TestLayout";
import moment from "moment";
import { goodPerformance, needImprovement } from "../../../assets/images";

const SensorMotorTest = () => {
  const [balls, setBalls] = useState([]);
  const [blinkingBallIndex, setBlinkingBallIndex] = useState(null);
  const [boxPosition, setBoxPosition] = useState({ x: 90, y: 90 });
  const [joystickPosition, setJoystickPosition] = useState({ x: 0, y: 0 });
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(120);
  const boxRef = useRef(null);
  const gameBoxRef = useRef(null);
  const joystickRef = useRef(null);
  const dragging = useRef(false);

  useEffect(() => {
    const generatedBalls = Array.from({ length: 40 }, () => ({
      x: Math.random() * 90 + 5,
      y: Math.random() * 90 + 5,
    }));
    setBalls(generatedBalls);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleblinkBall = () => {
    const randomBallIndex = Math.floor(Math.random() * balls.length);
    setBlinkingBallIndex(randomBallIndex);
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      if (isOverlap(balls[randomBallIndex])) {
        setScore((prev) => prev + 1);
        clearInterval(interval);
      } else if (elapsed >= 5000) {
        clearInterval(interval);
      }
    }, 50);
  };

  useEffect(() => {
    const blinkTimeOut = setTimeout(() => handleblinkBall(), 10000);
    return () => clearTimeout(blinkTimeOut);
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    dragging.current = true;
  };

  const handleMouseMove = (e) => {
    if (!dragging.current) return;
    e.preventDefault();
    const gameBox = gameBoxRef.current.getBoundingClientRect();
    const x = ((e.clientX - gameBox.left) / gameBox.width) * 100;
    const y = ((e.clientY - gameBox.top) / gameBox.height) * 100;
    const scaledX = boxPosition.x + (x - boxPosition.x) * 0.01;
    const scaledY = boxPosition.y + (y - boxPosition.y) * 0.01;

    setBoxPosition({
      x: Math.min(100, Math.max(0, scaledX)),
      y: Math.min(100, Math.max(0, scaledY)),
    });
  };

  const handleMouseUp = () => {
    dragging.current = false;
  };

  const handleTouchStart = (e) => {
    dragging.current = true;
  };

  const handleTouchMove = (e) => {
    if (!dragging.current) return;
    // e.preventDefault();
    const gameBox = gameBoxRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const x = ((touch.clientX - gameBox.left) / gameBox.width) * 100;
    const y = ((touch.clientY - gameBox.top) / gameBox.height) * 100;

    const scaledX = boxPosition.x + (x - boxPosition.x) * 0.01;
    const scaledY = boxPosition.y + (y - boxPosition.y) * 0.01;

    setBoxPosition({
      x: Math.min(100, Math.max(0, scaledX)),
      y: Math.min(100, Math.max(0, scaledY)),
    });
  };

  const handleTouchEnd = () => {
    dragging.current = false;
  };

  const handleSubmit = () => {
    if (isOverlap(balls[blinkingBallIndex])) {
      setScore((prev) => prev + 1);
    }
    handleblinkBall();
    // setBoxPosition({
    //   x: 90,
    //   y: 90,
    // });
  };

  // Overlap detection
  const isOverlap = (ball) => {
    if (!ball || !boxRef.current) return false;

    const { x, y } = ball;
    const box = boxRef.current.getBoundingClientRect();
    const ballPos = {
      left: x * (window.innerWidth / 100),
      top: y * (window.innerHeight / 100),
    };

    return (
      ballPos.left >= box.left &&
      ballPos.left <= box.right &&
      ballPos.top >= box.top &&
      ballPos.top <= box.bottom
    );
  };

  const timerProgress = ((120 - timeLeft) / 120) * 100;

  const formattedTime = moment
    .utc(moment.duration(timeLeft, "seconds").asMilliseconds())
    .format("mm:ss");

  useEffect(() => {
    document.body.style.overflow = timeLeft > 0 ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [timeLeft]);

  // Handle joystick movement

  const handleJoystickMove = (e) => {
    const parentRect = joystickRef.current.getBoundingClientRect();
    const centerX = parentRect.left + parentRect.width / 2;
    const centerY = parentRect.top + parentRect.height / 2;
  
    const deltaX = e.clientX - centerX;
    const deltaY = e.clientY - centerY;
  
    const distance = Math.min(Math.sqrt(deltaX ** 2 + deltaY ** 2), 50);
  
    const angle = Math.atan2(deltaY, deltaX);
  
    const newX = distance * Math.cos(angle);
    const newY = distance * Math.sin(angle);
  
    setJoystickPosition({ x: newX, y: newY });
  
    const newBoxX = Math.min(100, Math.max(0, boxPosition.x + (newX / 50) * 0.2));
    const newBoxY = Math.min(100, Math.max(0, boxPosition.y + (newY / 50) * 0.2));
  
    setBoxPosition({ x: newBoxX, y: newBoxY });
  };
  
  const handleJoystickEnd = () => {
    setJoystickPosition({ x: 0, y: 0 });
  };

  return (
    <TestLayout>
      <div
        className="sensor-motor-test"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={gameBoxRef}
      >
        <h2>Sensor-Motor Test</h2>

        {timeLeft > 0 ? (
          <>
            <div
              className="timer"
              style={{
                background: `conic-gradient(red ${timerProgress}%, #ddd ${timerProgress}% 100%)`,
              }}
            >
              <p>{formattedTime}</p>
            </div>
            <div className="game-box">
              <div className="ball_group">
                {balls.map((ball, index) => (
                  <div
                    key={index}
                    className={`ball ${
                      blinkingBallIndex === index ? "blinking" : ""
                    }`}
                    style={{
                      left: `${ball.x}%`,
                      top: `${ball.y}%`,
                      backgroundColor:
                        blinkingBallIndex === index ? "green" : "red",
                    }}
                  ></div>
                ))}
              </div>
              <div
                ref={boxRef}
                className="box-group"
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                style={{
                  left: `${boxPosition.x}%`,
                  top: `${boxPosition.y}%`,
                }}
              >
                <div className="box small"></div>
                <div className="box medium"></div>
                <div className="box large"></div>
              </div>
            </div>
            <div
              ref={joystickRef}
              className="joystick"
              onMouseMove={handleJoystickMove}
              onMouseUp={handleJoystickEnd}
              onMouseLeave={handleJoystickEnd}
            >
              <div
                className="joystick_handle"
                style={{
                  left: `calc(50% + ${joystickPosition.x}px - 15px)`,
                  top: `calc(50% + ${joystickPosition.y}px - 15px)`,
                }}
              ></div>
            </div>
          </>
        ) : (
          <div className="feedback_box">
            {score > 70 ? (
              <>
                <img src={goodPerformance} alt="good" />
                <p>
                  You have performed well in the test, demonstrating great
                  understanding and preparation. Keep up the good work and
                  continue striving for excellence!🎉
                </p>
              </>
            ) : (
              <>
                <img src={needImprovement} alt="needImprovement" />
                <p>
                  Your performance shows potential, but there is room for
                  improvement
                </p>
              </>
            )}
          </div>
        )}
        {timeLeft > 0 && blinkingBallIndex !== null && (
          <button className="btn" onClick={() => handleSubmit()}>
            Target Hit
          </button>
        )}
      </div>
    </TestLayout>
  );
};

export default SensorMotorTest;
